import React from 'react';
import PropTypes from 'prop-types';
import { Thumbnail } from './Thumbnail.js';
import { StudyInfo } from './StudyInfo';
import classnames from 'classnames';
import './StudyBrowser.styl';

function StudyBrowser(props) {
  const {
    opNum,
    noShow,
    studies,
    activeViewportIndex,
    studyMetadata,
    onThumbnailClick,
    onThumbnailDoubleClick,
    onTabClick,
    supportsDrag,
    showThumbnailProgressBar,
  } = props;

  return (
    <div className="study-browser">
      <StudyInfo studyMetadata={studyMetadata} activeViewportIndex={activeViewportIndex} />
      <div className="study-tab">
        {studies.length>1 && studies
          .map((study, i) => {
            const { StudyDate } = study;
            let flag = noShow.indexOf(i) >= 0
            if(!flag){
                return (
                    <button key={i} className={classnames("study-tab-item",{"active":activeViewportIndex==i})} id={i}
                        onClick={onTabClick.bind(undefined, i)}
                    >{i+1}</button>
                );
             }
          })
          .flat()}
      </div>
      <div className="scrollable-study-thumbnails">
        {studies
          .map((study, studyIndex) => {
            if(activeViewportIndex == studyIndex ) {
              const { StudyInstanceUID } = study;
              return study.thumbnails.map((thumb, thumbIndex) => {
                // TODO: Thumb has more props than we care about?
                const {
                  active,
                  altImageText,
                  displaySetInstanceUID,
                  imageId,
                  derivedDisplaySetsNumber,
                  numImageFrames,
                  SeriesDescription,
                  SeriesNumber,
                  hasWarnings,
                  hasDerivedDisplaySets,
                } = thumb;

                return (
                  <div
                    key={thumb.displaySetInstanceUID}
                    className="thumbnail-container"
                    data-cy="thumbnail-list"
                  >
                    <Thumbnail
                      active={active}
                      supportsDrag={supportsDrag}
                      key={`${studyIndex}_${thumbIndex}`}
                      id={`${studyIndex}_${thumbIndex}`} // Unused?
                      // Study
                      StudyInstanceUID={StudyInstanceUID} // used by drop
                      // Thumb
                      altImageText={altImageText}
                      imageId={imageId}
                      derivedDisplaySetsNumber={derivedDisplaySetsNumber}
                      displaySetInstanceUID={displaySetInstanceUID} // used by drop
                      numImageFrames={numImageFrames}
                      SeriesDescription={SeriesDescription}
                      SeriesNumber={SeriesNumber}
                      hasWarnings={hasWarnings}
                      hasDerivedDisplaySets={hasDerivedDisplaySets}
                      // Events
                      onClick={onThumbnailClick.bind(
                        undefined,
                        studyIndex,
                        displaySetInstanceUID
                      )}
                      onDoubleClick={onThumbnailDoubleClick}
                      showProgressBar={showThumbnailProgressBar}
                    />
                  </div>
                );
              });
            }
          })
          .flat()}
      </div>
    </div>
  );
}

const noop = () => {};

StudyBrowser.propTypes = {
  studies: PropTypes.arrayOf(
    PropTypes.shape({
      StudyInstanceUID: PropTypes.string.isRequired,
      thumbnails: PropTypes.arrayOf(
        PropTypes.shape({
          altImageText: PropTypes.string,
          displaySetInstanceUID: PropTypes.string.isRequired,
          imageId: PropTypes.string,
          derivedDisplaySetsNumber: PropTypes.number,
          numImageFrames: PropTypes.number,
          SeriesDescription: PropTypes.string,
          SeriesNumber: PropTypes.number,
          stackPercentComplete: PropTypes.number,
        })
      ),
    })
  ).isRequired,
  studyMetadata: PropTypes.array,
  noShow: PropTypes.array,
  supportsDrag: PropTypes.bool,
  onThumbnailClick: PropTypes.func,
  onThumbnailDoubleClick: PropTypes.func,
  onTabClick: PropTypes.func,
  showThumbnailProgressBar: PropTypes.bool,
};

StudyBrowser.defaultProps = {
  studies: [],
  noShow: [],
  supportsDrag: true,
  onThumbnailClick: noop,
  onThumbnailDoubleClick: noop,
  onTabClick: noop,
  showThumbnailProgressBar: true,
};

export { StudyBrowser };
