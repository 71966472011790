const KjHost="https://image.xingyunjk.com/health/";
//const KjHost="https://www.xingyunjk.com/health/";

const KjApi={
  getByHospitalId:KjHost+"patient/hospital/getByHospitalId",
  getStudyDetail:KjHost+"patient/study/getDetail",
  getStudy:KjHost+"patient/study/get",
  getStudyList:KjHost+"patient/study/list",
  loginByAuthCode:KjHost+"patient/auth/loginByAuthCode",
  loginByStudyAuthCode:KjHost+"patient/auth/loginByStudyAuthCode",
  sendLoginAuthCode:KjHost+"patient/auth/sendLoginAuthCode",
  sendLoginAuthCodeByStudy:KjHost+"patient/auth/sendLoginAuthCodeByStudy",
  getPatientDesensitizationPhoneNumber:KjHost+"patient/auth/getPatientDesensitizationPhoneNumber",
  getStudyDetail_ofv:KjHost+"open/study/get",
  getByHospitalId_ofv:KjHost+"open/study/getHospital",
  getStudySeriesAiList:KjHost+"ofv/studySeriesAi/list",
  getStudySeriesAi:KjHost+"ofv/studySeriesAi/get",
  getSharingCode:KjHost+"sharingCode/generateSharingAuthCode",
  getSharingCodeToken:KjHost+"sharingCode/token"
}

export {KjApi};
