import getImageId from "./getImageId";


const getDataSourcesModule={

    getImageIdsFromDisplaySet(displaySet) {
        const imageIds = [];
        if (!displaySet.images || displaySet.images.length < 1) {
            return [];
        }

        displaySet.images.forEach(image => {
            let imageId = getImageId(image);
            imageIds.push(imageId);
        });
        return imageIds;
    },

    getImageIdsForDisplaySet(displaySet){
        const imageIds = [];
        if (!displaySet.images || displaySet.images.length < 1) {
            return [];
        }

        displaySet.images.forEach(image => {
            let imageId = getImageId(image);
            imageIds.push(imageId);
        });
        return imageIds;
    }
}

export default getDataSourcesModule;
