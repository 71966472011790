import React, {useEffect, useCallback, useState} from 'react';
import {connect} from "react-redux";
import OHIFCornerstoneViewport from "./OHIFCornerstoneViewport";
import CornerstoneViewport from "./CornerstoneViewport";
import {servicesManager} from "../App";
import * as cornerstoneTools from "@cornerstonejs/tools";
import classnames from 'classnames';
import actions from "../core/redux/actions";

const {
    ToolGroupManager,
    Enums: csToolsEnums,
    CrosshairsTool,
} = cornerstoneTools;
const { setViewportActive } = actions;

const { MouseBindings } = csToolsEnums;

const mapStateToProps = (state) => {
    const { numRows, numColumns, layout, activeViewportIndex } = state.viewports;
    return {
        numRows,
        numColumns,
        layout,
        activeViewportIndex,
    };
};


const ViewportComponent =function(props) {
  const {
    activeViewportIndex,
    availablePlugins,
    defaultPlugin: defaultPluginName,
    layout,
    numRows,
    numColumns,
    setViewportData,
    studies,
    viewportData,
    children,
    isStudyLoaded,
    gridSet,
    noShow,
    onNewImage,
    dispatch,
  } = props;

  if (!viewportData || !viewportData.length) {
    return null;
  }

  const onViewportClick = (idx) =>{
      idx = parseInt(idx);
      const _len = studies.length
      if(_len> 1 && idx>=0 && idx<_len ){
        dispatch(setViewportActive(idx));
      }
  }
  useEffect(() => {
        const views = document.querySelectorAll('.viewport-container');
          for (const trigger of views) {
             trigger.addEventListener('touchstart', (e) => {
               const _idx = event.target.parentNode.parentNode.dataset['idx']
               onViewportClick(_idx);
           });
        }
  },[]);

  useEffect(() => {
      const Viewports=[];
      const {ToolGroupService}=servicesManager.services;
      const { unsubscribe } = ToolGroupService.subscribe(
          ToolGroupService.EVENTS.VIEWPORT_ADDED,
          (viewport)=>{
              if(!Viewports.includes(viewport.viewportId)){
                  Viewports.push(viewport.viewportId)
              }
              if(studies.length=='1' && Viewports.length>1){
                  //MPR
                  const toolGroup = ToolGroupManager.getToolGroup(viewport.toolGroupId);
                  const activeTool = toolGroup.getActivePrimaryMouseButtonTool();
                  if (activeTool) {
                      toolGroup.setToolPassive(activeTool);
                  }
                  setTimeout(()=>{
                    toolGroup.setToolActive(CrosshairsTool.toolName, {
                        bindings: [{ mouseButton: MouseBindings.Primary }],
                    });
                  },1000);
              }
          }
      );
      return () => {
          unsubscribe();
      };
    },[viewportData])

  const getViewportPanes=useCallback(()=>{
      const viewportPanes = [];
      for (let i = 0; i < viewportData.length; i++){
          const displaySet=viewportData[i]
          const {viewportOptions}=layout.viewports[i];
          const idx = displaySet.idx;
          const isActive = activeViewportIndex == idx;
          viewportPanes[i] = (
              <div key={i}
                className={classnames("viewport-drop-target viewport-container",{"active":isActive})}
                id={idx}
                onClick={onViewportClick.bind(this,idx)}
              >
                  <OHIFCornerstoneViewport
                      viewportIndex={i}
                      displaySet={displaySet}
                      viewportOptions={viewportOptions}
                      needsRerendering={true}
                  >
                  </OHIFCornerstoneViewport>
              </div>
          );
      }
      return viewportPanes;
  },[activeViewportIndex,viewportData])

  if(viewportData.length==1){
    const displaySet = viewportData[0];
    if (!displaySet) {
      return null;
    }

    return (
        <div data-cy="viewprt-grid"
             style={{
                 display: 'grid',
                 gridTemplateRows:`repeat(1, 100%)`,
                 gridTemplateColumns: `repeat(1, 100%)`,
                 height: '100%',
                 width: '100%',
             }}>
          <div>
              {getViewportPanes()}
          </div>
        </div>
    );
  }
  else if(viewportData.length==2){
    let _w = document.body.clientWidth;
    let _h = document.body.clientHeight;
    let isHorizontal = _w<_h;
    if(gridSet ){
      isHorizontal = gridSet.colNum == 1;
    }
    if(isHorizontal){
      return (
        <div data-cy="viewprt-grid"
             style={{
                 display: 'grid',
                 gridTemplateRows:`repeat(2, 50%)`,
                 gridTemplateColumns: `repeat(1, 100%)`,
                 height: '100%',
                 width: '100%',
             }}>
            {getViewportPanes()}
        </div>
      );
    } else {
      return (
        <div data-cy="viewprt-grid"
             style={{
                 display: 'grid',
                 gridTemplateRows:`repeat(1, 100%)`,
                 gridTemplateColumns: `repeat(2, 50%)`,
                 height: '100%',
                 width: '100%',
             }}>
            {getViewportPanes()}
        </div>
      );
    }
  }
  else{
    if(gridSet){
        let row3 = false;
        if(viewportData.length==3){
          if(gridSet.colNum == 3) row3 = true;
            if( row3 ){
                return (
                    <div data-cy="viewprt-grid"
                         style={{
                           display: 'grid',
                           gridTemplateRows: `repeat(1, 100%)`,
                           gridTemplateColumns: `repeat(3, 33.3333%)`,
                           height: '100%',
                           width: '100%',
                         }}>
                        {getViewportPanes()}
                    </div>
                );
            }else if(gridSet.rowNum == 3 && gridSet.colNum == 1 ){
                return (
                    <div data-cy="viewprt-grid"
                         style={{
                           display: 'grid',
                           gridTemplateRows: `repeat(3, 33.3333%)`,
                           gridTemplateColumns: `repeat(1, 100%)`,
                           height: '100%',
                           width: '100%',
                         }}>
                        {getViewportPanes()}
                    </div>
                );
            }
        } else {
          if(gridSet.colNum == 3) {
                return (
                    <div data-cy="viewprt-grid"
                         style={{
                           display: 'grid',
                           gridTemplateRows: `repeat(2, 50%)`,
                           gridTemplateColumns: `repeat(3, 33.3333%)`,
                           height: '100%',
                           width: '100%',
                         }}>
                        {getViewportPanes()}
                    </div>
                );
          }
        }
    }
    return (
        <div data-cy="viewprt-grid"
             style={{
               display: 'grid',
               gridTemplateRows: `repeat(2, 50%)`,
               gridTemplateColumns: `repeat(2, 50%)`,
               height: '100%',
               width: '100%',
             }}>
            {getViewportPanes()}
        </div>
    );

  }

};


export default connect(
    mapStateToProps,
    null
)(ViewportComponent);
