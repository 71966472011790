import { Icon } from './elements/Icon';
import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import './elements/iconfont.css';

export function ToolbarButton(props) {
  const className = classnames(props.className, 'tool', props.active?'active':'',props.disabled?'disabled':'');
  const { onClick } = props;

  const handleClick = event => {
    if (onClick&&!props.disabled) {
      onClick(event, props);
    }
  };

  return (
    <a
      id={props.id}
      className={className}
      title={props.title}
      onClick={handleClick}
      style={props.style}
    >
      {props.icon && <i className={"iconfont icon-"+ props.icon} />}
      <span className="name">{props.label}</span>
    </a>
  );
}

ToolbarButton.propTypes = {
  id: PropTypes.string,
  icon: PropTypes.string,
  label: PropTypes.string,
  title: PropTypes.string,
  className: PropTypes.string,
  active: PropTypes.bool,
  onClick: PropTypes.func,
};
export default ToolbarButton;
