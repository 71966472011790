import React, {useCallback, useEffect, useState} from 'react';
import classnames from 'classnames';
import debounce from 'lodash.debounce';
import MeasurementItem from "./MeasurementItem";
import "./MeasurementTracking.styl";

import {servicesManager} from "../App";
import addMeasurement from "../dicom-sr/utils/addMeasurement";

import {setTrackingUniqueIdentifiersForElement} from "../dicom-sr/tools/modules/dicomSRModule";
import toolNames from "../dicom-sr/tools/toolNames";
import {getEnabledElement as OHIFgetEnabledElement} from "../viewport/state";
import {dangerLevel,dangerLevels,tubercleTypes} from "../dicom-sr/utils/dangerLevel";

import * as cornerstoneTools from '@cornerstonejs/tools';
import Icon from './components/Icon';

const {ToolGroupManager,utilities}=cornerstoneTools;

function MeasurementTracking(props) {
    const [classify,setClassify]=useState([]);
    const [classifyIndex,setClassifyIndex]=useState(0);
    const [classifySubIndex,setClassifySubIndex]=useState(0);
    const [filterView,setFilterView]=useState(false);
    const [filteAll,setFilteAll]=useState({all: true, danger: true, tubercle: true});
    const [flag,setFlag]=useState(0);
    const [flush,setFlush]=useState(0);

    const [displayMeasurements, setDisplayMeasurements] = useState([]);
    const [allMeasurements, setAllMeasurements] = useState([]);
    const [fingerPosts, setFingerPosts] = useState('');
    const [examinationTxt, setExaminationTxt] = useState([]);
    const [dangerTypes, setDangerTypes] = useState([]);
    const [ctypes, setCtypes] = useState([]);
    const [activeIdx, setActiveIdx] = useState(-1);
    const [fdpArr, setFdpArr] = useState([]);
    const {MeasurementService}=servicesManager.services;

    const onClickHandler = event =>{
        setClassifyIndex(event.target.id)
        setClassifySubIndex(0)
    }

    const onSubClickHandler = event =>{
        classify[classifyIndex].subIdx = event.target.id
        setClassifySubIndex(event.target.id)
        setClassify(classify)
    }

    const onCheckChange=event=>{}

    const onCheckAll= event =>{
        setFilterView(false)
        const currentClass = getCurrentClass();
        let _isChecked = event.target.checked
        if(typeof _isChecked === 'undefined'){
            _isChecked = isCheckAll()
            _isChecked = !_isChecked
        }
        if(currentClass){
            for (let i = 0; i < displayMeasurements.length; i++) {
                if(checkShow(currentClass,displayMeasurements[i])){
                  displayMeasurements[i].isChecked = _isChecked
                }
            }
            setReport()
        }
    }

    const isCheckAll =()=>{
      const currentClass = getCurrentClass();

      let checkNum = 0
      let isCheck = true
      if(currentClass){
          for (let i = 0; i < displayMeasurements.length; i++) {
              const m = displayMeasurements[i]
              if(checkShow(currentClass,m)){
                  if(m.isChecked) {
                    checkNum++;
                  } else{
                    isCheck = false
                  }
              }
          }
      }
      return checkNum>0 && isCheck
    }

    const setMeasurements =(mdata)=>{
      setAllMeasurements(mdata);
      const tabMeasurements = []
      let idx = 0;
      const _itemIdx = [];
      for(let i=0; i<mdata.length; i++) {
          const item = mdata[i]
          const _s = item.classId + '_' + item.tabIdx
          if (item.toShow && _itemIdx.indexOf(_s) < 0 ) {
            _itemIdx.push(_s)
            item.idx = idx
            idx++
            item.msize = getMsize(item.ldiam,item.sdiam)
            if (item.classId === 'T-1-1') {
                item.danger = dangerLevel(item)
            } else{
                item.danger = {}
            }
            tabMeasurements.push(item)
          }
      }
      setDisplayMeasurements(tabMeasurements);
    }

    useEffect(() => {
        const debouncedSetDisplayMeasurements = debounce(
            setMeasurements,
            100
        );
        setMeasurements(_getMappedMeasurements(MeasurementService));

        // ~~ Subscription
        const added = MeasurementService.EVENTS.MEASUREMENT_ADDED;
        const addedRaw = MeasurementService.EVENTS.RAW_MEASUREMENT_ADDED;
        const updated = MeasurementService.EVENTS.MEASUREMENT_UPDATED;
        const removed = MeasurementService.EVENTS.MEASUREMENT_REMOVED;
        const cleared = MeasurementService.EVENTS.MEASUREMENTS_CLEARED;
        const subscriptions = [];

        [added, addedRaw, updated, removed, cleared].forEach(evt => {
            subscriptions.push(
                MeasurementService.subscribe(evt, () => {
                    debouncedSetDisplayMeasurements(
                        _getMappedMeasurements(MeasurementService)
                    );
                }).unsubscribe
            );
        });

        return () => {
            subscriptions.forEach(unsub => {
                unsub();
            });
            debouncedSetDisplayMeasurements.cancel();
        };
    },[]);

    useEffect(()=>{
        const debouncedSetClassify = debounce(
            setClassify,
            100
        );
        setClassify(_getFocusClasses(MeasurementService));
        setDangerTypes(_dangerLevels())
        setCtypes(_tubercleTypes())
        const addedRaw = MeasurementService.EVENTS.RAW_MEASUREMENT_ADDED;
        const subscriptions = [];
        subscriptions.push(
            MeasurementService.subscribe(addedRaw, () => {
                debouncedSetClassify(
                    _getFocusClasses(MeasurementService)
                );
            }).unsubscribe
        );
        return () => {
            subscriptions.forEach(unsub => {
                unsub();
            });
            debouncedSetClassify.cancel();
        };
    },[]);

    const onMeasurementItemClickHandler = ({ uid, idx }) => {
        setFilterView(false)
        setActiveIdx(idx)
        const element = OHIFgetEnabledElement(0);
        const {MeasurementService}=servicesManager.services;
        let measurement=MeasurementService.getMeasurement(uid);
        if(measurement){
            utilities.jumpToSlice(element,{imageIndex: measurement.frameNumber-1})
        }
        drawBox(measurement);
    }

    const onMeasurementItemChange = ({ idx, _isChecked }) => {
        displayMeasurements[idx].isChecked = _isChecked
        setReport()
    }

    const setReport = () => {
        let examinationMsg = [];
        let fingerMsg = [];
        let fdp = []; //肺大泡
        for (let i = 0; i < displayMeasurements.length; i++) {
            const m = displayMeasurements[i]
            if(m.isChecked){
                const localTextArr = m.markLocation.split(' ');
                if (m.code ==='6-1-1' ) {
                  const localTextArr = m.markLocation.split(' ');
                  if(fdp.indexOf(localTextArr[0])<0){
                    fdp.push(localTextArr[0])
                  }
                } else {
                  let mgs = localTextArr.join('') +'见一' + m.locationDescription +'('+m.pm+') ，大小约为' + m.msize
                  examinationMsg.push(mgs)
                  mgs = localTextArr.join('') + m.locationDescription+'('+m.pm+')'  + '，大小约为' + m.msize
                  fingerMsg.push(mgs)
                }
            }
        }

        setFingerPosts(fingerMsg.join(";"));
        setFdpArr(fdp)
        setExaminationTxt(examinationMsg)
    }

    const drawBox = (currentItem) => {
        const element = OHIFgetEnabledElement(0);
        if(!element) return;

        const trackingIds = [];
        for (let i = 0; i < allMeasurements.length; i++) {
          let measurement = allMeasurements[i];
          if(measurement){
             try{
                if (measurement.classId == currentItem. classId) {
                    addMeasurement(measurement,measurement.imageId,measurement.displaySetInstanceUID, measurement.tabIdx === currentItem. tabIdx);
                    trackingIds.push(measurement.TrackingUniqueIdentifier)
                }
             } catch (e) {
               // console.log(e)
            }
          }
        }
        setTrackingUniqueIdentifiersForElement(element,trackingIds);
        const toolGroup = ToolGroupManager.getToolGroup("STACK_TOOL_GROUP_ID");
        toolGroup.setToolEnabled(toolNames.DICOMSRDisplay);
        return;
    }

    const getCurrentClassId = () => {
        let classId;
        const class0=classify[classifyIndex];
        if(class0){
            const classifSub=class0.items;
            const class1=classifSub[class0.subIdx];
            if(class1){
                classId=class1.code;
            }
        }
        return classId;
    }

    const getCurrentClass = () => {
        const class0=classify[classifyIndex];
        if(class0){
            const classifSub=class0.items[class0.subIdx];
            if(classifSub) return classifSub;
        }
        return class0;
    }

    const sortFrameNumbers = (a, b) => {
        if (a.frameNumber === b.frameNumber) {
            return 0;
        }
        else {
            return (a.frameNumber < b.frameNumber) ? -1 : 1;
        }
    }

    const sortLdiam = (a, b) => {
        if (a.ldiam === b.ldiam) {
            return 0;
        }
        else {
            return (a.ldiam < b.ldiam) ? 1 : -1;
        }
    }
    const sortVolume = (a, b) => {
        if (a.volume === b.volume) {
            return 0;
        }
        else {
            return (a.volume < b.volume) ? 1 : -1;
        }
    }

    const sortDanger = (a, b) => {
        if (a.danger.level === b.danger.level) {
            return 0;
        }
        else {
            return (a.danger.level < b.danger.level) ? -1 : 1;
        }
    }

    const checkShow = (currentClass, item) => {
       if(item.classId !== currentClass.code ){
           return false
       }
       if(currentClass.sizeChoose>0 && item.ldiam < currentClass.sizeChoose ){
           return false
       }

       if (currentClass.sortType === 2) {
         let _show = true
         if(!filteAll.danger) {
            if(filteAll.showDanger) {
             _show = filteAll.showDanger.indexOf(item.danger.level) >= 0
            } else {
              return false
            }
         }

         if(_show && !filteAll.tubercle) {
             if (filteAll.showCode) {
              _show = filteAll.showCode.indexOf(item.code) >= 0
            } else {
              return false
            }
         }

         return _show

       }
       return true
    }

    const getCurrentItems= () => {
        const currentClass = getCurrentClass();
        const _displayMeasurements=[];
        if(currentClass){
            for (let i = 0; i < displayMeasurements.length; i++) {
                if(checkShow(currentClass,displayMeasurements[i])){
                    _displayMeasurements.push(displayMeasurements[i])
                }
            }

          switch(currentClass.sortChoose) {
            case 'ldiams':
                _displayMeasurements.sort(sortLdiam);
                break;
            case 'volume':
                _displayMeasurements.sort(sortVolume);
                break;
            case 'danger':
                _displayMeasurements.sort(sortDanger);
                break;
            case 'code':
                _displayMeasurements.sort(sortCode);
                break;
            default:
              _displayMeasurements.sort(sortFrameNumbers);
          }
        }
        return _displayMeasurements
    }


    const getMsize = (ldiam,sdiam) => {
        return  parseFloat((ldiam).toFixed(1)) + '×' + parseFloat((sdiam).toFixed(1)) +"mm";
    }

    const getMeasurementTable=useCallback(()=> {
            const measurementItems = [];
            const currentItems = getCurrentItems();
            for (let i = 0; i < currentItems.length; i++){
                const m = currentItems[i]
                const _isActive = m.idx === activeIdx
                measurementItems[i]=(
                    <MeasurementItem key={i}
                        uid={m.uid}
                        isChecked={m.isChecked?true:false}
                        isActive={_isActive}
                        label={m.locationDescription}
                        markLocation={m.markLocation}
                        pm={m.pm}
                        msize={m.msize}
                        danger={m.danger}
                        volume={m.volume}
                        act ={m.act}
                        idx={m.idx}
                        tabIdx={m.tabIdx}
                        onClick={onMeasurementItemClickHandler}
                        onChange={onMeasurementItemChange}
                     />
                )
            }
            return measurementItems;
        },
        [displayMeasurements,classifyIndex,flag,activeIdx,filteAll]
    )

    const getClassifyTable=useCallback(()=>{
        const items=[];
        for(let i=0;i<classify.length;i++){
            const m=classify[i]
            items[i]=(
                <button key={i} className={classnames("classify-item",{"active":classifyIndex==i})} id={i} onClick={onClickHandler}>{m.name+"("+m.count+")"}</button>
            )
        }
        return items;
    },[classify,classifyIndex])

    const getClassifySubTable=useCallback(()=>{
        const items=[];
        const class0=classify[classifyIndex];
        if(class0){
            const classifSub=class0.items;
            for(let i=0;i<classifSub.length;i++){
                const m=classifSub[i]
                items[i]=(
                    <button key={i} className={classnames("classify-item",{"active":class0.subIdx==i})} id={i} onClick={onSubClickHandler}>{m.name+"("+m.count+")"}</button>
                )
            }
        }
        return items;
    },[classify,classifyIndex,classifySubIndex])

    const getFingerpost=useCallback(()=>{
        let ms = fingerPosts
        if(ms) ms = ms +'。'
        else ms = '请从病灶列表勾选病灶'
        return ms;
    },[fingerPosts])

    const getExamination=useCallback(()=>{
        let et = examinationTxt.join(";");
        if(et) et = et +'。'
        let fdp = '';
        if(fdpArr.length>0){
          fdp = fdpArr.join("、") + '可见肺大泡'
        } else {
          if(!et){
            et = '请从病灶列表勾选病灶'
          }
        }

        return (
          <div>
            <div>{et}</div>
            <div>{fdp}</div>
          </div>
        )

    },[examinationTxt])

    const handleChange = event => {
      const class0=classify[classifyIndex];
      classify[classifyIndex].items[class0.subIdx].sizeChoose = event.target.value
      setClassify(classify)
      setFlag(!flag)
    };
    const onSortChange=event=>{
        const class0=classify[classifyIndex];
        classify[classifyIndex].items[class0.subIdx].sortChoose = event.target.value
        setClassify(classify)
        setFlag(!flag)
    }
    const tsortList = (t) => {
        if(t===2){
            return [
                    {val: 'frameNumber',title:  'IM'},
                    {val: 'ldiams',title:  '长径'},
                    {val: 'volume',title:  '体积'},
                    {val: 'code',title:  '类型'},
                    {val: 'danger',title:  '良恶性'}
                  ]
        } else if(t===1){
           return [
                    {val: 'frameNumber',title:  'IM'},
                    {val: 'volume',title:  '体积'},
                  ]
        }
    }

    const sizeSelect = (sizeChoose) => {
        const options = [{val: '',title:  '全部'},
                    {val: '1',title:  '≥1mm'},
                    {val: '2',title:  '≥2mm'},
                    {val: '3',title:  '≥3mm'},
                    {val: '4',title:  '≥4mm'},
                    {val: '5',title:  '≥5mm'},
                    {val: '6',title:  '≥6mm'},
                    {val: '7',title:  '≥7mm'},
                    {val: '8',title:  '≥8mm'},
                    {val: '9',title:  '≥9mm'},
                    {val: '10',title:  '≥10mm'}]
        return ( <select className="header-select" value={sizeChoose} onChange={handleChange} onClick={onFilterView}>
                     {options.map(option => (
                        <option key={option.val} value={option.val}>
                          {option.title}
                        </option>
                      ))}
                  </select>
                )
    }

    const onFilterView=event=>{
        if (event.target.id === 'filterBox' || filterView ) {
          setFilterView(!filterView)
        }
    }

    const tubercleAllChange = (isNo) => {
      filteAll.tubercle = !isNo
      for( let j=0; j< ctypes.length;j++ ){
        ctypes[j].isNo = isNo
      }
    }

    const dangerAllChange = (isNo) => {
      filteAll.danger = !isNo
      for( let j=0; j< dangerTypes.length;j++ ){
        dangerTypes[j].isNo = isNo
      }
    }

    const onTypeAllClick=event=>{
      const _ck = !filteAll.all
      filteAll.all = _ck
      tubercleAllChange(!_ck)
      dangerAllChange(!_ck)
      setFlush(flush+1)
    }

    const onDangerAllClick=event=>{
      const _ck = filteAll.danger
      dangerAllChange(_ck)
      filteAll.all = !_ck && filteAll.tubercle
      setFilteAll(filteAll)
      setFlush(flush+1)
    }

    const onTubercleAllClick=event=>{
      const _ck = filteAll.tubercle
      tubercleAllChange(_ck)
      filteAll.all = !_ck && filteAll.danger
      setFilteAll(filteAll)
      setFlush(flush+1)
    }

    const onDangerCheckClick=event=>{
      const idx = event.target.dataset.id
      const _ck = !dangerTypes[idx].isNo
      dangerTypes[idx].isNo = _ck
      let isAll = !_ck
      let showDanger = []

      for( let j=0; j< dangerTypes.length;j++ ){
        if(dangerTypes[j].isNo){
          isAll = false
        } else{
          showDanger.push(dangerTypes[j].level)
        }
      }
      setDangerTypes(dangerTypes)
      filteAll.danger = isAll
      filteAll.all = isAll && filteAll.tubercle
      filteAll.showDanger = showDanger
      setFilteAll(filteAll)
      setFlush(flush+1)
    }

    const onTubercleCheckClick=event=>{
      const idx = event.target.dataset.id
      const _ck = !ctypes[idx].isNo
      ctypes[idx].isNo = _ck
      let isAll = !_ck
      let showCode = []
      for( let j=0; j< ctypes.length;j++ ){
        if(ctypes[j].isNo){
          isAll = false
        } else{
          showCode = showCode.concat(ctypes[j].codes)
        }
      }
      filteAll.tubercle = isAll
      filteAll.all = isAll && filteAll.danger
      filteAll.showCode = showCode
      setFilteAll(filteAll)
      setFlush(flush+1)
      setCtypes(ctypes)
    }


    const showFilterBox=useCallback(()=>{
        return (
           <div className="filterBox">
             <div className="filterTitle">
               结节类型
             </div>
             <div className="filterChoose" onClick={onTubercleAllClick}>
                <input type="checkbox" checked={filteAll.tubercle} onChange={onCheckChange} /> 全部类型
             </div>
             <div className="filterList">
             {ctypes.map((option,index) => (
                  <div key={index} className="ckb" data-id={index} onClick={onTubercleCheckClick}>
                    <input type="checkbox" checked={!option.isNo} data-id={index} onChange={onCheckChange}/>
                    {option.title}
                   </div>
              ))}
             </div>
             <div className="filterTitle">
               良恶性
             </div>
             <div className="filterChoose" onClick={onDangerAllClick}>
                <input type="checkbox" checked={filteAll.danger} onChange={onCheckChange} />全部类型
             </div>
             <div className="filterList">
             {dangerTypes.map((option,index) => (
                  <div key={index} className="ckb" data-id={index} onClick={onDangerCheckClick}>
                    <input type="checkbox" checked={!option.isNo} data-id={index} onChange={onCheckChange}/>
                    {option.title}
                   </div>
              ))}
             </div>
             <div className="filterFoot">
               <span onClick={onTypeAllClick}>
                 <input type="checkbox" checked={filteAll.all} onChange={onCheckChange} />全选
               </span>
               <span className="filterBtn" onClick={onFilterView}>确定</span>
             </div>
           </div>
       )
    },[filterView,ctypes,dangerTypes,filteAll,flush])

    const showSort=useCallback(()=>{
        const currentClass = getCurrentClass();
        if(!currentClass) return;
        const tsrot = tsortList(currentClass.sortType)
        return (
                <div className="list-header">
                <div className="chkbox all" onClick={onCheckAll}>
                  <input type="checkbox" checked={isCheckAll()} onChange={onCheckChange} />
                  <span>全选</span>
                  </div>
                  {currentClass.sortType > 0 && <div><Icon
                    name="sort"
                    className="sort-icon"
                  />
                   <select className="header-select" value={currentClass.sortChoose} onChange={onSortChange} onClick={onFilterView}>
                     {tsrot.map(option => (
                        <option key={option.val} value={option.val}>
                          {option.title}
                        </option>
                      ))}
                  </select></div> }
                   {currentClass.code === 'T-1-1' && <Icon
                    name="tool-length"
                    className="sort-icon"
                   /> }
                   {currentClass.code === 'T-1-1' && sizeSelect(currentClass.sizeChoose)}
                   {currentClass.code === 'T-1-1' && <div className="header-filter" onClick={onFilterView} id="filterBox">
                     <Icon
                      name="filter"
                      className="sort-icon"
                      id="filterBox"
                     />筛选 </div> }
            </div>
        )
    },[flag,classify,classifyIndex,filterView,dangerTypes,ctypes])

    return (
        <div className="measurementTable">
            <div className="classify1" onClick={onFilterView}>
                {getClassifyTable()}
            </div>
            <div className="classify1" onClick={onFilterView}>
                {getClassifySubTable()}
            </div>
            <div className="list-body">
                {
                    getMeasurementTable()
                }
            </div>
            {showSort()}
            {filterView && showFilterBox()}
            <div className="opinion" onClick={onFilterView}>
                <div className="opinion-item">
                    <div className="opinion-header"><span>指南意见</span></div>
                    <div className="opinion-text">
                      {
                        getFingerpost()
                      }
                    </div>
                </div>
                <div className="opinion-item">
                    <div className="opinion-header"><span>检查所见</span></div>
                    <div className="opinion-text">
                      {
                        getExamination()
                      }
                    </div>
                </div>
                <div><span>AI结论仅供参考，不作临床诊断依据</span></div>
            </div>
        </div>
    )
}

function sortCode(a, b) {
    if (a.code === b.code) {
        return 0;
    }
    else {
        return (a.code < b.code) ? -1 : 1;
    }
}

function _dangerLevels() {
   return dangerLevels();
}

function _tubercleTypes() {
   return tubercleTypes();
}

function _getMappedMeasurements(MeasurementService) {
    const measurements = MeasurementService.getMeasurements();
    return measurements;
}

function _getFocusClasses(MeasurementService){
    const classes=MeasurementService.getClasses();
    for(let j=0; j<classes.length;j++) {
      classes[j].items.sort(sortCode);
      classes[j].subIdx = 0;
    }
    classes.sort(sortCode);
    return classes;
}

export default MeasurementTracking;
