import React, { useState, useEffect, useContext, useCallback } from 'react';
import ConnectedViewer from "./ConnectedViewer";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import getStudyDetail from "./core/http/getStudyDetail";
import getStudyDetail_ofv from "./core/http/getStudyDetail_ofv";
import getHospital from "./core/http/getHospital";
import getHospital_ofv from "./core/http/getHospital_ofv";
import actions from "./core/redux/actions";
import utils from "./core/utils";
import metadata from "./core/classes/metadata";
import {message} from "antd";

const { setStudyData } = actions;

const { studyMetadataManager } = utils;
const { OHIFStudyMetadata } = metadata;

const mapDispatchToProps = (dispatch) => {
    return {
        setStudyData: (StudyInstanceUID, data) => {
            dispatch(setStudyData(StudyInstanceUID, data));
        },
    };
};

const ViewerRetrieveStudy=function(props){
    const{setStudyData,params}=props;
    const kjStudyIds = params.kjStudyId.split(",");
    var studyDataList = [];
    const _mapStudiesToNewFormat = studies => {
        studyMetadataManager.purge();

        studies.map(study => {
            const studyMetadata = new OHIFStudyMetadata(study, study.StudyInstanceUID);
            studyMetadataManager.add(studyMetadata);
        });

    }

    const loadStudies = async (_params) => {

        getHospital({hospitalId:_params.hospitalId}).then(hospt=>{
            getStudyDetail(_params).then(function (data) {
                //console.log(data);
                if (data.error) {
                    console.log("errorCode:",data.error);
                     message.error('认证已过期，请重新获取');
                }
                else{
                    processStudies(data,hospt)
                }
            });
        });
    }

    const loadStudies_ofv = async (_params,accessToken) => {
        getStudyDetail_ofv(_params,accessToken).then(function (data) {
            if (data.error) {
                console.log("errorCode:",data.error);
                if (data.error.subCode === 'NO_LOGIN') {
                    message.error('链接已过期，请重新获取');
                } else{
                    message.error(data.error.message);
                }
            }
            else{
                getHospital_ofv({hospitalId:data.hospitalId},accessToken).then(function(hospt){
                    processStudies({...data,consultationOrderId:_params.consultationOrderId},hospt)
                });
            }
        });
    }

    const processStudies=(data,hospt)=>{
        if(params.ofv && !params.patientName && data.patientName00100010) {
             document.title= data.patientName00100010 + "-影像界面";
        }
        if(data.series){
            let patientInfo = {patName: data.patientName00100010,patNo:data.patientIdNumber,patGender:data.patientSex00100040,patAge:data.patientAge00101010,studyNo:data.studyId00200010,studyTime:data.studyDate00080020,modality:data.modality00080060,};
            let showAi = kjStudyIds.length>1;
            let _date = data.studyDate00080020
            if(_date){
               _date = _date.substring(0,10);
            }
            Promise.all(
                data.series.map(serie=> {
                    let imgs = [];
                    if(serie.images&&serie.images.length>0){
                        serie.images.map(e => {
                            let img={url:"wadouri:" +hospt.hospitalConfig.hospitalImageHost+ e.kjImagePath+".dcm",SOPInstanceUID:e.sopInstanceUid00080018}
                            imgs.push(img);
                        });
                    }

                    let displaySet={
                        StudyInstanceUID:data.studyInstanceUid0020000d,
                        displaySetInstanceUID: serie.kjSeriesId,
                        kjStudyId:serie.kjStudyId,
                        isAi: showAi ? false : serie.isAi,
                        StudyDate: _date,
                        SeriesInstanceUID:serie.seriesInstanceUid0020000e,
                        SeriesDescription:serie.seriesDescription0008103e,
                        SeriesNumber: 0,
                        InstanceNumber: 1,
                        numImageFrames: imgs.length,
                        Modality: '',
                        images:imgs,
                    };
                    return displaySet;
                })
            ).then(displaySets=>{
                let studyData={
                    studyId: data.studyId00200010,
                    StudyInstanceUID: data.studyInstanceUid0020000d,
                    StudyDescription:'',
                    StudyDate: _date,
                    modality: data.modality00080060,
                    PatientID: '',
                    patientInfo:patientInfo,
                    consultationOrderId:data.consultationOrderId,
                    autoLoadKjSeriesIds:data.autoLoadKjSeriesIds,
                    displaySets:displaySets,
                    series:[],
                };
                setStudyListData(studyData)
            });
        }
    }

    const sortDate = (a, b) => {
        if (a.StudyDate === b.StudyDate) {
            return 0;
        }
        else {
            return (a.StudyDate < b.StudyDate) ? 1 : -1;
        }
    }

    const setStudyListData=(_data)=>{
      studyDataList.push(_data);
      if(studyDataList.length === kjStudyIds.length) {
          studyDataList.sort(sortDate);
          _mapStudiesToNewFormat(studyDataList);
          setStudyData(studyDataList[0].studyId, studyDataList);
      }
    }

    let _params = {
        accessionNumber00080050:params.accessionNumber00080050,
        hospitalId:params.hospitalId,
        patientId00100020:params.patientId00100020,
        patientPhoneNumber:params.patientPhoneNumber,
        studyDate00080020:params.studyDate00080020,
        studyId00200010:params.studyId00200010,
        studyInstanceUid0020000d:params.studyInstanceUid0020000d,
    };

    useEffect(() => {
        if(params.ofv){
            let _title = "影像界面";
            if(params.patientName) {
              _title = params.patientName+'-'+_title
            }
            document.title= _title;
            for (var i = 0; i < kjStudyIds.length; i++) {
              loadStudies_ofv({kjStudyId:kjStudyIds[i],consultationOrderId:params.consultationOrderId,patientName:params.patientName},params.accessToken);
            }
        }
        else{
            if(params.kjStudyId){
                _params = {
                  kjStudyId:params.kjStudyId,
                  hospitalId:params.hospitalId
              };
            }
            loadStudies(_params);
        }
    }, []);

    let canShare = params.ofv?false:true;
    if(params.noShare){
       canShare = false;
    }

    let isCompare = false;
    if(kjStudyIds.length>1){
       canShare = false;
       isCompare = true;
    }

    return (
        <ConnectedViewer kjStudyId={params.kjStudyId} hospitalId={params.hospitalId} canShare={canShare} isCompare={isCompare}></ConnectedViewer>
    );
}

ViewerRetrieveStudy.propTypes = {
    setStudyData: PropTypes.func,
    params:PropTypes.object,
};

export default connect(null, mapDispatchToProps)(ViewerRetrieveStudy);
