import cloneDeep from 'lodash.clonedeep';

const defaultState = {
  studyData: [],
};

const studies = (state = defaultState, action) => {
  switch (action.type) {
    case 'SET_STUDY_DATA': {
      /*
      const updatedStudyData = cloneDeep(state.studyData);
      updatedStudyData[action.StudyInstanceUID] = cloneDeep(action.data);

      return Object.assign({}, state, { studyData: updatedStudyData });
      *
       */
      return Object.assign({}, { studyData: action.data });
    }
    case 'CLEAR_STUDY_DATA':{
      return defaultState;
    }
    default:
      return state;
  }
};

export default studies;
