import { Icon } from './ui/elements/Icon';
import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import './ui/elements/iconfont.css';

export function CustomError(props) {

    return (
        <div className="error">
            <i className={"iconfont icon-error"} />
            <span className="txt">{props.label}</span>
        </div>
    );
}

CustomError.propTypes = {
    label: PropTypes.string
}

export default CustomError;
