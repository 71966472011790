import React, { useEffect, useState } from 'react';

import CornerstoneCacheService from '../../core/services/ViewportService/CornerstoneCacheService';
import ViewportImageScrollbar from './ViewportImageScrollbar';
import ViewportOverlay from "./ViewportOverlay";
import ViewportOrientationMarkers from './ViewportOrientationMarkers';
import {connect} from "react-redux";

const mapStateToProps = state => {
    const {studyData} =state.studies;
    return {
        patientInfo:!studyData||!studyData.length?{}:studyData[0].patientInfo,
        studyNums: studyData.length
    }
}

function CornerstoneOverlays(props) {
    const { viewportIndex, element, scrollbarHeight, servicesManager,patientInfo,studyNums,StudyDate,idx } = props;
    const { CornerstoneViewportService } = servicesManager.services;
    const [imageSliceData, setImageSliceData] = useState({
        imageIndex: 0,
        numberOfSlices: 0,
    });
    const [viewportData, setViewportData] = useState(null);

    useEffect(() => {
        const { unsubscribe } = CornerstoneCacheService.subscribe(
            CornerstoneCacheService.EVENTS.VIEWPORT_DATA_CHANGED,
            props => {
                if (props.viewportIndex !== viewportIndex) {
                    return;
                }
                setViewportData(props.viewportData);
            }
        );

        return () => {
            unsubscribe();
        };
    }, [viewportIndex]);

    if (!element) {
        return null;
    }

    if (viewportData) {
        const viewportInfo = CornerstoneViewportService.getViewportInfoByIndex(
            viewportIndex
        );

        if (viewportInfo?.viewportOptions?.customViewportProps?.hideOverlays) {
            return null;
        }
    }

    return (
        <div>
            <ViewportImageScrollbar
                viewportIndex={viewportIndex}
                viewportData={viewportData}
                element={element}
                imageSliceData={imageSliceData}
                setImageSliceData={setImageSliceData}
                scrollbarHeight={scrollbarHeight}
                servicesManager={servicesManager}
            />
            <ViewportOverlay
                imageSliceData={imageSliceData}
                viewportData={viewportData}
                viewportIndex={viewportIndex}
                servicesManager={servicesManager}
                element={element}
                patientInfo={patientInfo}
                studyNums={studyNums}
                studyDate={StudyDate}
                idx={idx}
            />
            <ViewportOrientationMarkers
                imageSliceData={imageSliceData}
                element={element}
                viewportData={viewportData}
                viewportIndex={viewportIndex}
            />
        </div>
    );
}


export default connect(mapStateToProps, null)(CornerstoneOverlays);
