import touchStart from "./touchStart";
import touchMove from "./touchMove";
import touchState from "./touchEventState";


const enable = function (element: HTMLDivElement,viewportData): void {
    touchState.screen={width: element.clientWidth,height: element.clientHeight}
    touchState.imageSliceData.numberOfSlices=viewportData.imageIds.length
    element.addEventListener('touchstart', touchStart, false)
    element.addEventListener('touchmove', touchMove, false)
};

const disable = function (element: HTMLDivElement) {
    element.removeEventListener('touchstart', touchStart)
    element.removeEventListener('touchmove', touchMove)
}

const touchEventDispatcher = {
    enable,
    disable,
};

export default touchEventDispatcher;
