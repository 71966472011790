import './ExpandableToolMenu.styl';

import { OverlayTrigger } from './components/overlayTrigger';
import PropTypes from 'prop-types';
import React from 'react';
import ToolbarButton from './ToolbarButton.js';
import { Tooltip } from './components/tooltip';
import { PresetsBox } from './components/presetsBox';

class ExpandableToolMenu extends React.Component {
  static propTypes = {
    /** Button label */
    label: PropTypes.string.isRequired,
    icon: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        name: PropTypes.string.isRequired,
      }),
    ]),
    onGroupMenuClick: PropTypes.func,
    getVoi:PropTypes.func,
    activeCommand: PropTypes.string,
  };

  static defaultProps = {
    buttons: [],
    icon: 'ellipse-circle',
    label: 'More',
  };

  constructor(props) {
    super(props);
    this.state = {
      isExpanded: false,
    };
  }

  toolbarMenuOverlay = () => (
    <Tooltip
      placement="bottom"
      className="tooltip-toolbar-overlay"
      id={`${Math.random()}_tooltip-toolbar-overlay}`}
    >
      {this.getPresetsBox()}
    </Tooltip>
  );

  handleClick = (event) => {
    this.refs.overlay.hide();
    if (this.props.onGroupMenuClick) {
      this.props.onGroupMenuClick(event, this.props);
    }
  }

  getPresetsBox = () => {
    return (
      <PresetsBox currentVoi={this.props.getVoi} onClick={this.handleClick}></PresetsBox>
    );
  };

  currentVoi=()=>{
    return this.props.getVoi();
  };

  activeIcon = () => {
    return this.props.icon;
  };

  onExpandableToolClick = () => {
    this.setState({
      isExpanded: !this.state.isExpanded,
    });
  };

  onOverlayHide = () => {
    this.setState({
      isExpanded: false,
    });
  };

  render() {
    const getToolBarButtonComponent = () => {
      return (
        <ToolbarButton
          key="menu-button"
          type="tool"
          label={this.props.label}
          icon={this.activeIcon()}
          className={'toolbar-button expandableToolMenu'}
        />
      );
    };

    const toolbarComponent = getToolBarButtonComponent();

    return (
      <OverlayTrigger
        ref="overlay"
        key="menu-button"
        trigger="click"
        placement="bottom"
        rootClose={true}
        handleHide={this.onOverlayHide}
        onClick={this.onExpandableToolClick}
        overlay={this.toolbarMenuOverlay()}
      >
        {toolbarComponent}
      </OverlayTrigger>
    );
  }
}

export default ExpandableToolMenu;
