import './presetsBox.styl';

import React from 'react';
import PropTypes from 'prop-types';


const presets=[
  {id:0,label:'缺省值',wl:50,ww:650},
  {id:1,label:'  脑窗',wl:40,ww:80},
  {id:2,label:'血管窗',wl:300,ww:600},
  {id:3,label:'腹部窗',wl:40,ww:400},
  {id:4,label:'  骨窗',wl:300,ww:1500},
  {id:5,label:'  肺窗',wl:-400,ww:1500},
  {id:6,label:'纵隔窗',wl:40,ww:400},
];

const PresetsBox = function(props) {
  const { onClick ,currentVoi} = props;
  const [ww,setWw]=React.useState(0);
  const [wl,setWl]=React.useState(0);
  const handleClick = event => {
    if (onClick) {
      onClick(event, props);
    }
  };

  const onWwChange = (event)=>{
    let v=event.target.value;
    setWw(v);
  };

  const onWlChange = (event)=>{
    let v=event.target.value;
    setWl(v);
  };

  const submitClick = () => {
    if (onClick) {
      let val={id:7,wl:parseInt(wl),ww:parseInt(ww)};
      onClick(val, props);
    }
  };

  const {windowWidth_default,windowCenter_default}=currentVoi();
  presets[0].ww=windowWidth_default;
  presets[0].wl=windowCenter_default;

  return (
    <div id="presetsBox" className="box-presets active">
      <ul className="options">
        <li className="currentValue">
          <label>当前值</label><span className="value"><span>WL: {Math.round(currentVoi().windowCenter)}</span><span>WW: {Math.round(currentVoi().windowWidth)}</span></span>
        </li>
        {
          presets.map(pst=>{
            return(
          <li className="opt" key={pst.id} data-keycode="48" onClick={()=>handleClick(pst)}>
          <label>[{pst.id}]{pst.label}</label><span className="value"><span>WL: {pst.wl}</span><span>WW: {pst.ww}</span></span>
          </li>);
          })
        }
        <li className="opt">
          <label style={{width: "3.5em"}}>自定义</label><span className="value">
            <span>WL: <input type="number" value={wl} name="customizeWl" onChange={(e)=>{onWlChange(e)}} /></span>
            <span>WW: <input type="number" value={ww} name="customizeWW" onChange={(e)=>{onWwChange(e)}} /></span></span>
          <button className="submitCustomPreset" onClick={()=>submitClick()}>确定</button>
        </li>
      </ul>
    </div>
  );
}

PresetsBox.propTypes = {
  onClick: PropTypes.func,
};

export default PresetsBox;
