import * as cornerstoneTools from '@cornerstonejs/tools';
import DICOMSRDisplayTool from "../../dicom-sr/tools/DICOMSRDisplayTool";

const {
    PanTool,
    WindowLevelTool,
    StackScrollMouseWheelTool,
    ZoomTool,
    StackScrollTool,
    MagnifyTool,
    LengthTool,
    AngleTool,
    ProbeTool,
    EllipticalROITool,
    RectangleROITool,
    ToolGroupManager,
    Enums: csToolsEnums,
} = cornerstoneTools;
const { MouseBindings } = csToolsEnums;

const stackToolGroupId = 'STACK_TOOL_GROUP_ID';

const ManipulationTools=()=>{

    // Define a tool group, which defines how mouse events map to tool commands for
    // Any viewport using the group
    const toolGroup = ToolGroupManager.createToolGroup(stackToolGroupId);

    // Add tools to the tool group
    toolGroup.addTool(WindowLevelTool.toolName);
    toolGroup.addTool(PanTool.toolName);
    toolGroup.addTool(ZoomTool.toolName);
    toolGroup.addTool(StackScrollMouseWheelTool.toolName);
    toolGroup.addTool(MagnifyTool.toolName);
    toolGroup.addTool(StackScrollTool.toolName);

    toolGroup.addTool(LengthTool.toolName);
    toolGroup.addTool(AngleTool.toolName);
    toolGroup.addTool(ProbeTool.toolName);
    toolGroup.addTool(EllipticalROITool.toolName);
    toolGroup.addTool(RectangleROITool.toolName);
    toolGroup.addTool(DICOMSRDisplayTool.toolName);

    //toolGroup.setToolConfiguration(ZoomTool.toolName,{pinchToZoom:false,pan:true});
    // Set the initial state of the tools, here all tools are active and bound to
    // Different mouse inputs
    toolGroup.setToolActive(WindowLevelTool.toolName, {
        bindings: [
            {
                mouseButton: MouseBindings.Primary, // Left Click
            },
        ],
    });
    toolGroup.setToolActive(PanTool.toolName, {
        bindings: [
            {
                mouseButton: MouseBindings.Auxiliary, // Middle Click
            },
        ],
    });
    toolGroup.setToolActive(ZoomTool.toolName, {
        bindings: [
            {
                mouseButton: MouseBindings.Secondary, // Right Click
                numTouchPoints: 2,
            },
        ],
    });
    toolGroup.setToolActive(StackScrollMouseWheelTool.toolName);

    return toolGroup;
}

export default ManipulationTools;
export { ManipulationTools,stackToolGroupId};
