import * as cornerstone from '@cornerstonejs/core';
import * as cornerstoneTools from '@cornerstonejs/tools';
import {init as csRenderInit} from '@cornerstonejs/core';
import {init as csToolsInit} from "@cornerstonejs/tools";
import initCornerstoneWADOImageLoader from "./utils/helpers/initCornerstoneWADOImageLoader";

import initVolumeLoader from "./utils/helpers/initVolumeLoader";
import initProviders from "./utils/helpers/initProviders";
import initSRTools from "./viewport/tools/initSRTools";
import {ManipulationTools} from "./viewport/tools/manipulationTools";
import {VolumeRenderTools} from "./viewport/tools/volumeRenderTools";
import {utilities} from "@cornerstonejs/tools";
import {eventTarget,imageLoadPoolManager,Enums} from "@cornerstonejs/core";
import {EVENTS} from "@cornerstonejs/core";

export default async function initCornerstone() {

  initProviders();
  initCornerstoneWADOImageLoader();
  initVolumeLoader();
  await csRenderInit();
  await csToolsInit();

  cornerstone.cache.setMaxCacheSize(1073741824*4); //4G
  imageLoadPoolManager.maxNumRequests={
    interaction: 200,
    thumbnail: 200,
    prefetch: 200};


  initSRTools();
  ManipulationTools();
  VolumeRenderTools();

  const newStackCallback = evt => {
    const { element } = evt.detail;
    try{
        utilities.stackPrefetch.enable(element);
    }catch(e){
      console.log('catch: stackPrefetch error',e)
    }
  };

  function elementEnabledHandler(evt) {
    const { element } = evt.detail;
    eventTarget.addEventListener(
        EVENTS.STACK_VIEWPORT_NEW_STACK,
        newStackCallback
    );
  }

  function elementDisabledHandler(evt) {
    const { viewportId, element } = evt.detail;
    imageLoadPoolManager.clearRequestStack(Enums.RequestType.Prefetch);
   // utilities.stackPrefetch.disable(element);
  }

  eventTarget.addEventListener(
      EVENTS.ELEMENT_ENABLED,
      elementEnabledHandler.bind(null)
  );

  eventTarget.addEventListener(
      EVENTS.ELEMENT_DISABLED,
      elementDisabledHandler.bind(null)
  );

  window.cornerstone = cornerstone;
  window.cornerstoneTools = cornerstoneTools;
}
