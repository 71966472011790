const focusClasses=[
    {
        code: '1', name: '胸膜病变',
        items: [
            { code: '1-1', name: '气胸' },
            { code: '1-2', name: '胸腔积液' },
            { code: '1-3', name: '胸膜增厚、钙化、黏连' },
            { code: '1-4', name: '胸膜肿瘤',
                items: [
                    {   code: '1-4-1', name: '胸膜原发肿瘤',
                        items: [
                          { code: '1-4-1-1', name: '恶性间皮瘤' },
                          { code: '1-4-1-2', name: '脂肪瘤/囊肿' },
                          { code: '1-4-1-3', name: '孤立性纤维瘤' },
                          { code: '1-4-1-4', name: '淋巴瘤' },
                          { code: '1-4-1-5', name: '肉瘤' }
                        ]
                    },
                    { code: '1-4-2', name: '转移瘤' }
                ]
            }
        ]
    },
    {
        code: '2', name: '纵隔病变',
        items: [
            { code: '2-1', name: '纵膈肿瘤性病变',
                items: [
                    {   code: '2-1-1', name: '前纵隔',
                        items: [
                            { code: '2-1-1-1', name: '胸腺瘤/囊肿' },
                            { code: '2-1-1-2', name: '生殖细胞肿瘤' },
                            { code: '2-1-1-3', name: '甲状腺瘤' }
                        ]
                    },
                    {   code: '2-1-2', name: '中纵隔',
                        items: [
                            { code: '2-1-2-1', name: '淋巴瘤' }
                        ]
                    },
                    {   code: '2-1-3', name: '后纵隔',
                        items: [
                            { code: '2-1-3-1', name: '神经源性肿瘤' }
                        ]
                    }
                ]
            },
            { code: '2-2', name: '感染/出血/纵隔炎',
                items: [
                    { code: '2-2-1', name: '胸腺瘤' },
                    { code: '2-2-2', name: '良性囊肿' },
                    { code: '2-2-3', name: '胸腺癌' },
                    { code: '2-2-4', name: '纵隔生殖细胞瘤' },
                    { code: '2-2-5', name: '纵隔软组织肿瘤' },
                    { code: '2-2-6', name: '神经内分泌肿瘤' },
                    { code: '2-2-7', name: '胸腺增生' },
                    { code: '2-2-8', name: '淋巴瘤' },
                    { code: '2-2-9', name: '淋巴组织增生' },
                    { code: '2-2-10', name: '异位甲状腺肿瘤' },
                    { code: '2-2-11', name: '肉芽肿性炎' },
                    { code: '2-2-12', name: '神经源性肿瘤' }
                ]
            }
        ]
    },
    {
        code: '3', name: '气管病变',
        items: [
            { code: '3-1', name: '气管扩张',
                items: [
                    { code: '3-1-1', name: '囊状/柱状型支扩' },
                    { code: '3-1-2', name: '曲张性支扩' },
                    { code: '3-1-3', name: '支气管黏液栓' }
                ]
            },
            { code: '3-2', name: '气管狭窄',
                items: [
                    { code: '3-2-1', name: '剑鞘状气管' },
                    { code: '3-2-2', name: '气管塌陷' },
                    { code: '3-2-3', name: '气管闭塞' }
                ]
            },
            { code: '3-3', name: '占位性病变',
                items: [
                    { code: '3-3-1', name: '肉芽肿性病变' },
                    { code: '3-3-2', name: '气管异物' },
                    { code: '3-3-3', name: '气管内膜结核' },
                    { code: '3-3-4', name: '囊肿/栓塞' },
                    { code: '3-3-5', name: '气管癌' }
                ]
            }
        ]
    },
    {
        code: '4', name: '慢阻肺',
        items: [
            { code: '4-1', name: '慢性支气管炎' },
            { code: '4-2', name: '肺气肿' },
            { code: '4-3', name: '肺大泡' },
            { code: '4-4', name: '肺动脉高压' }
        ]
    },
    {
        code: '5', name: '增殖性病变',
        items: [
            { code: '5-1', name: '慢性炎症' },
            { code: '5-2', name: '肺结核' },
            { code: '5-3', name: '矽肺' },
            { code: '5-4', name: '炎性假瘤' },
            { code: '5-5', name: '结节病', drawType: 'RECTANGLE' }
        ]
    },
    {
        code: '6', name: '空洞和空洞性病变',
        items: [
            { code: '6-1', name: '空腔性病变',
                items: [
                    { code: '6-1-1', name: '肺大泡' },
                    { code: '6-1-2', name: '囊肿' }
                ]
            },
            { code: '6-2', name: '空洞性病变',
                items: [
                    { code: '6-2-1', name: '肺癌' },
                    { code: '6-2-2', name: '肺结核' },
                    { code: '6-2-3', name: '真菌感染' },
                    { code: '6-2-4', name: '韦格氏肉芽肿' }
                ]
            }
        ]
    },
    {
        code: '7', name: '渗出/突变',
        items: [
            { code: '7-1', name: '肺炎',
                items: [
                    { code: '7-1-1', name: '间质性肺炎',
                        items: [
                            { code: '7-1-1-1', name: '间质纤维化' },
                            { code: '7-1-1-2', name: '结节病' },
                            { code: '7-1-1-3', name: '过敏性肺炎' },
                            { code: '7-1-1-4', name: '尘肺' }
                         ]
                    },
                    { code: '7-1-2', name: '细菌性肺炎' },
                    { code: '7-1-3', name: '病毒性肺炎',
                        items: [
                            { code: '7-1-3-1', name: '新冠肺炎' },
                            { code: '7-1-3-2', name: '其他病毒性肺炎' },
                        ]
                    },
                    { code: '7-1-4', name: '真菌性肺炎' }
                ]
            },
            { code: '7-2', name: '肺结核' },
            { code: '7-3', name: '肺水肿' },
            { code: '7-4', name: '肺出血' },
            { code: '7-5', name: '肺不张',
                items: [
                    { code: '7-5-1', name: '肺段不张' },
                    { code: '7-5-2', name: '小叶不张' }
                ]
            }
        ]
    },
    {
        code: '8',
        name: '结节和肿块',
        items: [
            { code: '8-1', name: '肿瘤性病变',
                items: [
                    { code: '8-1-1', name: '恶性',
                        items: [
                            { code: '8-1-1-1', name: '腺癌' },
                            { code: '8-1-1-2', name: '鳞癌' },
                            { code: '8-1-1-3', name: '小细胞癌' },
                            { code: '8-1-1-4', name: '大细胞癌' },
                            { code: '8-1-1-5', name: '神经内分泌癌' }
                        ]
                    },
                    {   code: '8-1-2', name: '良性',
                        items: [
                            { code: '8-1-2-1', name: '错构癌' },
                            { code: '8-1-2-2', name: '硬化性血瘤癌' }
                        ]
                    },
                 ]
            },
            { code: '8-2', name: '非肿瘤性病变',
                items: [
                    { code: '8-2-1', name: '肉芽肿' }
                 ]
            },
            { code: '8-3', name: '肺实性结节', tip: '实性结节', drawType: 'RECTANGLE' },
            { code: '8-4', name: '肺亚实性结节', tip: '亚实性结节', drawType: 'RECTANGLE' },
            { code: '8-5', name: '磨玻璃结节', tip: '磨玻璃结节', drawType: 'RECTANGLE' },
            { code: '8-6', name: '肺内钙化结节', tip: '钙化结节', drawType: 'RECTANGLE' },
            { code: '8-7', name: '胸膜钙化结节', tip: '钙化结节', drawType: 'RECTANGLE' },
            { code: '8-8', name: '胸膜结节', tip: '实性结节', drawType: 'RECTANGLE' },
            { code: '8-9', name: '肿块', tip: '肿块', drawType: 'RECTANGLE' }
        ]
    }
];

export default focusClasses;
