
export const defaultState = 'Wwwc';

const activetools = (state= defaultState, action) => {
    switch (action.type) {
        case 'SET_ACTIVETOOL': {
            return action.state;
        }
        default:
            return state;
    }
};

export default activetools;
