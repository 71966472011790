import {
  applyMiddleware,
  combineReducers,
  createStore,
  compose,
} from 'redux/es/redux.js';

import redux from './../core/redux/';

// Combine our @ohif/core and oidc reducers
// Set init data, using values found in localStorage
const { reducers, localStorage, sessionStorage } = redux;

const rootReducer = combineReducers(reducers);
const preloadedState = {
  ...localStorage.loadState(),
  ...sessionStorage.loadState(),
};

const store = createStore(
  rootReducer,
  preloadedState
);

// When the store's preferences change,
// Update our cached preferences in localStorage
store.subscribe(() => {
  /*
  localStorage.saveState({
    preferences: store.getState().preferences,
  });
  sessionStorage.saveState({
    servers: store.getState().servers,
  });
  *
   */
});

export default store;
