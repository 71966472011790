import React, { useState,useEffect,useRef,useCallback } from 'react';
import ReactResizeDetector from 'react-resize-detector';
import { RenderingEngine, Types, Enums,eventTarget } from '@cornerstonejs/core';
import * as cornerstoneTools from '@cornerstonejs/tools';
import './CornerstoneViewport.css';
import metadataProvider from "../core/classes/MetadataProvider";
import {stackToolGroupId} from "../viewport/tools/manipulationTools"
import cornerstoneWADOImageLoader from "cornerstone-wado-image-loader";
import WADORSHeaderProvider from "../utils/helpers/WADORSHeaderProvider";
const {ToolGroupManager} = cornerstoneTools;
const renderingEngineId = 'myRenderingEngine';
const viewportId = 'CT_AXIAL_STACK';
const { ViewportType } = Enums;

const CornerstoneViewport = React.memo( props =>{

    const {displaySet}=props
    const {images}=displaySet
    const imageIds=[]
    for (let i = 0; i < images.length; i++){
        imageIds.push(images[i].url)
    }

    const [scrollbarHeight, setScrollbarHeight] = useState('100px');

    const elementRef = useRef();


    const setImageScrollBarHeight = useCallback(() => {
        const scrollbarHeight = `${elementRef.current.clientHeight - 20}px`;
        setScrollbarHeight(scrollbarHeight);
    }, [elementRef]);

    // useCallback for onResize
    const onResize = useCallback(() => {
        if (elementRef.current) {
           // CornerstoneViewportService.resize();
            setImageScrollBarHeight();
        }
    }, [elementRef]);

    /*
    eventTarget.addEventListener(Enums.Events.IMAGE_LOADED,(image)=>{
        const metadata=image.detail.image.data.byteArray.buffer
        metadataProvider.addInstance(metadata);
    });
    */

    useEffect(() =>  {
        const element=elementRef.current
        const viewportInput = {
            viewportId,
            element,
            type: ViewportType.STACK,
        };

        const renderingEngine = new RenderingEngine(renderingEngineId);
        renderingEngine.enableElement(viewportInput);

        const toolGroup = ToolGroupManager.getToolGroup(stackToolGroupId);
        toolGroup.addViewport(viewportId, renderingEngineId);

        const viewport = renderingEngine.getViewport(viewportInput.viewportId);

        viewport.setStack(imageIds, 0);

        viewport.render();
    },[imageIds])


    return (
            <div className="viewport-wrapper">
                <ReactResizeDetector
                    handleWidth
                    handleHeight
                    skipOnMount={true} // Todo: make these configurable
                    refreshMode={'debounce'}
                    refreshRate={100}
                    onResize={onResize}
                    targetRef={elementRef.current}
                >
                <div className="cornerstone-viewport-element"
                     style={{ height: '100%', width: '100%' }}
                     ref={elementRef}></div>
                </ReactResizeDetector>
            </div>
    );
})

export default CornerstoneViewport;
