// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/images/list_bg.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../assets/images/list_hd.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, "\n.content{\n    /*height:100%;*/\n    background: #e3e9f9;\n}\n.patient{\n    width:100%;\n    aspect-ratio:2;\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    background-size: cover;\n    margin: 0px;\n    padding: 40px 30px;\n    font-size: 20px;\n}\n\n.description{\n    font-size: 14px;\n    font-weight: bold;\n    color:#7c8188;\n    margin:10px;\n}\n\n.list{\n\n}\n\n.list .item{\n    height: 100px;\n    vertical-align: center;\n}\n\n.card{\n    margin:10px 20px;\n    display: flex;\n    flex-direction: row;\n    background: #fff;\n    border: 1px solid #fff;\n    border-radius: 5px;\n    color:#939393;\n    font-size: 13px;\n}\n\n.card .card_l{\n    width:15px;\n    height: 100px;\n    border-radius: 5px 0px 0px 5px;\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n    background-size: contain;\n}\n.card .card_c{\n    padding: 0px 10px;\n    flex: 1;\n    display: flex;\n    flex-direction: column;\n}\n.card .card_c .card_c_cap{\n    display: flex;\n    flex-direction: row;\n    margin: 5px 5px;\n    font-size: 16px;\n}\n.card .card_c .card_c_cap .a{\n    background: #54BAED;\n    padding: 3px 5px;\n    font-size: 12px;\n    font-weight: bold;\n    color: #ffffff;\n    width: 65px;\n    text-align: center;\n    border-radius: 3px;\n}\n.card .card_c .card_c_cap .b{\n    padding-left: 5px;\n    color:#000000;\n    flex:1;\n}\n.card .card_c .card_c_txt{\n    display: flex;\n    flex-direction: row;\n    margin: 0px 5px;\n}\n.card .card_c .card_c_txt .a{\n    width: 65px;\n}\n.card .card_c .card_c_txt .b{\n    padding-left: 5px;\n    flex: 1;\n}\n.card .card_r{\n    width: 50px;\n}\n.card .card_r i{\n    position: relative;\n    top: 40px;\n}\n", ""]);
// Exports
module.exports = exports;
