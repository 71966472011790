import throttle from 'lodash.throttle';
import { getEnabledElement as OHIFgetEnabledElement } from './viewport/state';
import html2canvas from "html2canvas";
import {message} from "antd";
import uploadImageScreenshot from "./core/http/uploadImageScreenshot";
import setCornerstoneLayout from "./viewport/utils/setCornerstoneLayout";
import setMPRLayout from "./viewport/utils/setMPRLayout";
//const scroll = cornerstoneTools.import('util/scroll');

import * as cornerstone3D from '@cornerstonejs/core';
import {StackViewport,getEnabledElement,utilities as csUtils} from '@cornerstonejs/core';
import * as cornerstoneTools from '@cornerstonejs/tools';
import {servicesManager} from  './App';


const {
    ToolGroupManager,
    Enums: csToolsEnums,
    WindowLevelTool,
    CrosshairsTool,
    LengthTool,
    cancelActiveManipulations
} = cornerstoneTools;

const { MouseBindings } = csToolsEnums;
const {Enums}=cornerstone3D;
const { Events } = Enums;


const commandsModule =()=>{

    function _getActiveViewportEnabledElement(activeViewportIndex) {
        const element = OHIFgetEnabledElement(activeViewportIndex) || {};
        console.log()
        const enabledElement = getEnabledElement(element);
        return enabledElement;
    }

    const actions = {
        resetViewport: ({ viewports }) => {
            const enabledElement = _getActiveViewportEnabledElement(viewports.activeViewportIndex);
            if (!enabledElement) {
                return;
            }
            const { viewport } = enabledElement;

            if (viewport instanceof StackViewport) {
                viewport.resetProperties();
                viewport.resetCamera();
                viewport.render();
            }
            else{
                viewport.reset();
                viewport.resetCamera();
                viewport.render();
            }
        },
        invertViewport: ({ viewports }) => {
            const enabledElement = _getActiveViewportEnabledElement(viewports.activeViewportIndex);
            if (enabledElement) {
                const { viewport } = enabledElement;
                if (viewport instanceof StackViewport) {
                    const { invert } = viewport.getProperties();
                    viewport.setProperties({ invert: !invert });
                    viewport.render();
                }
            }
        },
        invertViewportAll: ({ viewports }) => {
            const ln = viewports.layout.viewports.length
            for (let i=0; i<ln; i++) {
              const enabledElement = _getActiveViewportEnabledElement(i);
              if (enabledElement) {
                const { viewport } = enabledElement;
                if (viewport instanceof StackViewport) {
                    const { invert } = viewport.getProperties();
                    viewport.setProperties({ invert: !invert });
                    viewport.render();
                }
              }
            }
        },
        clearAnnotations:({ viewports })=>{
            const element = OHIFgetEnabledElement(viewports.activeViewportIndex) || {};
            // const annotationUID = cancelActiveManipulations(element);
            // console.log("annotationUID:",annotationUID)
            //cornerstoneTools.annotation.state.removeAnnotation(annotationUID, element);
            cornerstoneTools.annotation.state.removeAllAnnotations(element);
            const {CornerstoneViewportService}=servicesManager.services;
            const renderingEngine = CornerstoneViewportService.getRenderingEngine();
            renderingEngine.render();
        },
        nextImage: ({ viewports }) => {
            const enabledElement = _getActiveViewportEnabledElement(viewports.activeViewportIndex);
            if (enabledElement) {
                const { viewport } = enabledElement;
                if (viewport instanceof StackViewport) {
                    viewport.scroll(1)
                }
            }
        },
        previousImage: ({ viewports }) => {
            const enabledElement = _getActiveViewportEnabledElement(viewports.activeViewportIndex);
            if (enabledElement) {
                const { viewport } = enabledElement;
                if (viewport instanceof StackViewport) {
                    viewport.scroll(-1)
                }
            }
        },
        setCornerstoneLayout: () => {
            const toolGroup = ToolGroupManager.getToolGroup("VOLUME_TOOLGROUP_ID");
            const activeTool = toolGroup.getActivePrimaryMouseButtonTool();
            if (activeTool) {
                toolGroup.setToolPassive(activeTool);
                toolGroup.setToolDisabled(activeTool);
            }
            setCornerstoneLayout();
        },
        setToolActive: ({ toolName, toolGroupId = null }) => {
            const toolGroup = ToolGroupManager.getToolGroup(toolGroupId);

            if (!toolGroup) {
                console.warn('No tool group found for toolGroupId:', toolGroupId);
                return;
            }
            // Todo: we need to check if the viewports of the toolGroup is actually
            // parts of the ViewportGrid's viewports, if not we return

            const activeTool = toolGroup.getActivePrimaryMouseButtonTool();

            if (activeTool) {
                toolGroup.setToolPassive(activeTool);
            }

            toolGroup.setToolActive(toolName, {
                bindings: [{ mouseButton: MouseBindings.Primary }],
            });
        },
        setWindowLevel: ({ viewports, window, level }) => {
            const windowWidthNum = Number(window);
            const windowCenterNum = Number(level);

            const { viewportId } = _getActiveViewportEnabledElement(viewports.activeViewportIndex);

            const {CornerstoneViewportService}=servicesManager.services;
            const renderingEngine = CornerstoneViewportService.getRenderingEngine();
            const viewport = renderingEngine.getViewport(viewportId);

            const { lower, upper } = csUtils.windowLevel.toLowHighRange(
                windowWidthNum,
                windowCenterNum
            );

            viewport.setProperties({
                voiRange: {
                    upper,
                    lower,
                },
            });
            viewport.render();
        },
        setWindowLevelAll: ({ viewports, window, level }) => {
            const windowWidthNum = Number(window);
            const windowCenterNum = Number(level);

            const {CornerstoneViewportService}=servicesManager.services;
            const renderingEngine = CornerstoneViewportService.getRenderingEngine();
            const { lower, upper } = csUtils.windowLevel.toLowHighRange(
                windowWidthNum,
                windowCenterNum
            );
            const ln = viewports.layout.viewports.length
            for (let i=0; i<ln; i++) {
                const { viewportId } = _getActiveViewportEnabledElement(i);
                const viewport = renderingEngine.getViewport(viewportId);
                viewport.setProperties({
                    voiRange: {
                      upper,
                      lower,
                    },
                });
                viewport.render();
            }
        },
        enableLevelTool: () => {
            //这个方法只是在MRP中调用,StackViewport下面是TOOL_ACTIVE模式
            const toolGroup = ToolGroupManager.getToolGroup("VOLUME_TOOLGROUP_ID");
            const activeTool = toolGroup.getActivePrimaryMouseButtonTool();
            if (activeTool) {
                toolGroup.setToolPassive(activeTool);
            }
            toolGroup.setToolDisabled(CrosshairsTool.toolName);
            toolGroup.setToolActive(WindowLevelTool.toolName, {
                bindings: [{ mouseButton: MouseBindings.Primary }],
            });
        },
        enableCrosshairsTool: () => {
            const toolGroup = ToolGroupManager.getToolGroup("VOLUME_TOOLGROUP_ID");
            const activeTool = toolGroup.getActivePrimaryMouseButtonTool();
            if (activeTool) {
                toolGroup.setToolPassive(activeTool);
            }
            toolGroup.setToolActive(CrosshairsTool.toolName, {
                bindings: [{ mouseButton: MouseBindings.Primary }],
            });
        },
        displayCrosshairs:({viewports, display})=>{
            const toolGroup = ToolGroupManager.getToolGroup("VOLUME_TOOLGROUP_ID");
            if(display){
                toolGroup.setToolDisabled(WindowLevelTool.toolName);
                toolGroup.setToolActive(CrosshairsTool.toolName, {
                    bindings: [{ mouseButton: MouseBindings.Primary }],
                });
            }
            else{
                toolGroup.setToolDisabled(CrosshairsTool.toolName);
                toolGroup.setToolActive(WindowLevelTool.toolName, {
                    bindings: [{ mouseButton: MouseBindings.Primary }],
                });
            }
        },
        enableDistanceTool:({viewports})=>{
            const toolGroup = ToolGroupManager.getToolGroup("VOLUME_TOOLGROUP_ID");
            const activeTool = toolGroup.getActivePrimaryMouseButtonTool();
            if (activeTool) {
                toolGroup.setToolPassive(activeTool);
            }
            toolGroup.setToolActive(LengthTool.toolName, {
                bindings: [{ mouseButton: MouseBindings.Primary }],
            });
        },
        screenShot:({consultationOrderId})=>{
            //viewport-element
                html2canvas(document.querySelector(".ViewerMain")).then(
                    (canvas)=> {
                var dataUrl = canvas.toDataURL("image/png");

                // 不是从健康咨询平台过来的则直接保存图片
                if(!consultationOrderId){
                    var eleLink = document.createElement("a");
                    eleLink.href = dataUrl;
                    eleLink.download = "image-"+new Date().getTime();
                    document.body.appendChild(eleLink);
                    eleLink.click();
                    document.body.removeChild(eleLink);
                    message.info('截图保存成功，请查看下载记录');
                    return;
                }

                uploadImageScreenshot({
                    consultationOrderId: consultationOrderId,
                    fileBase64: dataUrl
                }).then(function (data) {
                    if (data.code === 200) {
                      message.info('截图已成功发送至健康咨询平台');
                    } else {
                       message.error('截图失败，' + data.message);
                    }
                });
            });
        },
        mpr2d:async ({ viewports }) => {
            const displaySet =
                viewports.viewportSpecificData[viewports.activeViewportIndex];
            try {
                const {MeasurementService}=servicesManager.services;
                MeasurementService.clearMeasurements();
                await setMPRLayout(displaySet,  1, 3);
            } catch (error) {
                throw new Error(error);
            }
        },
        show3d: () => {
            console.log('this is show3d')
        }
    }

    const definitions = {
        invertViewport: {
            commandFn: actions.invertViewport,
            storeContexts: ['viewports'],
            options: {},
        },
        invertViewportAll: {
            commandFn: actions.invertViewportAll,
            storeContexts: ['viewports'],
            options: {},
        },
        resetViewport: {
            commandFn: actions.resetViewport,
            storeContexts: ['viewports'],
            options: {},
        },
        clearAnnotations: {
            commandFn: actions.clearAnnotations,
            storeContexts: ['viewports'],
            options: {},
        },
        nextImage: {
            commandFn: actions.nextImage,
            storeContexts: ['viewports'],
            options: {},
        },
        previousImage: {
            commandFn: actions.previousImage,
            storeContexts: ['viewports'],
            options: {},
        },
        // TOOLS
        setToolActive: {
            commandFn: actions.setToolActive,
            storeContexts: ['viewports'],
            options: {},
        },
        setZoomTool: {
            commandFn: actions.setToolActive,
            storeContexts: [],
            options: { toolName: 'Zoom' },
        },
        setCornerstoneLayout: {
            commandFn: actions.setCornerstoneLayout,
            storeContexts: [],
            options: {},
            context: 'VIEWER',
        },
        setWindowLevel: {
            commandFn: actions.setWindowLevel,
            storeContexts: ['viewports'],
            options: {},
        },
        setWindowLevelAll: {
            commandFn: actions.setWindowLevelAll,
            storeContexts: ['viewports'],
            options: {},
        },
        enableCrosshairsTool: {
            commandFn: actions.enableCrosshairsTool,
            options: {},
        },
        enableLevelTool: {
            commandFn: actions.enableLevelTool,
            options: {},
        },
        screenShot:{
            commandFn: actions.screenShot,
            storeContexts: ['viewports'],
            options: {},
        },
        mpr2d: {
            commandFn: actions.mpr2d,
            storeContexts: ['viewports'],
            options: {},
            context: 'VIEWER',
        },
        displayCrosshairs:{
            commandFn: actions.displayCrosshairs,
            storeContexts: ['viewports'],
            options: {},
        },
        enableDistanceTool:{
            commandFn: actions.enableDistanceTool,
            storeContexts: ['viewports'],
            options: {},
        },
        show3d:{
            commandFn: actions.show3d,
            options: {},
        }
    };

    return {
        actions,
        definitions,
        defaultContext: 'ACTIVE_VIEWPORT::CORNERSTONE',
    };
}

export default commandsModule;
