import './ShareBox.styl';

import PropTypes from 'prop-types';
import React from 'react';
import ToolbarButton from '../ToolbarButton.js';
import Icon from '../components/Icon';
import getSharingCode from "../../core/http/getSharingCode";
import {message} from "antd";
import classnames from 'classnames';


class ShareBox extends React.Component {
  static propTypes = {
    /** Button label */
    label: PropTypes.string.isRequired,
    icon: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        name: PropTypes.string.isRequired,
      }),
    ]),
    activeCommand: PropTypes.string,
    kjStudyId: PropTypes.string,
    hospitalId: PropTypes.string,
    isMobile: PropTypes.bool,
    onBarClick: PropTypes.func,
  };

  static defaultProps = {
    buttons: [],
    icon: 'ellipse-circle',
    label: 'More',
    isMobile: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      isShow: false,
      isRes: false,
      expHour: 1,
      shareCode: null,
      urlCode: true
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.active !== prevProps.active) {
      if(!this.props.active){
        this.setState({
            isShow: false,
        });
      }
    }
  }

  activeIcon = () => {
    return this.props.icon;
  };

  onExpandableToolClick = () => {
    const _r = !this.state.isShow
    this.setState({
      isShow: _r,
    });
    if(!_r) {
      this.onClose();
    }
    if(this.props.onBarClick){
      this.props.onBarClick(this.props.id);
    }
  };

  onClose = () => {
    this.setState({
      isRes: false,
      expHour: 1
    });
  }
  copyText = () => {
     let text = '影像分享链接：';
     let url = this.shareUrl()
     text += url;
     if(!this.state.urlCode){
       text += '     分享验证码： '+ this.state.shareCode;
     }
     text += '      分享有效期：'+ this.state.expHour + '天';
     return text;
  }
  onCopyClick = (e) => {
     e.preventDefault()
     const text =  this.copyText();
     this.doCopy(text);
  };

  doCopy = (text) => {
        if(navigator.clipboard){
             navigator.clipboard.writeText(text).then(() => {
                message.success('已复制成功！')
            }).catch((e) => {
                this.doCopy2(text);
            });
        }else{
            this.doCopy2(text);
        }
    };

   doCopy2 = (text) => {
        if((navigator.userAgent.match(/(iPhone|iPod|iPad);?/i))){
            const range = document.createRange();
            range.selectNode(document.querySelector(".copyTxt"));
            const selection = window.getSelection();
            if(selection.rangeCount > 0) selection.removeAllRanges();
            selection.addRange(range);
            document.execCommand('Copy');
             message.success('已复制成功！')
            selection.removeAllRanges();
        }else {
            const input = document.createElement("INPUT");
            input.value = text;
            input.className = "";
            document.body.appendChild(input);
            input.select();
            if (document.execCommand('copy')) {
                document.execCommand('copy');
                message.success('已复制成功！')
            }
            document.body.removeChild(input);
        }
    }

  getRes = () => {
    if(!this.state.expHour || this.state.expHour<0.1){
        message.warn('请输入正确的有效期！')
        return;
    }
    let _params = {
      expirationHour: parseInt(this.state.expHour*24),
      hospitalId: this.props.hospitalId,
      kjStudyId: this.props.kjStudyId,
      sharerId: "",
      sharerIp: ""
    };
    var _this = this
     getSharingCode(_params).then(function (data) {
        if (data) {
            _this.setCode(data)
        }
        else{
           message.warn('获取分享码失败！')
        }
    });
  };

  setCode = (_code) => {
    this.setState({
      shareCode: _code,
      isRes: true,
    });
  }

  onOverlayHide = () => {
    this.setState({
      isShow: false,
    });
  };
  onChangeHandler = () => {
    this.setState({
      urlCode: !this.state.urlCode,
    });
  };

  shareUrl = () => {
    let url = 'https://image.comtcare.com/shareView?k='+this.props.kjStudyId+'&h='+this.props.hospitalId;
    if(this.state.urlCode){
      url += '&c='+this.state.shareCode;
    }
    return url;
  }

  onWlChange = (event)=>{
    let v=event.target.value;
    if(v>7) v = 7
    this.setState({
      expHour: v,
    });
  };

  render() {
    const {isShow, expHour, isRes, shareCode, urlCode}=this.state;
    const getToolBarButtonComponent = () => {
      return (
        <ToolbarButton
          key="menu-button"
          type="tool"
          label={this.props.label}
          icon={this.activeIcon()}
          className={'toolbar-button expandableToolMenu'}
          onClick={this.onExpandableToolClick}
        />
      );
    };

    const toolbarComponent = getToolBarButtonComponent();

    const getResulr = () => {
        if (isRes) {
          return (<div>
                <div>
                分享验证码：<span className="shareUrl">{shareCode}</span>
              </div>
              <div>
                <label>分享链接自动填充验证码：<input type="checkbox" checked={urlCode} onChange={this.onChangeHandler} /></label>
              </div>
              复制以下链接分享：
              <div className="shareUrl">
                {this.shareUrl()}
              </div>

              <div className="center">
                <button className="btn-primary" onClick={(e)=>{this.onCopyClick(e)}}>点击复制</button>
              </div>
              <div className="copyTxt" id="copyTxt">{this.copyText()}</div>
          </div>)
        } else {
          return (<div>
            <div className="center">
              <label style={{width: "3.5em"}}>分享有效期：</label>
                <span>
                <input
                    type="number"
                    value={expHour}
                    min="1"
                    max="7"
                    name="customizeWl"
                    onChange={(e)=>{this.onWlChange(e)}}
                    className="shareBox-inp"
                  /> 天</span>
               </div>
               <div className="center">
                <button className="btn-primary" onClick={this.getRes}>生成分享码</button>
              </div>
            </div>)
        }
    }
    const resComponent = getResulr();

    return (
      <div className={classnames("",{"shareMobile":this.props.isMobile})}>
        {toolbarComponent}
        {isShow && <div className={classnames("shareBox",{"boxTop":!this.props.isMobile},{"boxBottom":this.props.isMobile})}>
            <Icon
                    name="cancel"
                    className="cancel-icon"
                    onClick={this.onExpandableToolClick}
                  />
            {resComponent}

        </div>}
      </div>
    );
  }
}

export default ShareBox;
