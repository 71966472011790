
const CORNERSTONE_3D_TOOLS_SOURCE_NAME = 'Cornerstone3DTools';
const CORNERSTONE_3D_TOOLS_SOURCE_VERSION = '0.1';

const initMeasurementService=(MeasurementService)=>{

    const csTools3DVer1MeasurementSource = MeasurementService.createSource(
        CORNERSTONE_3D_TOOLS_SOURCE_NAME,
        CORNERSTONE_3D_TOOLS_SOURCE_VERSION
    );

    /* Mappings */
    // MeasurementService.addMapping(
    //     csTools3DVer1MeasurementSource,
    //     'Length',
    //     Length.matchingCriteria,
    //     Length.toAnnotation,
    //     Length.toMeasurement
    // );

    MeasurementService.addMapping(csTools3DVer1MeasurementSource,"POLYLINE",{},()=>{},()=>{})

}

export {initMeasurementService}
