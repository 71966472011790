import SCOORD_TYPES from "../constants/scoordTypes";
import getStudySeriesAiList from "../../core/http/getStudySeriesAiList";
import accessToken from "../../core/configs/accessToken";
import getFocusClassesData from "../../utils/helpers/getFocusClassesData";
import {message} from "antd";

const toMeasurementSchema=(data)=>{
    return {
        labels:data.labels,
        coords:[{GraphicType:data.type, GraphicData:data.data,ReferencedSOPSequence:[{ReferencedSOPInstanceUID:data.SOPInstanceUID,ReferencedFrameNumber:data.frameNumber}]}],
        TrackingIdentifier:":",
        TrackingUniqueIdentifier:data.TrackingUniqueIdentifier,
        locationDescription:data.locationDescription,
        displaySetInstanceUID:data.displaySetInstanceUID,
        imageId:data.imageId,
        frameNumber:data.frameNumber,
        act:data.act,
        classId:data.classId,
        volume:data.volume,
        toShow:data.toShow,
        tabIdx:data.tabIdx,
        markLocation:data.markLocation,
        ldiam:data.ldiam,
        sdiam:data.sdiam,
        pm:data.pm,
        total:data.total,
        code:data.code
    }
}

const toXY=(segments)=>{
    let XY=[];
    for (let i = 0; i < segments.length; i++){
        let seg=segments[i];
        XY.push(seg[1],seg[0])
    }
    XY.push(segments[0][1],segments[0][0])
    return XY;
}

const toXY2=(bboxes)=>{
    return [bboxes[1],bboxes[2],bboxes[3],bboxes[0]];
}

const sortMainPage = (a, b) => {
    if (a.mainPage === b.mainPage) {
        return 0;
    }
    else {
        return (a.mainPage < b.mainPage) ? -1 : 1;
    }
}

export default function addMeasurementToService(measurementService,displaySet){
    if(!displaySet.isAi){
        return;
    }
    let token=accessToken();
    getStudySeriesAiList({kjSeriesId:displaySet.displaySetInstanceUID,kjStudyId:displaySet.kjStudyId,aiProvider:'TSINGHUA',aiStatus:'ACTIVE'},token).then(data=>{
        if (data.error) {
            console.log("errorCode:",data.error);
        }
        else{
            measurementService.clearClasses();
            let source = measurementService.getSource('Cornerstone3DTools', '0.1');
            if(data.list&&data.list.length){
                let item=data.list[0];
                let aiResult=item.aiResult;
                let aiJSON=JSON.parse(aiResult.replace(/"/gi, '"'));
                //console.log(aiJSON)
                console.log('aiJSON.length:'+aiJSON.length)
                if(aiJSON && aiJSON.length && aiJSON[0].mark){
                    let classes = getFocusClassesData(aiJSON)
                    const markData = []
                    for(let i=0;i<aiJSON.length;i++){
                        let mark=aiJSON[i].mark;
                        aiJSON[i].mainPage = mark.instanceNumber[mark.main]
                        aiJSON[i].idx = i
                    }
                    aiJSON.sort(sortMainPage)
                    let classNum = {}
                    for(let i=0;i<aiJSON.length;i++){
                        const itemClass = classes[aiJSON[i].idx]

                        if (itemClass.tabId in classNum) {
                            classNum[itemClass.tabId]++;
                        } else {
                            classNum[itemClass.tabId] = 1;
                        }

                        measurementService.addClasses(itemClass.class0,itemClass.class1)
                        let trackingUniqueIdentifier='kj'+aiJSON[i].id;
                        let mark=aiJSON[i].mark;
                        let segmentations=mark.segmentations;
                        let inLen = mark.instanceNumber.length
                        const mainPage = mark.instanceNumber[mark.main]
                        mark.instanceNumber.sort();
                        let pm = [mark.instanceNumber[0]]
                        if (inLen>1) {
                          pm.push(mark.instanceNumber[inLen-1])
                        }
                        for(let j=0;j<inLen;j++){
                            let instanceNumber=mark.instanceNumber[j]-1;
                            let SOPInstanceUID=displaySet.images[instanceNumber].SOPInstanceUID;
                            let url=displaySet.images[instanceNumber].url;
                            let dataXY
                            let _type = SCOORD_TYPES.POLYLINE
                            let _label = ''

                            if(itemClass.drawType) {
                              dataXY = toXY2(mark.bboxes[j])
                              _type = SCOORD_TYPES.RECTANGLE
                            } else {
                              dataXY = toXY(segmentations[j])
                            }
                            if(itemClass.tip) {
                              _label = ', '+itemClass.tip
                            }

                            let _name = itemClass.name
                            let _tabIdx = classNum[itemClass.tabId]
                            let data1={
                                toShow: (instanceNumber+1) === mainPage,
                                type: _type,
                                data:dataXY,
                                labels:[{label:_tabIdx,value:_label}], //[{label:j,value:itemClass.code}]
                                act:mark.act,
                                SOPInstanceUID:SOPInstanceUID,
                                frameNumber:instanceNumber+1,
                                TrackingUniqueIdentifier:trackingUniqueIdentifier+'-'+instanceNumber,
                                locationDescription: _name,
                                code:itemClass.code,
                                displaySetInstanceUID:displaySet.displaySetInstanceUID,
                                classId: itemClass.tabId,
                                volume:mark.volume,
                                imageId:url,
                                total:aiJSON.length,
                                markLocation:mark.location === '其他 其他'?'肺外':mark.location,
                                pm:pm,
                                ldiam: mark.ldiam,
                                sdiam:mark.sdiam,
                                tabIdx:_tabIdx
                            }
                            measurementService.addRawMeasurement(source,"POLYLINE",data1,toMeasurementSchema);
                        }
                    }
                }
            }
        }
    })

}

export function jump3DUrl(kjStudyId){
    let token=accessToken();
    getStudySeriesAiList({kjStudyId:kjStudyId,aiProvider:'TSINGHUA_3D',aiStatus:'ACTIVE'},token).then(data=>{
        if (data.error) {
            console.log("errorCode:",data.error);
            message.warn(data.error)
        }
        else{
            if(data.list&&data.list.length){
                let item=data.list[0];
                let aiResult=item.aiResult;
                let aiJSON=JSON.parse(aiResult.replace(/"/gi, '"'));
                window.open(aiJSON.url)
            } else {
              message.warn('无对应3d模型！')
            }
        }
    })

}
