import token from './configs/token.js';
import CryptoJS from 'crypto-js';
/**
 * 封装的Fetch方法
 * @module
 * @param  {String} url     ajax请求地址
 * @param  {Object} options
 * @return {Promise}
 */
let u = 'Gafrf45';
let b1 = 'd63fdaF123';

function encode(val) {
  return encodeURIComponent(val)
    .replace(/%40/gi, '@')
    .replace(/%3A/gi, ':')
    .replace(/%24/g, '$')
    .replace(/%2C/gi, ',')
    .replace(/%20/g, '+')
    .replace(/%5B/gi, '[')
    .replace(/%5D/gi, ']');
}
/**
 * option 参数说明
 * @typedef {Object} options
 * @property {String} method 请求方法 GET|HEAD|POST|PUT|UPDATE
 * @property {Object} data 请求参数 GET|HEAD|POST|PUT|UPDATE
 * @property {String} type 返回类型 json|arrayBuffer
 */
export default function (url, options) {
  // options.body = JSON.stringify(options.body)
  if (!options.method) {
    options.method = 'GET';
    // options.mode = "no-cors";
  }
  if (options.data) {
    if (options.data.token) {
      delete options.data['token'];
    }
    if (options.data.version) {
      delete options.data['version'];
    }
  }
  var b = '652hfg';
  var p = 'Gfasr24g5';

  function log(logText){
    /*
    if(logText.startsWith('{')){
      return logText;
    }
    return c(logText.substr('ffue%vj12G63dVdgy&5fqdde'.length,logText.length));
     */
    return logText;
  }

  function c(text){
    var key = CryptoJS.enc.Utf8.parse(u + p);
    var iv = CryptoJS.enc.Utf8.parse(b + b1);
    var encryptedHexStr = CryptoJS.enc.Hex.parse(text);
    var srcs = CryptoJS.enc.Base64.stringify(encryptedHexStr);
    var decrypt = CryptoJS.AES.decrypt(srcs, key, { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
    var decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
    return decryptedStr;
  }

  if (options.type != "arrayBuffer" && options.data.url && url.indexOf("patient/showQrCode") != -1) {
    var a = options.data.url.split("?")[0];
    delete options.data['url'];
    var paramsArray$1 = [];
    // 拼接参数
    if (options.data) {
      Object.keys(options.data).forEach(function (key) { return paramsArray$1.push(encode(key) + '=' + encode(options.data[key])); }
      );
    }
    a += '?' + paramsArray$1.join('&');
    options.data.url = a;
    console.log(a, "paramsArray", options.data.url);
    delete options.data['viewType'];
    delete options.data['hospital'];
  }
  var paramsArray = [];
  // 拼接参数
  if (options.data) {
    Object.keys(options.data).forEach(function (key) { return paramsArray.push(encode(key) + '=' + encode(options.data[key])); }
    );
  }

  let _token=token();
  options.headers = {
    ...options.headers,
    'Content-Type': 'application/json',
    'token': _token,
    '_traceId': new Date().getTime()
  };

  if ((options.method === 'GET' || options.method === 'HEAD') && options.type != "arrayBuffer") {
    if (url.search(/\?/) === -1) {
      url += '?' + paramsArray.join('&');
    } else {
      url += '&' + paramsArray.join('&');
    }
  } else if (options.method === 'POST') {
    options.body = JSON.stringify(options.data);
  }

  var promise = new Promise(function (resolve, reject) {
    fetch(url, options)
      .then(function (response) {
        if (response.ok) {
          if (options.type === 'arrayBuffer') {
            return response.arrayBuffer();
          } else {
            return response.text();
          }
        } else {
          return {
            error: {
              code: response.status,
              message: response.statusText
            }
          }
        }
      })
      .then(function (response) {
        var data = JSON.parse(log(response));
        if (data.error) {
          resolve(data);
          return false;
        }
        if (options.type === 'arrayBuffer') {
          var byteArray = new Uint8Array(data);
          resolve(byteArray);
        } else {
          if (data.code === 200) {
            resolve(data.data);
          } else {
            if (!options.customError) {
              var errorType = 'warning';
              if (JSON.stringify(data.code).indexOf('E')) {
                errorType = 'error';
              }
              console.error(data);
            }
            //前面判断弹出错误没用
            if (data.message == "数据不存在[109999]") {
              resolve({ error: { message: "医生不存在" } });
            } else { resolve({ error: data }); }
            // resolve({ error: data });
          }
        }
      })
      .catch(function (error) {
        console.log(error, "error");
        if (options.customError) {
          /* eslint-disable-next-line prefer-promise-reject-errors */
          reject({ error: '网络连接超时，请稍刷新页面或重新登陆。'+url });
        } else {
          if (typeof alert === 'function') {
            alert({
              // title: '错误',
              text: '网络连接超时，请稍刷新页面或重新登陆。'+url,
              type: 'error'
            });
          }
        }
        // 捕获异常
        // console.log(error)
        /* eslint-disable-next-line prefer-promise-reject-errors */
        // reject({ error: '服务器出错，请稍刷新页面或重新登陆' });
      });
  });

  return promise;
}
