import React from 'react';
import { isMobileView } from "../../../utils/tool";

function StudyInfo(props) {
  const{
    studyMetadata,
    activeViewportIndex,
  }=props;

  if(!studyMetadata||!studyMetadata.length)
    return null;

  const { patName,
    patNo,
    patGender,
    patAge,
    studyNo,studyTime,modality}=studyMetadata[activeViewportIndex].patientInfo;

  const isMobile = isMobileView()

  function genderText(){
    if(patGender==='F'||patGender==='女') return '女'
    if(patGender==='M'||patGender==='男') return '男'
    return '未知';
  }

  return (
    <div className="patientInfo" id="patientInfo">
      <div className="info">
        <span>{patName}</span>
        <span></span>
      </div>
      <div className="info">
            <span>{modality}</span>
            <span className="split">/</span>
            <span>{genderText()}</span>
            <span className="split">/</span>
            <span>{patAge}</span>
      </div>

      {!isMobile && <div className="item">
          <span className="label0">病人编号:</span>
          <span className="value">{patNo}</span>
      </div>}
      <div className="item">
        <span className="label0">检查编号:</span>
        <span className="value">{studyNo}</span>
      </div>
      <div className="item">
        <span className="label0">检查时间:</span>
        <span className="value">{studyTime}</span>
      </div>
    </div>
  );
}

export { StudyInfo };
