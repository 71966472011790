import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import "./MeasurementTracking.styl";

const MeasurementItem = ({
                             uid,
                             label,
                             markLocation,
                             pm,
                             msize,
                             danger,
                             volume,
                             idx,
                             tabIdx,
                             isActive,
                             act,
                             isChecked,
                             onClick,
                             onChange,
                         }) => {
    const [isHovering, setIsHovering] = useState(false);


    const onMouseEnter = () => setIsHovering(true);
    const onMouseLeave = () => setIsHovering(false);

    const localText = markLocation.split(' ');
    const markLocationText = localText[0]
    const showLevel = danger.level > 0

    const onClickHandler = event =>{
        if(onClick){
            onClick({uid, idx, event});
        }
    }

    const onChangeHandler = event =>{
        const _isChecked=!isChecked
        if(onChange){
            onChange({ idx, _isChecked, event});
        }
    }

    const volumeText = () =>{
        if(volume > 1000) {
          return parseFloat((volume/1000).toFixed(2)) +"cm";
        } else {
          return parseFloat(volume.toFixed(1)) +"mm";
        }
    }

    const pmText = () =>{
        let s = 'IM' + pm[0]
        if (pm.length>1) {
           s = s + '-IM' + pm[1]
        }
        return s
    }

    const levelClass = () =>{
        return 'danger level-' + danger.level
    }

    return (
        <div className={classnames("list-item",{"hover":isHovering},{"active":isActive})}
             onMouseEnter={onMouseEnter}
             onMouseLeave={onMouseLeave}
             onClick={onClickHandler}
        >
            <div className={classnames("chkbox-item",{"hover":isHovering})} onClick={onChangeHandler}>
                <input type="checkbox" checked={isChecked} onChange={onChangeHandler} />
                <span className="indexNum">{tabIdx}</span>
            </div>
            <div className="flex-2 mt2">
               {pmText()}<br/>{msize}
            </div>
            <div className="flex-2 mt2">{label}<br/>{markLocationText}</div>
            <div className="flex-1 mt2">
                <span>{volumeText()}</span><span className="toplabel">3</span>
                <br/>{act}Hu
            </div>
            {danger.level && <div className="flex-1">
                <div className={levelClass()}>{danger.title}</div>
            </div>}
        </div>
    )
}

MeasurementItem.propTypes = {
    uid: PropTypes.oneOfType([
        PropTypes.number.isRequired,
        PropTypes.string.isRequired,
    ]),
    tabIdx: PropTypes.number.isRequired,
    label: PropTypes.string,
    markLocation: PropTypes.string,
    pm: PropTypes.array,
    act: PropTypes.number,
    msize: PropTypes.string,
    danger: PropTypes.object,
    isActive: PropTypes.bool,
    isChecked: PropTypes.bool,
    onClick: PropTypes.func,
    onChange: PropTypes.func,
};

MeasurementItem.defaultProps = {
    isActive: false,
    isChecked: false,
};

export default MeasurementItem;
