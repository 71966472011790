import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Switch,useLocation } from 'react-router-dom';
import ViewerRetrieveStudy from './ViewerRetrieveStudy';
import { Provider } from 'react-redux';
import store from './store';
import CommandsManager from './core/classes/CommandsManager';
import commandsModule from './commandsModule';
import Login from './login/Login';
import ShareCode from './login/ShareCode';
import StudyList from './login/StudyList';
import qs from 'querystring';
import { decryptParams,Encrypt2} from "./utils/urlParam";
import CustomError from "./CustomError";
import "./utils/dateFormat";

import ServicesManager from "./core/services/ServicesManager";
import CornerstoneViewportService from "./core/services/ViewportService/CornerstoneViewportService";
import ToolGroupService from "./core/services/ToolGroupService";
import ToolBarService from "./core/services/ToolBarService";
import SyncGroupService from "./core/services/SyncGroupService";
import DisplaySetService from "./core/services/DisplaySetService";
import MeasurementService from "./core/services/MeasurementService";

const commandsManagerConfig = {
    getAppState: () => store.getState(),
   // getActiveContexts: () => getActiveContexts(store.getState()),
};
const {definitions}=commandsModule()
const commandsManager = new CommandsManager(commandsManagerConfig);
commandsManager.initCommandsModule(definitions);

const servicesManager = new ServicesManager(commandsManager);
servicesManager.registerServices([ToolBarService,DisplaySetService,MeasurementService]);
servicesManager.registerService(CornerstoneViewportService(servicesManager));
servicesManager.registerService(ToolGroupService(servicesManager));
servicesManager.registerService(SyncGroupService(servicesManager));
window.store = store;

function Index() {
  const { search,state } = useLocation();
  if(search){
      return AuthLogin();
  }
  else if(state){
      return (
          <Provider store={store}>
              <ViewerRetrieveStudy params={{...state,ofv:false}} />
          </Provider>
      );
  }
  else{
      return (
          <CustomError label="参数错误"/>
       );
  }
}

function AuthLogin() {
  const { search } = useLocation();
  const {hospital,authMode}=qs.parse(search.slice(1));
  if(authMode&&hospital){
      let options={key:"1234567812345678",iv:"1234567812345678"};
      //let ho=Encrypt2("kj-test8888",options)
      //console.log(ho)
      let params=decryptParams({HID:hospital},options);
      return (<Login hospitalId={params.hospitalCode} authMode={authMode} />);
  }
  else{
      return (
          <CustomError label="参数错误"/>
      );
  }
}

function Film(){
    const { search } = useLocation();
    if(search){
        const {Node,PID,AccessNo,HID,ExamineDate,RPTID,Sign}=qs.parse(search.slice(1));
        let params=decryptParams({PID:PID,AccessNo:AccessNo,HID:HID,SIGN:Sign});
        if(params.AccessNo&&params.HID&&ExamineDate){
            let dt=ExamineDate.substring(0,4)+"/"+ExamineDate.substring(4,6)+"/"+ExamineDate.substring(6,8);
            params.ExamineDate=new Date(dt).format("yyyy-MM-dd hh:mm:ss");
            return (<Login hospitalId={params.hospitalCode} authMode="NNM" params={params} />);
        }
        else
            return (<CustomError label="参数错误"/>);
    }
    else{
        return (
            <CustomError label="参数错误"/>
        );
    }
}

function OpenFilmView(){
    const { search } = useLocation();
    if(search){
        const {accessToken,kjStudyId,consultationOrderId,patientName}=qs.parse(search.slice(1));
        if(accessToken&&kjStudyId){
            localStorage.setItem('accessToken', accessToken);
            let state={ofv:true,kjStudyId:kjStudyId,accessToken:accessToken,consultationOrderId:consultationOrderId,patientName:patientName}
            return (
                <Provider store={store}>
                    <ViewerRetrieveStudy params={state} />
                </Provider>
            );
        }
        else{
            return (
                <CustomError label="参数错误"/>
            );
        }
    }
    else{
        return (
            <CustomError label="参数错误"/>
        );
    }
}

function Test() {
    return (
        <></>
    );
}

function shareView() {
  const ul = useLocation();
  const { search,state } = useLocation();
  if(search){
      const {kjStudyId,hospitalId,k,h,c}=qs.parse(search.slice(1));
      if(k&&h){
            return (<ShareCode hospitalId={h} kjStudyId={k} authCode={c} />);
      }
      else if(kjStudyId&&hospitalId){
            return (<ShareCode hospitalId={hospitalId} kjStudyId={kjStudyId} />);
      }
      else
         return (<CustomError label="参数错误"/>);
  }
  else if(state){
      return (
          <Provider store={store}>
              <ViewerRetrieveStudy params={{...state,ofv:false}} />
          </Provider>
      );
  }
  else{
      return (
          <CustomError label="参数错误"/>
       );
  }
}

function AppRouter() {
  return (
    <Router>
      <Switch>
        <Route exact path="/login" component={AuthLogin} />
        <Route exact path="/film" component={Film} />
        <Route exact path="/list" component={StudyList} />
        <Route exact path="/openFilmView" component={OpenFilmView} />
        <Route exact path="/test" component={Test} />
        <Route exact path="/shareView" component={shareView} />
        <Route exact path="/" component={Index} />
      </Switch>
    </Router>
  );
}

export default class App extends Component {

    render() {
        return <AppRouter />;
    }
}

export {commandsManager,servicesManager}
