import "./studylist.css";
import React, {Component} from "react";
import {withRouter } from "react-router";
import {Link} from "react-router-dom";

import getStudyList from "../core/http/getStudyList";
import getHospital from "../core/http/getHospital";

class StudyList extends Component {

    constructor (props) {
        super(props);
        this.state={
           patient:{Name:"",Sex:"",Age:""},
           hospitalNm:"影易影像中心",
           list:[]
        };
        if(props.location.state){
            this.getHospital(props.location.state.hospitalId);
            this.getlist(props.location.state.hospitalId);
        }
    }

    getHospital=(hId)=>{
        var _this=this;
        getHospital({hospitalId:hId}).then(data=>{
            if (!!data && !data.error) {
                _this.setState({hospitalNm:data.hospitalName})
            }
        });
    }

    getlist=(hId)=>{
        var _this=this;
        getStudyList({hospitalId:hId}).then(data=>{
            //console.log(data);
            if (!!data && !data.error) {
                if(data.list&&data.list.length>0){
                    let pat=data.list[0];
                    let gender=(pat.patientSex00100040 && pat.patientSex00100040=="F")?"女":"男";
                    _this.setState({patient:{Name:pat.patientName00100010,Sex:gender,Age:pat.patientAge00101010}})
                }
                _this.setState({list:data.list});
            }
        });
    }

    toIndex=(item)=>{
        let history=this.props.history;
        history.push({pathname:'/',state:item});
    }

    render() {
        const {patient,hospitalNm}=this.state;
        return (
            <div className="content">
                <div className="patient">
                    <div style={{ marginBottom:"10px"}}>{patient.Name}</div>
                    <div><span>性别：</span><span>{patient.Sex}</span><span style={{ marginLeft:"30px"}}>年龄：</span><span>{patient.Age}</span></div>
                </div>
                <div className="description">请选择要查看的检查:</div>
                <div className="list">
                    {
                        this.state.list.map(item=>{
                            return (
                                <a className="item" key={item.studyInstanceUid0020000d} onClick={(e)=>{this.toIndex(item)}}>
                                    <div className="card">
                                        <div className="card_l"></div>
                                        <div className="card_c">
                                            <div className="card_c_cap"><span className="a">{item.modality00080060+"检查"}</span><span className="b">{hospitalNm}</span></div>
                                            <div className="card_c_txt"><span className="a">检查编号:</span><span className="b">{item.studyId00200010}</span></div>
                                            <div className="card_c_txt"><span className="a">检查时间:</span><span className="b">{item.studyDate00080020}</span></div>
                                            <div className="card_c_txt"><span className="a">检查描述:</span><span className="b">{item.studyDescription00081030}</span></div>
                                        </div>
                                        <div className="card_r">
                                            <i className="iconfont icon-arrow-right" />
                                        </div>
                                    </div>
                                </a>
                            );
                        })
                    }
                </div>
            </div>
        );
    }
}

export default withRouter(StudyList);
