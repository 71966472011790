
export default function (param) {

    let {consultationOrderId,fileBase64}=param;
    var uploadUrl = 'https://www.xingyunjk.com/health/callback/uploadv2';

    //let paramData=new FormData();
    //paramData.append("fileBase64",fileBase64);
    //paramData.append("orderId",consultationOrderId);
    let paramData={"fileBase64":fileBase64,"orderId":consultationOrderId}

    const promise = new Promise(function (resolve, reject) {

        fetch(uploadUrl, {
            headers:{'Content-Type': 'application/json'},
            method: 'POST',
            body:JSON.stringify(paramData),
            mode:'cors'
        }).then(function (response) {
            if (response.ok) {
               // if (options.type === 'arrayBuffer') {
               //     return response.arrayBuffer();
               // } else {
                    return response.text();
              //  }
            } else {
                return {
                    error: {
                        code: response.status,
                        message: response.statusText
                    }
                }
            }
        }).then((response) =>{
            var data = JSON.parse(response);
            resolve(data);
       }).catch((error)=>{
           console.error(error)
           reject(error);
        });

    });

    return promise;
}
