import CryptoJS from 'crypto-js';


const getAllParameter= (url)=> {
  if (!url) { url = window.location.search; }
  var result = {};
  var reg = new RegExp('([?|&])(.+?)=([^&?]*)', 'ig');
  var arr = reg.exec(url);
  while (arr) {
    result[arr[2]] = arr[3];
    arr = reg.exec(url);
  }
  return result;
};

const getParam= (viewType, urlParameters) =>{
  var param = {};
  var hospitalvalue;
  if (urlParameters['hospital']) {
    hospitalvalue = urlParameters['hospital'];
  } else if (urlParameters['hospitalCode']) {
    hospitalvalue = urlParameters['hospitalCode'];
  } else if (urlParameters['HID']) {
    hospitalvalue = urlParameters['HID'];
  } else if (urlParameters['hsCode']) {
    hospitalvalue = urlParameters['hsCode'];
  }
  param.hospitalCode = hospitalvalue;
  var datelvalue;
  if (urlParameters['studyDate']) {
    datelvalue = urlParameters['studyDate'];
  } else if (urlParameters['ExamineDate']) {
    datelvalue = urlParameters['ExamineDate'];
  } else if (urlParameters['date']) {
    datelvalue = urlParameters['date'];
  }
  param.studyDate = datelvalue;
  //param.reportSource = urlParameters['reportSource'];
  param.reportSource = '4';

  if (urlParameters['Node']) {
    param.nodeCode = urlParameters['Node'];
  }
  if (urlParameters['sign']) {
    param.sign = urlParameters['sign'];
  }else if (urlParameters['Sign']) {
    param.sign = urlParameters['Sign'];
  }

  if ((urlParameters['accessionNumber'] || urlParameters['AccessNo']) && urlParameters['PatientId']) {
    param.queryKey = 'accessionNumber,patientId';
    param.queryValue = (urlParameters['accessionNumber'] ? urlParameters['accessionNumber'] : urlParameters['AccessNo']) + ',' + urlParameters['PatientId'];
  } else if (urlParameters['accessionNumber'] || urlParameters['AccessNo']) {
    param.queryKey = 'accessionNumber';
    param.queryValue = (urlParameters['accessionNumber'] ? urlParameters['accessionNumber'] : urlParameters['AccessNo']);
  } else if (urlParameters['PatientId'] || urlParameters['PID']) {
    param.queryKey = 'PatientId';
    param.queryValue = urlParameters['PatientId'] ? urlParameters['PatientId'] : urlParameters['PID'];
  } else if (urlParameters['studyUid']) {
    param.queryKey = 'studyUid';
    param.queryValue = urlParameters['studyUid'];
  } else if (urlParameters['applyId']) {
    param.queryKey = 'applyId';
    param.queryValue = urlParameters['applyId'];
  }
  param.viewType = viewType;
  param.defaultShow = 'index';
  param.apiVersion = 'V3.0';
  if (urlParameters['channel']) {
    param.channel = urlParameters['channel'];
  }
  return param;
};


const _key = CryptoJS.enc.Utf8.parse("21oiu90ww0al29ck");
const _iv = CryptoJS.enc.Utf8.parse('alsk1234uxop10sl');

const decryptParams = (param,options) => {
  let _options={key:_key,iv:_iv};
  if(options){
    _options={
      key:CryptoJS.enc.Utf8.parse(options.key),
      iv:CryptoJS.enc.Utf8.parse(options.iv)};
  }
  if (param.hospital) {
    param.hospitalCode = Decrypt(param.hospital,_options);
  } else if (param.HID) {
    param.hospitalCode = Decrypt(param.HID,_options);
  } else if (param.hospitalCode) {
    param.hospitalCode = Decrypt(param.hospitalCode,_options);
  }

  if (param.PID) {
    param.PID = Decrypt(param.PID,_options);
  }
  if (param.AccessNo) {
    param.AccessNo = Decrypt(param.AccessNo,_options);
  }
  if(param.SIGN){
    param.SIGN=Decrypt(param.SIGN,_options)
  }

  return param;
}

const Decrypt=(word,options) =>{
  const {key,iv}=options;
  var encryptedHexStr = CryptoJS.enc.Hex.parse(word);
  var srcs = CryptoJS.enc.Base64.stringify(encryptedHexStr);
  var decrypt = CryptoJS.AES.decrypt(srcs, key, { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
  var decryptedStr = null;
  try {
    decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
  }catch (e) {
    throw new Error(e);
  }
  return decryptedStr.toString();
}

const Encrypt=(word)=>{
  var cryptStr = CryptoJS.AES.encrypt(word, _key, { iv: _iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
  return cryptStr.ciphertext.toString();
}

const Encrypt2=(word,options)=>{
  let k=CryptoJS.enc.Utf8.parse(options.key);
  let v=CryptoJS.enc.Utf8.parse(options.iv);
  var cryptStr = CryptoJS.AES.encrypt(word, k, { iv: v, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
  return cryptStr.ciphertext.toString();
}

export { getAllParameter, getParam, decryptParams,Encrypt,Decrypt,Encrypt2};
