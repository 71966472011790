import log from "../log";

export class CommandsManager {
    constructor({ getAppState, getActiveContexts } = {}) {
        this.contexts = {};

        this._getAppState = getAppState;
    }

    createContext(contextName) {
        if (!contextName) {
            return;
        }

        if (this.contexts[contextName]) {
            return this.clearContext(contextName);
        }

        this.contexts[contextName] = {};
    }

    getContext(contextName) {
        const context = this.contexts[contextName];

        if (!context) {
            return;
        }

        return context;
    }

    clearContext(contextName) {
        if (!contextName) {
            return;
        }

        this.contexts[contextName] = {};
    }

    registerCommand(contextName, commandName, definition) {
        if (typeof definition !== 'object') {
            return;
        }

        const context = this.getContext(contextName);
        if (!context) {
            return;
        }

        context[commandName] = definition;
    }

    getCommand(commandName, contextName= 'VIEWER') {
        let contexts = [];

        if (contextName) {
            const context = this.getContext(contextName);
            if (context) {
                contexts.push(context);
            }
        } else {
            // const activeContexts = this._getActiveContexts();
            // activeContexts.forEach(activeContext => {
            //     const context = this.getContext(activeContext);
            //     if (context) {
            //         contexts.push(context);
            //     }
            // });
        }

        if (contexts.length === 0) {
            return;
        }

        let foundCommand;
        contexts.forEach(context => {
            if (context[commandName]) {
                foundCommand = context[commandName];
            }
        });

        return foundCommand;
    }

    /**
     *
     * @method
     * @param {String} commandName
     * @param {Object} [options={}] - Extra options to pass the command. Like a mousedown event
     * @param {String} [contextName]
     */
    runCommand(commandName, options = {}, contextName) {
        const definition = this.getCommand(commandName, contextName);
        if (!definition) {
            log.warn(`Command "${commandName}" not found in current context`);
            return;
        }

        const { commandFn, storeContexts = [] } = definition;
        const definitionOptions = definition.options;

        let commandParams = {};
        const appState = this._getAppState();
        storeContexts.forEach(context => {
            commandParams[context] = appState[context];
        });

        commandParams = Object.assign(
            {},
            commandParams, // Required store contexts
            definitionOptions, // "Command configuration"
            options // "Time of call" info
        );

        if (typeof commandFn !== 'function') {
            log.warn(`No commandFn was defined for command "${commandName}"`);
            return;
        } else {
            return commandFn(commandParams);
        }
    }


    initCommandsModule(commandDefinitions, defaultContext = 'VIEWER') {
        if (!this.getContext(defaultContext)) {
            this.createContext(defaultContext);
        }

        Object.keys(commandDefinitions).forEach(commandName => {
            const commandDefinition = commandDefinitions[commandName];
            const commandHasContextThatDoesNotExist =
                commandDefinition.context &&
                !this.getContext(commandDefinition.context);

            if (commandHasContextThatDoesNotExist) {
                this.createContext(commandDefinition.context);
            }

            this.registerCommand(
                commandDefinition.context || defaultContext,
                commandName,
                commandDefinition
            );
        });
    }
}

export default CommandsManager;
