import * as cornerstone from '@cornerstonejs/core';
import WADORSHeaderProvider from './WADORSHeaderProvider';
import ptScalingMetaDataProvider from './ptScalingMetaDataProvider';
import classes from "../../core/classes";

const { calibratedPixelSpacingMetadataProvider } = cornerstone.utilities;

export default function initProviders() {
  cornerstone.metaData.addProvider(
    WADORSHeaderProvider.get.bind(WADORSHeaderProvider),
    9999
  );
  cornerstone.metaData.addProvider(
    ptScalingMetaDataProvider.get.bind(ptScalingMetaDataProvider),
    10000
  );
  cornerstone.metaData.addProvider(
    calibratedPixelSpacingMetadataProvider.get.bind(
      calibratedPixelSpacingMetadataProvider
    ),
    11000
  );

  const metadataProvider = classes.MetadataProvider;
  cornerstone.metaData.addProvider(
      metadataProvider.get.bind(metadataProvider),
      9999
  );
}
