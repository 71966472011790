
const touchState={
    activeTool:{},
    screen:{width:0,height:0},
    startPoint:{x:0,y:0},
    moveEndPoint:{x:0,y:0},
    viewport:{},
    imageSliceData:{imageIndex:0,numberOfSlices:0}
}

export default touchState;
