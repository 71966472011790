import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ToolbarButton from './ToolbarButton';
import {toolPrev,toolNext,toolShare} from './ToolbarModule';
import {commandsManager} from "../App";
import ShareBox from './toolBarBox/ShareBox';
import HelpBox from './toolBarBox/HelpBox';

export default class BottomToolbar extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {kjStudyId, hospitalId, canShare}=this.props;
        return (
            <div className="bottom-tools">
               {<HelpBox></HelpBox>}
               {canShare && <ShareBox {...toolShare} kjStudyId={kjStudyId} hospitalId={hospitalId} isMobile={true} ></ShareBox>}
                <ToolbarButton {...toolPrev} style={{width: "80px",position: "absolute",right: "95px"}} onClick={_handleToolbarButtonClick.bind(this,toolPrev)}></ToolbarButton>
                <ToolbarButton {...toolNext} style={{width: "80px",position: "absolute",right: "5px"}} onClick={_handleToolbarButtonClick.bind(this,toolNext)}></ToolbarButton>
            </div>
        )
    }
}


function _handleToolbarButtonClick(button, evt, props) {
    if (button.commandName) {
        const options = Object.assign({ evt }, button.commandOptions);
        commandsManager.runCommand(button.commandName, options);
    }

}
