import React, { useState,useEffect,useRef,useCallback } from 'react';
import ReactResizeDetector from 'react-resize-detector';
import {
    Types, Enums,
    eventTarget, VolumeViewport
} from '@cornerstonejs/core';
import './CornerstoneViewport.css';
import {setEnabledElement} from "./state";
import {servicesManager} from "../App";
import CornerstoneCacheService from "../core/services/ViewportService/CornerstoneCacheService";
import getDataSourcesModule from "../core/utils/getDataSourcesModule";

import CornerstoneOverlays from "./Overlays/CornerstoneOverlays";
import touchEventDispatcher from "./touchEventHandlers/touchEventDispatcher";

function areEqual(prevProps, nextProps) {
    const prevDisplaySets = prevProps.displaySet;
    const nextDisplaySets = nextProps.displaySet;
    if (prevDisplaySets && nextDisplaySets) {
        const areSameDisplaySetInstanceUIDs =
            prevDisplaySets.displaySetInstanceUID ===
            nextDisplaySets.displaySetInstanceUID;
        const areSameImageLength =
            prevDisplaySets.images.length === nextDisplaySets.images.length;
        const areSameImageIds = prevDisplaySets.images.every(
            (prevImage, index) =>
                prevImage.url === nextDisplaySets.images[index].url
        );
        return (
            areSameDisplaySetInstanceUIDs && areSameImageLength && areSameImageIds
        );
    }
    return false;
}

const OHIFCornerstoneViewport = React.memo( props =>{
    const {viewportIndex,displaySet,viewportOptions,onElementEnabled}=props

    const [scrollbarHeight, setScrollbarHeight] = useState('100px');
    const [viewportData, setViewportData] = useState(null);
    const elementRef = useRef();

    const {CornerstoneViewportService,ToolGroupService,SyncGroupService}=servicesManager.services;

    const dataSource=getDataSourcesModule;
    //const displaySetOptions=[{voi:{windowWidth:1500,windowCenter:-400}}]; //默认肺窗
    const displaySetOptions=[{}];
    const displaySets=[displaySet];

    const initialImageIndex=0

    // useCallback for scroll bar height calculation
    const setImageScrollBarHeight = useCallback(() => {
        const scrollbarHeight = `${elementRef.current.clientHeight - 20}px`;
        setScrollbarHeight(scrollbarHeight);
    }, [elementRef]);

    // useCallback for onResize
    const onResize = useCallback(() => {
        if (elementRef.current) {
            CornerstoneViewportService.resize();
            setImageScrollBarHeight();
        }
    }, [elementRef]);

    const elementEnabledHandler = useCallback(
        evt=>{
            if (evt.detail.element !== elementRef.current) {
                return;
            }

            const { viewportId, element } = evt.detail;
            const viewportInfo = CornerstoneViewportService.getViewportInfo(
                viewportId
            );

            const renderingEngineId = viewportInfo.getRenderingEngineId();
            const toolGroupId = viewportInfo.getToolGroupId();
            const syncGroups = viewportInfo.getSyncGroups();
            setEnabledElement(viewportIndex, element);
            ToolGroupService.addViewportToToolGroup(
                viewportId,
                renderingEngineId,
                toolGroupId
            );

            SyncGroupService.addViewportToSyncGroup(
                viewportId,
                renderingEngineId,
                syncGroups
            );

            if (onElementEnabled) {
                onElementEnabled(evt);
            }
        },
        [viewportIndex, onElementEnabled, ToolGroupService]
    );

    /*
    const contextLostHandler = ev=>{
        console.log("contextLostHandler:",ev)
        ev.preventDefault()
    };
    const renderingEngine = CornerstoneViewportService.getRenderingEngine();
    const {offScreenCanvasContainer}=renderingEngine;
    const canvas=offScreenCanvasContainer.getElementsByTagName('canvas')[0];

    useEffect(()=>{
        if (canvas) {
            canvas.addEventListener('webglcontextlost', contextLostHandler, false)
            return ()=>{
                canvas.removeEventListener('webglcontextlost', contextLostHandler)
            }
        }
    },[canvas])
    **/


    useEffect(()=>{
        if (elementRef.current&&viewportData) {
             setTimeout(()=>{
                onResize();
            },100);

            if(viewportData.viewportType=="stack"){
                touchEventDispatcher.enable(elementRef.current,viewportData)
                return ()=>{
                    touchEventDispatcher.disable(elementRef.current)
                }
            }
        }
    },[elementRef,viewportData])


    useEffect( () =>  {
        CornerstoneViewportService.enableElement(
            viewportIndex,
            viewportOptions,
            elementRef.current
        );

        eventTarget.addEventListener(
            Enums.Events.ELEMENT_ENABLED,
            elementEnabledHandler
        );

        setImageScrollBarHeight();

        return () => {
            CornerstoneViewportService.disableElement(viewportIndex);
            eventTarget.removeEventListener(
                Enums.Events.ELEMENT_ENABLED,
                elementEnabledHandler
            );
        }
    },[])

    useEffect(() => {
        const loadViewportData =async()=>{
            await CornerstoneCacheService.getViewportData(
                viewportIndex,
                displaySets,
                viewportOptions.viewportType,
                dataSource,
                viewportDataLoaded => {
                    CornerstoneViewportService.setViewportDisplaySets(
                        viewportIndex,
                        viewportDataLoaded,
                        viewportOptions,
                        displaySetOptions
                    );
                    setViewportData(viewportDataLoaded);
                },
                initialImageIndex
            )
        }
        loadViewportData();
    },[viewportOptions,displaySet])

    return (
        <div className="viewport-wrapper">
            <ReactResizeDetector
                handleWidth
                handleHeight
                skipOnMount={true} // Todo: make these configurable
                refreshMode={'debounce'}
                refreshRate={100}
                onResize={onResize}
                targetRef={elementRef.current}
            >
            </ReactResizeDetector>
            <div className="cornerstone-viewport-element"
                 data-idx = {displaySet.idx}
                 style={{ height: '100%', width: '100%' }}
                 onContextMenu={e => e.preventDefault()}
                 onMouseDown={e => e.preventDefault()}
                 ref={elementRef}></div>
            <CornerstoneOverlays
                viewportIndex={viewportIndex}
                element={elementRef.current}
                StudyDate={displaySet.StudyDate}
                idx={displaySet.idx}
                scrollbarHeight={scrollbarHeight}
                servicesManager={servicesManager}
            />
        </div>
    );
},areEqual)

export default OHIFCornerstoneViewport;
