
const TOOLBAR_BUTTON_TYPES = {
    COMMAND: 'command',
    SET_TOOL_ACTIVE: 'setToolActive',
    BUILT_IN: 'builtIn',
};

const definitions =[
    {
        id: 'WindowLevel',
        label: '调窗',
        title: '调窗',
        className: 'imageViewerTool',
        icon: 'wwwc',
        type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
    },
    [{
        id: 'Presets',
        label: '预设',
        title: '预设',
        className: 'imageViewerTool',
        icon: 'presets',
        type: TOOLBAR_BUTTON_TYPES.COMMAND,
        commandName: 'setWindowLevel',
    },
    {
        id: 'Presets',
        label: '全部预设',
        title: '全部预设',
        className: 'imageViewerTool',
        icon: 'presets',
        type: TOOLBAR_BUTTON_TYPES.COMMAND,
        commandName: 'setWindowLevelAll'
    }],
    {
        id: 'Magnify',
        label: '放大镜',
        title: '放大镜',
        className: 'imageViewerTool',
        icon: 'magnify',
        type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
       // noShow:['mobile']
    },
    [{
        id: 'invert',
        label: '反色',
        title: '反色',
        className: 'imageViewerTool',
        icon: 'invert',
        type: TOOLBAR_BUTTON_TYPES.COMMAND,
        commandName: 'invertViewport',
    },
    {
        id: 'invertAll',
        label: '全部反色',
        title: '全部反色',
        className: 'imageViewerTool',
        icon: 'invert',
        type: TOOLBAR_BUTTON_TYPES.COMMAND,
        commandName: 'invertViewportAll'
    }],
    {
        id: 'Zoom',
        label: '缩放',
        title: '缩放',
        className: 'imageViewerTool',
        icon: 'zoom',
        type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
        noShow:['mobile']
    },
    {
        id: 'Pan',
        label: '移动',
        title: '移动',
        className: 'imageViewerTool',
        icon: 'pan',
        type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
    },
    {
        id: 'StackScroll',
        label: '翻页',
        title: '翻页',
        className: 'imageViewerTool',
        icon: 'stackScroll',
        type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
    },
    {
        id: 'Length',
        label: '长度',
        title: '长度',
        className: 'imageViewerTool',
        icon: 'length',
        type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
    },
    {
        id: 'Angle',
        label: '角度',
        title: '角度',
        className: 'imageViewerTool',
        icon: 'simpleAngle',
        type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
    },
    {
        id: 'Probe',
        label: 'CT值',
        title: 'CT值',
        className: 'imageViewerTool',
        icon: 'probe',
        type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
    },
    {
        id: 'EllipticalROI',
        label: '椭圆',
        title: '椭圆',
        className: 'imageViewerTool',
        icon: 'ellipticalRoi',
        type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
    },
    {
        id: 'RectangleROI',
        label: '矩形',
        title: '矩形',
        className: 'imageViewerTool',
        icon: 'rectangleRoi',
        type: TOOLBAR_BUTTON_TYPES.SET_TOOL_ACTIVE,
    },
    {
        id: 'MPR',
        label: 'MPR',
        title: 'MPR',
        className: 'imageViewerTool',
        icon: 'mpr',
        type: TOOLBAR_BUTTON_TYPES.COMMAND,
        commandName: 'mpr2d',
        noShow:['compare']
    },
    {
        id: 'ScreenShot',
        label: '截图',
        title: '截图',
        className: 'imageViewerTool',
        icon: 'screenshot',
        type: TOOLBAR_BUTTON_TYPES.COMMAND,
        commandName: 'screenShot',
    },
    {
        id: 'resetViewport',
        label: '重置',
        title: '重置',
        className: 'imageViewerTool',
        icon: 'reset',
        type: TOOLBAR_BUTTON_TYPES.COMMAND,
        commandName: 'resetViewport',
    },
    {
        id: 'Erase',
        label: '清除',
        title: '清除',
        className: 'imageViewerTool',
        icon: 'clear',
        type: TOOLBAR_BUTTON_TYPES.COMMAND,
        commandName: 'clearAnnotations',
    },
    {
        id: 'TD',
        label: '三维',
        title: '三维',
        className: 'imageViewerTool',
        icon: '3d',
        type: TOOLBAR_BUTTON_TYPES.COMMAND,
        commandName: 'show3d',
        noShow:['compare'],
    },
    {
        id: 'Ai',
        label: 'AI',
        title: 'AI标注',
        className: 'imageViewerTool',
        icon: 'ai',
        type: TOOLBAR_BUTTON_TYPES.COMMAND,
        commandName: 'aiCommand',
        noShow:['compare'],
    }
];

const mprDefinitions =[
    {
        id: 'WindowLevel',
        label: '调窗',
        title: '调窗',
        className: 'imageViewerTool',
        icon: 'wwwc',
        type: TOOLBAR_BUTTON_TYPES.COMMAND,
        commandName: 'enableLevelTool',
    },
    {
        id: 'Presets',
        label: '预设',
        title: '预设',
        className: 'imageViewerTool',
        icon: 'presets',
        type: TOOLBAR_BUTTON_TYPES.COMMAND,
        commandName: 'setWindowLevel',
    },
    {
        id: 'Crosshairs',
        label: '定位',
        title: '定位',
        className: 'imageViewerTool',
        icon: 'crosshairs',
        type: TOOLBAR_BUTTON_TYPES.COMMAND,
        commandName: 'enableCrosshairsTool',
    },
    {
        id: 'DistanceTool',
        label: '测量',
        title: '测量',
        className: 'imageViewerTool',
        icon: 'length',
        type: TOOLBAR_BUTTON_TYPES.COMMAND,
        commandName: 'enableDistanceTool',
    },
    {
        id: 'ScreenShot',
        label: '截图',
        title: '截图',
        className: 'imageViewerTool',
        icon: 'screenshot',
        type: TOOLBAR_BUTTON_TYPES.COMMAND,
        commandName: 'screenShot',
    },
    {
        id: 'resetViewport',
        label: '重置',
        title: '重置',
        className: 'imageViewerTool',
        icon: 'reset',
        type: TOOLBAR_BUTTON_TYPES.COMMAND,
        commandName: 'resetViewport',
    },
    {
        id: 'Erase',
        label: '清除',
        title: '清除',
        className: 'imageViewerTool',
        icon: 'clear',
        type: TOOLBAR_BUTTON_TYPES.COMMAND,
        commandName: 'clearAnnotations',
    }
];

const compareToolbars = [
  {
    id: 'grid',
    label: '布局',
    title: '布局',
    className: 'imageViewerTool',
    icon: 'grid',
    type: TOOLBAR_BUTTON_TYPES.COMMAND,
    commandName: '',
  },
  {
    id: 'setting',
    label: '设置',
    title: '设置',
    className: 'imageViewerTool',
    icon: 'setting',
    type: TOOLBAR_BUTTON_TYPES.COMMAND,
    commandName: '',
  },
  {
    id: 'opBatch',
    label: '批量操作',
    title: '批量操作',
    className: 'imageViewerTool',
    icon: 'batch',
    type: TOOLBAR_BUTTON_TYPES.COMMAND,
    commandName: 'opBatchCommand',
  }
]
const backToolbar={
    id: 'Exit2DMPR',
    label: '返回',
    title: '返回',
    className: 'imageViewerTool',
    icon: 'to2d',
    type: TOOLBAR_BUTTON_TYPES.COMMAND,
    commandName: 'setCornerstoneLayout',
};

const switchToolbar={
    id: 'Switch',
    label: '序列',
    title: '序列',
    className: 'orangeTool',
    icon: 'menu',
    type:TOOLBAR_BUTTON_TYPES.COMMAND,
    commandName: 'seriesCommand',
};

const toolShare = {
    id: 'Share',
    label: '分享',
    title: '分享',
    className: 'imageViewerTool',
    icon: 'share',
    type: TOOLBAR_BUTTON_TYPES.COMMAND,
    commandName: 'shareCommand',
};

const toolPrev={
    id: 'Prev',
    label: '上一张',
    title: '上一张',
    className: 'tool-prev',
    icon: 'prev',
    type:TOOLBAR_BUTTON_TYPES.COMMAND,
    commandName: 'previousImage',
};

const toolNext={
    id: 'Next',
    label: '下一张',
    title: '下一张',
    className: 'tool-next',
    icon: 'next',
    type:TOOLBAR_BUTTON_TYPES.COMMAND,
    commandName: 'nextImage',
};

export function getDefaultButtons (barType,opbatch){
    const toolbarButtonDefinitions = [];
    if(barType === 'MPR' ){
        mprDefinitions.forEach(definition => {
            toolbarButtonDefinitions.push(definition);
        })
        toolbarButtonDefinitions.push(backToolbar);
        return toolbarButtonDefinitions;
    }else{
        definitions.forEach(definition => {
            let show = true;
            let _def
            if(definition.constructor === Array){
              _def = opbatch?definition[1]:definition[0];
            } else {
              _def = definition
            }
            if(barType && definition.noShow && definition.noShow.indexOf(barType) >= 0){
              show = false;
            }
            if(show) {
              toolbarButtonDefinitions.push(_def);
            }
        })
        if(!barType){
          toolbarButtonDefinitions.push(toolShare);
        } else if(barType === 'compare' ){
          compareToolbars.forEach(definition => {
            toolbarButtonDefinitions.push(definition);
          })
          let _ln = toolbarButtonDefinitions.length-1
          toolbarButtonDefinitions[_ln].label = (opbatch)?'取消批量':'批量操作'
        }
    }
    return toolbarButtonDefinitions;
}

export default getDefaultButtons;

export {definitions,mprDefinitions,switchToolbar,toolPrev,toolNext,toolShare}
