import setLayoutAndViewportData from './setLayoutAndViewportData.js';
import {Enums} from "@cornerstonejs/core";

const viewportOptions=[
  {
    viewportType: 'VOLUME',
    toolGroupId: 'VOLUME_TOOLGROUP_ID',
    viewportId: 'CT_AXIAL',
    orientation: Enums.OrientationAxis.AXIAL,
    syncGroups: [
      {
        type: 'cameraPosition',
        id: 'axialSync',
        source: true,
        target: true,
      },
      {
        type: 'voi',
        id: 'ctWLSync',
        source: true,
        target: true,
      },
      {
        type: 'zoompan',
        id: 'zoompanSync',
        syncPan:false,
        source: true,
        target: true,
      },
    ],
  },
  {
    viewportType: 'VOLUME',
    toolGroupId: 'VOLUME_TOOLGROUP_ID',
    viewportId: 'CT_SAGITTAL',
    orientation: Enums.OrientationAxis.SAGITTAL,
    syncGroups: [
      {
        type: 'cameraPosition',
        id: 'sagittalSync',
        source: true,
        target: true,
      },
      {
        type: 'voi',
        id: 'ctWLSync',
        source: true,
        target: true,
      },
      {
        type: 'zoompan',
        id: 'zoompanSync',
        syncPan:false,
        source: true,
        target: true,
      },
    ],
  },
  {
    viewportType: 'VOLUME',
    toolGroupId: 'VOLUME_TOOLGROUP_ID',
    viewportId: 'CT_CORONAL',
    orientation: Enums.OrientationAxis.CORONAL,
    syncGroups: [
      {
        type: 'cameraPosition',
        id: 'coronalSync',
        source: true,
        target: true,
      },
      {
        type: 'voi',
        id: 'ctWLSync',
        source: true,
        target: true,
      },
      {
        type: 'zoompan',
        id: 'zoompanSync',
        syncPan:false,
        source: true,
        target: true,
      },
    ],
  },
  {
  }
]


export default function setMPRLayout(
  displaySet,
  numRows = 1,
  numColumns = 1
) {
  return new Promise((resolve, reject) => {
    const viewports = [];
    const numViewports = numRows * numColumns;

    const viewportSpecificData = {};

    for (let i = 0; i < numViewports; i++) {
      viewports.push({});
      viewportSpecificData[i] = displaySet;
      viewportSpecificData[i].plugin = 'vtk';
    }

    const apis = [];
    viewports.forEach((viewport, index) => {
      apis[index] = null;
      viewports[index] = Object.assign({}, viewports[index], {
        vtk: {
          mode: 'mpr', // TODO: not used
          afterCreation: api => {
            apis[index] = api;

            if (apis.every(a => !!a)) {
              resolve(apis);
            }
          },
        },
      },{
        viewportOptions:viewportOptions[index]
      });
    });

    setLayoutAndViewportData(
      {
        numRows,
        numColumns,
        viewports,
      },
      viewportSpecificData
    );
  });
}
