import focusClasses from "../../assets/focusClasses";
import classificationTable from "../../assets/classificationTable";

const getFocusClassItem=(focusClassId)=>{
    const item={id:0,code:"",name:"",class0:{},class1:{}}
    for(let i=0;i<focusClasses.length;i++){
        let items0_code=focusClasses[i].code;
        let items0_name=focusClasses[i].name;
        let items0_items=focusClasses[i].items;
        if(items0_items&&items0_items.length){
            let found1=false;
            for(let j=0;j<items0_items.length;j++){
                let items1_code=items0_items[j].code;
                let items1_name=items0_items[j].name;
                let items1_items=items0_items[j].items;
                if(items1_code==focusClassId){
                    item.code=items1_code;
                    item.name=items1_name;
                    item.class0={code:items0_code,name:items0_name}
                    item.class1={code:items1_code,name:items1_name}
                    if(items0_items[j].tip) {
                      item.tip = items0_items[j].tip;
                    }
                    if(items0_items[j].drawType) {
                      item.drawType = items0_items[j].drawType;
                    }
                    found1=true;
                    break;
                }
                else{
                    if(items1_items&&items1_items.length){
                        let found2=false;
                        for(let k=0;k<items1_items.length;k++){
                            let items2_code=items1_items[k].code;
                            let items2_name=items1_items[k].name;
                            if(items2_code==focusClassId){
                                item.code=items2_code;
                                item.name=items2_name;
                                item.class0={code:items0_code,name:items0_name}
                                item.class1={code:items1_code,name:items1_name}
                                if(items1_items[k].tip) {
                                  item.tip = items1_items[k].tip;
                                }
                                if(items1_items[k].drawType) {
                                  item.drawType = items1_items[k].drawType;
                                }

                                found1=true;
                                found2=true;
                                break;
                            }
                        }
                        if(found2){
                            break;
                        }
                    }
                }
            }
            if(found1){
                break;
            }
        }
    }
    return item;
}


const getClassificationTable=(focusClassId)=>{
    const item = [{code:'other',name:'其他'},{code: 'other',name:'其他'}]
    for(let i=0;i<classificationTable.length;i++){
      let _item0 = classificationTable[i];
      let found1 = false;
      if (_item0.items && _item0.items.length > 0) {
        for(let j=0;j< _item0.items.length;j++){
            let _item1 = _item0.items[j];
            if( focusClassId.indexOf(_item1.classId) === 0 ) {
                item[0] = {code: _item0.code,name: _item0.name}
                item[1] = {code: _item1.code,name: _item1.name,sortType: _item1.sortType,sortChoose:'frameNumber'}
                found1=true;
                break;
            }
        }
      }
      if(found1){
        break;
      }
    }
    return item;
}
export default function getFocusClassesData(aiJSONArray) {
    const classesData=[]
    for(let i=0;i<aiJSONArray.length;i++){
        let mark=aiJSONArray[i].mark;
        let focusClassId=mark.focusClasses[0];
        let item=getFocusClassItem(focusClassId);
        const tabClass = getClassificationTable(focusClassId)
        item.tabId = tabClass[1].code
        item.class0 = tabClass[0]
        item.class1 = tabClass[1]
        item.id=aiJSONArray[i].id;
        classesData.push(item)
    }

    return classesData;
}
