import touchState from "./touchEventState";
import * as cornerstoneTools from '@cornerstonejs/tools';
import {getEnabledElement as OHIFgetEnabledElement} from "../state";
import {getEnabledElement} from "@cornerstonejs/core";
import {servicesManager} from "../../App";

const {ToolGroupManager} = cornerstoneTools;

const _getActiveViewportEnabledElement=(activeViewportIndex)=> {
    const element = OHIFgetEnabledElement(activeViewportIndex) || {};
    const enabledElement = getEnabledElement(element as HTMLDivElement);
    return enabledElement;
}

export default function touchStart(evt){

    /*
    const { viewportId } = _getActiveViewportEnabledElement(0);
    const {CornerstoneViewportService}=servicesManager.services;
    const renderingEngine = CornerstoneViewportService.getRenderingEngine();
    const viewport = renderingEngine.getViewport(viewportId);
    const toolGroup = ToolGroupManager.getToolGroup("STACK_TOOL_GROUP_ID");
    touchState.activeTool = toolGroup.getActivePrimaryMouseButtonTool();
    touchState.viewport=viewport;
    touchState.imageSliceData.imageIndex=viewport.getCurrentImageIdIndex();

    touchState.startPoint={x:evt.touches[0].pageX.toFixed(2),y:evt.touches[0].pageY.toFixed(2)}
    touchState.moveEndPoint={x:evt.touches[0].pageX.toFixed(2),y:evt.touches[0].pageY.toFixed(2)}
    */

    evt.preventDefault()
}
