const levels = [
            {level:1,title:'高危'},
            {level:2,title:'中危'},
            {level:3,title:'低危'},
            {level:4,title:'良性'},
            {level:5,title:'未知'}
        ]

const classnameMapping = [
        '',
        '肿瘤性病变',
        '非肿瘤性病变',
        '实性结节',
        '亚实性结节',
        '磨玻璃结节',
        '钙化结节',
        '钙化结节',
        '实性结节',
        '肿块'
        ]


export function dangerLevels(){
  return levels;
}

export function tubercleTypes(){
  return [
      {title:'实性结节',codes:['8-3','8-8']},
      {title:'亚实性结节',codes:['8-4']},
      {title:'磨玻璃结节',codes:['8-5']},
      {title:'钙化结节',codes:['8-6','8-7']},
      {title:'肿块',codes:['8-9']},
    ]
}

export function dangerLevel(serverItem){
    const code = serverItem.code.split('-')
    let classId = parseInt(code[1])
    if (classId === 7) {
        //8-7 -> 8-6
        classId = 6
    } else if (classId === 8) {
        //8-8 -> 8-3
        classId = 3
    } else if (classId < 3 || classId>=classnameMapping.length) {
        // invalid = true
    }

    let dangerlvl
    if(classId === 3){
      if(serverItem.ldiam >= 15){
        dangerlvl = 0
      }else if(serverItem.ldiam >= 5){
        dangerlvl = 1
      }else {
        dangerlvl = 2
      }
    } else if (classId === 4) {
      if(serverItem.ldiam >= 8){
        dangerlvl = 0
      }else {
        dangerlvl = 1
      }
    } else if (classId === 5) {
      if(serverItem.ldiam >= 5){
        dangerlvl = 1
      }else {
        dangerlvl = 2
      }
    } else if (classId === 6 || classId === 7) {
      dangerlvl = 3
    } else {
      dangerlvl = 4
    }

    return levels[dangerlvl]
}
