import actions from './../core/redux/actions';
import ViewerMain from './ViewerMain';
import { connect } from 'react-redux';

const {
  setLayout,
  setViewportSpecificData,
  clearViewportSpecificData,
  clearStudyData
} = actions;

const mapStateToProps = (state) => {
  const { activeViewportIndex, layout, viewportSpecificData } = state.viewports;
  return {
    activeViewportIndex,
    layout,
    viewportSpecificData,
    viewports: state.viewports,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setLayout:({ numRows, numColumns, viewports })=>{
      dispatch(setLayout({ numRows, numColumns, viewports }));
    },
    setViewportSpecificData: (viewportIndex, data) => {
      dispatch(setViewportSpecificData(viewportIndex, data));
    },
    clearViewportSpecificData: () => {
      dispatch(clearViewportSpecificData());
    },
    clearStudyData:()=>{
      dispatch(clearStudyData());
    }
  };
};

const ConnectedViewerMain = connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewerMain);

export default ConnectedViewerMain;
