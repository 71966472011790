
export default function (param) {

    var token="";
    if (window.localStorage.Token) {
        token = window.localStorage.Token
    } else if (window.localStorage.shareToken) {
        token = window.localStorage.shareToken
    }

    return token
}

