import cloneDeep from 'lodash.clonedeep';

const defaultState = {
  icons: [],
};

const seriesicons = (state = defaultState, action) => {
  switch (action.type) {
    case 'SET_STUDY_ICONS': {
      const iconsData = cloneDeep(state.icons);
      //iconsData[action.SeriesId] = cloneDeep(action.data);
      iconsData.push(cloneDeep(action.data));

      return Object.assign({}, state, { icons: iconsData });
    }
    default:
      return state;
  }
};

export default seriesicons;
