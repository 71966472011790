import './login.css';
import React, {Component} from "react";
import {withRouter } from "react-router";
import {Input, Button, message} from "antd";
import loginByAuthCode from "../core/http/loginByAuthCode";
import loginByStudyAuthCode from "../core/http/loginByStudyAuthCode";
import sendLoginAuthCode from "../core/http/sendLoginAuthCode";
import sendLoginAuthCodeByStudy from "../core/http/sendLoginAuthCodeByStudy";
import getPatientDesensitizationPhoneNumber from "../core/http/getPatientDesensitizationPhoneNumber";
import PropTypes from "prop-types";

class Login extends Component {

    constructor (props) {
        super(props);
        this.doLogin = this.doLogin.bind(this);
        let params=this.getParams(props.params);
        this.state={
            authMode: props.authMode,
            loading: false,
            yztime: 59,
            timer:null,
            hospitalId:props.hospitalId,
            name:"",
            mobile:"",
            authCode:"",
            smsSendId:"",
            params:params,
        }
    }

    getParams=(params)=>{
        let param={};
        if(params){
            param={
                "accessionNumber00080050": params.AccessNo,
                "hospitalId": params.hospitalCode,
                "patientId00100020": "",
                "studyDate00080020": params.ExamineDate,
                "studyId00200010": "",
                "studyInstanceUid0020000d": ""
            }
        }
        return param;
    }

    doLogin(){
        let {hospitalId,mobile,authCode,smsSendId,params}=this.state;
        let history=this.props.history;
        if(!smsSendId||!authCode){
            message.error("请输入验证码");
            return;
        }
        if(this.props.authMode=="NNM"){
            loginByStudyAuthCode({
                "accessionNumber00080050":params.accessionNumber00080050,
                "authCode": authCode,
                "hospitalId": hospitalId,
                "patientId00100020": params.patientId00100020,
                "smsSendId": smsSendId,
                "studyDate00080020":params.studyDate00080020,
                "studyId00200010":params.studyId00200010,
                "studyInstanceUid0020000d":params.studyInstanceUid0020000d
            }).then(data=>{
                if (!!data && !data.error) {
                    history.push({pathname:'/list',state:{hospitalId: hospitalId}});
                }
                else{
                    console.log(data);
                }
            })
        }
        else{
            loginByAuthCode({
                "authCode": authCode,
                "hospitalId": hospitalId,
                "patientPhoneNumber": mobile,
                "smsSendId": smsSendId
            }).then(data=>{
                if (!!data && !data.error) {
                    history.push({pathname:'/list',state:{hospitalId: hospitalId}});
                }
                else{
                    console.log(data);
                }
            })
        }
    }

    //倒计60s
    count = () => {
        let { yztime } = this.state;
        let siv = setInterval(() => {
            this.setState({ yztime: (yztime--) }, () => {
                if (yztime <= -1) {
                    clearInterval(siv);
                    this.setState({ loading: false, yztime: 59,timer:null })
                }
            });
        }, 1000);
        this.setState({timer:siv});
    }

    verifiedSubmit = (e) => {
        let {authMode,hospitalId,mobile,params}=this.state;
        var _this=this;
        if(authMode=="NNM"){
            this.setState({ loading: true });
            e.preventDefault();
            this.count();
            sendLoginAuthCodeByStudy(params).then(data=>{
                if (!!data && !data.error) {
                    _this.setState({smsSendId:data.smsSendId});
                }
                else{
                    message.error(data.error.message);
                    console.log(data);
                }
            });
        }
        else{
            if(mobile&&mobile.length==11){
                this.setState({ loading: true });
                e.preventDefault();
                this.count();
                sendLoginAuthCode({hospitalId:hospitalId,patientPhoneNumber:mobile}).then(data=>{
                    if (!!data && !data.error) {
                        _this.setState({smsSendId:data.smsSendId});
                    }
                    else{
                        message.error("发送失败");
                        console.log(data);
                    }
                });
            }
            else{
                message.error("请输入正确的手机号码");
            }
        }
    }

    setName=(e)=>{
        let v=e.target.value;
        this.setState({name:v});
    }

    setMobile=(e)=>{
        let v=e.target.value;
        this.setState({mobile:v});
    }

    setCode=(e)=>{
        let v=e.target.value;
        this.setState({authCode:v});
    }

    componentDidMount() {
        if(this.props.authMode=="NNM"){
            let _this=this
            getPatientDesensitizationPhoneNumber(this.state.params).then(data=>{
                    if (!!data && !data.error) {
                        _this.setState({mobile:data})
                    } else {
                        _this.setState({authMode:'M'})
                    }
                }
            )
        }
    }

    componentWillUnmount(){
        let {timer}=this.state;
        if(timer){
            clearInterval(timer);
        }
    }

    render() {
        const {authMode,mobile,authCode,name,loading}=this.state;
        let nnmInputTxt="";
        if(authMode=="NNM"){
            nnmInputTxt="请输入手机号"+mobile+"收到的短信验证码";
            if(loading)
                nnmInputTxt="验证码已发送至"+mobile;
        }
        return (
            <div className="login">
                <div className="model">
                    <div className="bg">
                        <div className="prompt">为保护个人隐私与信息安全，需要进行身份验证</div>
                    </div>
                    <div className="box">
                        <div className="field"><span className="caption">请输入以下预留信息，进行身份认证</span></div>
                        {
                            authMode=="NM" &&
                            <div className="field"><span className="icou"></span><Input placeholder="请输入姓名" className="txt" value={name} onChange={(e)=>{this.setName(e)}} /></div>
                        }
                        {
                            (authMode=="NM"||authMode=="M")&&
                            <div className="field"><span className="icom"></span><Input placeholder="请输入手机号" className="txt" value={mobile} onChange={(e)=>{this.setMobile(e)}} disabled={this.state.loading} /></div>
                        }
                        {
                            authMode=="NNM"&&
                            <div className="field"><span className="icom"></span><Input placeholder={nnmInputTxt} disabled={true} className="txt" /></div>
                        }
                        <div className="field"><span className="icov"></span>
                            <Input placeholder="请输入验证码" className="txt" value={authCode} onChange={(e)=>{this.setCode(e)}} style={{width: 'calc(100% - 100px)',}}
                             suffix={
                                 <Button style={{width:'100px'}} disabled={loading} onClick={this.verifiedSubmit}>
                                     {loading ? this.state.yztime + '秒' : '发送验证码'}
                                 </Button>
                             }>
                        </Input></div>
                        <div><button className="btn" onClick={this.doLogin}></button> </div>
                        <div className="field" style={{textAlign:"center"}}>
                            <div className="anquan">
                                <i className={"iconfont icon-anquanbaozhang"} /><span className="lab" style={{fontSize:"14px"}}>个人信息加密保护中</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

Login.propTypes={
    hospitalId: PropTypes.string.isRequired,
    authMode: PropTypes.oneOf(['M','NM','NNM']),
    params: PropTypes.object,
}

export default withRouter(Login);
