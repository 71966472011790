import touchState from "./touchEventState";
import touchEventHandler from "./touchEventHandler";

export default function touchMove(evt){

   // touchState.moveEndPoint={x:evt.changedTouches[0].pageX.toFixed(2),y:evt.changedTouches[0].pageY.toFixed(2)}
    evt.preventDefault()

   // touchEventHandler(touchState)
}
