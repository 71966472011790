import getToolAlias from './utils/getToolAlias';
import * as cornerstoneTools from '@cornerstonejs/tools';
import init from '../../dicom-sr/init';

const {
  PanTool,
  WindowLevelTool,
  StackScrollMouseWheelTool,
  ZoomTool,
  StackScrollTool,
  MagnifyTool,
  LengthTool,
  AngleTool,
  ProbeTool,
  EllipticalROITool,
  RectangleROITool,
  CrosshairsTool,
  annotation
} = cornerstoneTools;

const initSRTools = () => {
  // const primaryToolId = 'Wwwc';
  // const toolAlias = getToolAlias(primaryToolId); // These are 1:1 for built-in only

  // Add tools to Cornerstone3D
  cornerstoneTools.addTool(PanTool);
  cornerstoneTools.addTool(WindowLevelTool);
  cornerstoneTools.addTool(StackScrollMouseWheelTool);
  cornerstoneTools.addTool(ZoomTool);
  cornerstoneTools.addTool(StackScrollTool);
  cornerstoneTools.addTool(MagnifyTool);
  cornerstoneTools.addTool(LengthTool);
  cornerstoneTools.addTool(AngleTool);
  cornerstoneTools.addTool(ProbeTool);
  cornerstoneTools.addTool(EllipticalROITool);
  cornerstoneTools.addTool(RectangleROITool);
  cornerstoneTools.addTool(CrosshairsTool);

  init({});
};

const annotationStyle = {
  textBoxFontSize: '15px',
  lineWidth: '1.5',
};

const defaultStyles = annotation.config.style.getDefaultToolStyles();

annotation.config.style.setDefaultToolStyles({
  global: {
    ...defaultStyles.global,
    ...annotationStyle,
  },
});

export default initSRTools;
