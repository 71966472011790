import MetadataProvider from './MetadataProvider';
import OHIFError from './OHIFError.js';
import { StudyPrefetcher } from './StudyPrefetcher';

export {
  MetadataProvider,
  StudyPrefetcher,
  OHIFError,
};

const classes = {
  MetadataProvider,
  StudyPrefetcher,
  OHIFError,
};

export default classes;
