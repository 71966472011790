/* global cornerstone */
import './ImageThumbnail.styl';

import utils from './../../../core/utils';
import React, { useState, useEffect, createRef, useCallback } from 'react';
import classNames from 'classnames';

import PropTypes from 'prop-types';
import ViewportErrorIndicator from '../../ViewportErrorIndicator';
import ViewportLoadingIndicator from '../../ViewportLoadingIndicator';
import * as cornerstone from '@cornerstonejs/core';

// TODO: How should we have this component depend on Cornerstone?
// - Passed in as a prop?
// - Set as external dependency?
// - Pass in the entire load and render function as a prop?
//import cornerstone from 'cornerstone-core';
function ImageThumbnail(props) {
  const {
    active,
    width,
    height,
    imageSrc,
    imageId,
    stackPercentComplete,
    error: propsError,
    showProgressBar,
    children
  } = props;

  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [image, setImage] = useState({});
  const canvasRef = createRef();

  let loadingOrError;
  let cancelablePromise;

  if (propsError || error) {
    loadingOrError = <ViewportErrorIndicator />;
  } else if (isLoading) {
    loadingOrError = <ViewportLoadingIndicator />;
  }

  const showStackLoadingProgressBar =
    showProgressBar && stackPercentComplete !== undefined;

  const shouldRenderToCanvas = () => {
    return imageId && !imageSrc;
  };

  const fetchImagePromise = () => {
    if (!cancelablePromise) {
      return;
    }

    setLoading(true);
    cancelablePromise
      .then(imageId => {
        setImage(canvasRef.current.toDataURL());
      })
      .catch(error => {
        if (error.isCanceled) return;
        // setLoading(false);
        // setError(true);
        // throw new Error(error);
      });
  };

  const setImagePromise = () => {
    if (shouldRenderToCanvas()) {
      cancelablePromise = utils.makeCancelable(
        cornerstone.utilities.loadImageToCanvas(canvasRef.current, imageId)
      );
    }
  };

  const purgeCancelablePromise = useCallback(() => {
    if (cancelablePromise) {
      cancelablePromise.cancel();
    }
  });

  useEffect(() => {
    return () => {
      purgeCancelablePromise();
    };
  }, [purgeCancelablePromise]);

  useEffect(() => {
    if (image) {
      //cornerstone.utilities.renderToCanvas(canvasRef.current, image);
      setLoading(false);
    }
  }, [canvasRef, image]);

  useEffect(() => {
    // if (!image||imageId)
     {
      purgeCancelablePromise();
      setImagePromise();
      fetchImagePromise();
     }
  }, [
    // fetchImagePromise,
    // imageId,
    // purgeCancelablePromise,
    // setImagePromise,
  ]);

  let isMobile=navigator.userAgent.match(/Android/i)||
      navigator.userAgent.match(/webOS/i)||
      navigator.userAgent.match(/iphone/i);

  return (
    <div className={classNames('ImageThumbnail', { active: active },{mobile:isMobile})}>
      <div className="image-thumbnail-canvas">
        {shouldRenderToCanvas() ? (
          <canvas ref={canvasRef} width={width} height={height} />
        ) : (
          <img
            className="static-image"
            src={imageSrc}
            //width={this.props.width}
            height={height}
            alt={''}
          />
        )}
      </div>
      <div className="thumbnail-information">
        {children}
      </div>
      {loadingOrError}
      {showStackLoadingProgressBar && stackPercentComplete<100 && (
        <div className="image-thumbnail-progress-bar">
          <div
            className="image-thumbnail-progress-bar-inner"
            style={{ width: `${stackPercentComplete}%` }}
          />
        </div>
      )}
      {isLoading && <div className="image-thumbnail-loading-indicator"></div>}
    </div>
  );
}

ImageThumbnail.propTypes = {
  active: PropTypes.bool,
  imageSrc: PropTypes.string,
  imageId: PropTypes.string,
  error: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
  stackPercentComplete: PropTypes.number.isRequired,
  showProgressBar: PropTypes.bool,
};

ImageThumbnail.defaultProps = {
  active: false,
  error: false,
  stackPercentComplete: 0,
  width: 80,
  height: 66,
  showProgressBar: true,
};

export default ImageThumbnail;
