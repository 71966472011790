const classificationTable = [
    { code: 'T-1', name: '肺疾病',
      items: [
        { code: 'T-1-1', name:'肺结节',sortType: 2, classId: '8' }, // #
        { code: 'T-1-2', name:'肺炎',sortType: 1, classId: '7-1' },
        { code: 'T-1-3', name:'肺大疱',sortType: 1, classId: '6-1-1' },
        { code: 'T-1-4', name:'支扩',sortType: 0, classId: '3-1' },
        { code: 'T-1-5', name:'空洞',sortType: 0, classId: '6-2' },
      ]
    },
    { code: 'T-2', name: '胸膜纵隔',
      items: [
        { code: 'T-2-1', name:'气胸',sortType: 0, classId: '1-1' },
        { code: 'T-2-2', name:'胸腔积液',sortType: 1, classId: '1-2' },
        { code: 'T-2-3', name:'胸膜增厚',sortType: 0, classId: '1-3' },
        { code: 'T-2-4', name:'胸膜肿瘤',sortType: 1, classId: '2-2' }
      ]
    }
];

export default classificationTable;
