import './HelpBox.styl';

import PropTypes from 'prop-types';
import React from 'react';
import ToolbarButton from '../ToolbarButton.js';
import Icon from '../components/Icon';
import getSharingCode from "../../core/http/getSharingCode";
import {message} from "antd";
import classnames from 'classnames';


class HelpBox extends React.Component {
  static propTypes = {
    /** Button label */
    label: PropTypes.string.isRequired,
    icon: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        name: PropTypes.string.isRequired,
      }),
    ]),
    activeCommand: PropTypes.string,
    isMobile: PropTypes.bool,
    onBarClick: PropTypes.func,
  };

  static defaultProps = {
    buttons: [],
    icon: 'info',
    label: '帮助',
    isMobile: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      isShow: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.active !== prevProps.active) {
      if(!this.props.active){
        this.setState({
            isShow: false,
        });
      }
    }
  }

  activeIcon = () => {
    return this.props.icon;
  };

  onExpandableToolClick = () => {
    const _r = !this.state.isShow
    this.setState({
      isShow: _r,
    });
    if(this.props.onBarClick){
      this.props.onBarClick(this.props.id);
    }
  };

  render() {
    const {isShow, expHour, isRes, shareCode, urlCode}=this.state;
    const getToolBarButtonComponent = () => {
      return (
        <ToolbarButton
          key="menu-button"
          type="tool"
          label={this.props.label}
          icon={this.activeIcon()}
          className={'toolbar-button expandableToolMenu'}
          onClick={this.onExpandableToolClick}
        />
      );
    };

    const toolbarComponent = getToolBarButtonComponent();

    const getResulr = () => {
       return (<div>
          <div><br/>
            1. 点击右上角的系列图标，可显示影像的所有系列，系列可上下滑动。<br/>
            2. 页面首行图标菜单，可左右滑动<br/>
            3. 影像区域，手势向下或者向上可滚动影像<br/><br/>
               </div>
               <div className="center">
                <button className="btn-primary" onClick={this.onExpandableToolClick}>我知道了</button>
              </div>
              <br/>
          </div>)
    }
    const resComponent = getResulr();

    return (
      <div className="helpMobile">
        {toolbarComponent}
        {isShow && <div className="helpBox">
            <Icon
                    name="cancel"
                    className="cancel-icon"
                    onClick={this.onExpandableToolClick}
                  />
            {resComponent}

        </div>}
      </div>
    );
  }
}

export default HelpBox;
