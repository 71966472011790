import React from 'react';
import ReactDOM from 'react-dom';

import "antd/dist/antd.css";
import './index.css';
import App from './App';
import {servicesManager} from "./App";

import initCornerstone from "./initCornerstone";
import {initMeasurementService} from "./initMeasurementService";

initCornerstone().then(()=>{

    const {MeasurementService}=servicesManager.services;
    initMeasurementService(MeasurementService);

    ReactDOM.render(<App />, document.getElementById('root'));
})
