import './SettingBox.styl';
import PropTypes from 'prop-types';
import React from 'react';
import ToolbarButton from '../ToolbarButton.js';
import Icon from '../components/Icon';
import classnames from 'classnames';
import {message} from "antd";

class SettingBox extends React.Component {
  static propTypes = {
    /** Button label */
    label: PropTypes.string.isRequired,
    icon: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        name: PropTypes.string.isRequired,
      }),
    ]),
    activeCommand: PropTypes.string,
    studieLen: PropTypes.number,
    onSetNoShow: PropTypes.func,
    onBarClick: PropTypes.func,
  };

  static defaultProps = {
    buttons: [],
    icon: 'setting',
    label: '设置',
  };

  constructor(props) {
    super(props);
    this.state = {
      isShow: false,
      ids: []
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.active !== prevProps.active) {
      if(!this.props.active){
        this.setState({
            isShow: false,
        });
      }
    }
  }
  activeIcon = () => {
    return this.props.icon;
  };

  onExpandableToolClick = () => {
    const _r = !this.state.isShow
    this.setState({
      isShow: _r,
    });
    this.props.onBarClick(this.props.id);
  };

  onCheckChange=event=>{}

  onChangeHandler = event =>{
    const idx = parseInt(event.target.dataset.id)
    const ids = this.state.ids
    const _d = ids.indexOf(idx);
    if(_d >=0){
        ids.splice(_d ,1);
    } else{
       ids.push(idx);
    }

    this.setState({
        ids: ids,
    });
  };

  onClickHandler = event =>{
    const _len = this.state.ids.length;
    if(_len >= this.props.studieLen ){
      message.warn('至少选择一个影像显示！')
      return;
    }
    if(this.props.onSetNoShow){
      this.props.onSetNoShow(this.state.ids)
    }
    this.setState({
      isShow: false,
    });
  };

  render() {
    const {isShow,ids}=this.state;
    const getToolBarButtonComponent = () => {
      return (
        <ToolbarButton
          key="menu-button"
          type="tool"
          label={this.props.label}
          icon={this.activeIcon()}
          active={this.props.active}
          className={'toolbar-button expandableToolMenu'}
          onClick={this.onExpandableToolClick}
        />
      );
    };

    const toolbarComponent = getToolBarButtonComponent();
    const getTrs = () => {
        const items=[];
        for(let i=0;i<this.props.studieLen;i++){
            const _has = this.state.ids.indexOf(i) >=0
            items[i]=(
                <div className="chk-item" key={i} data-id={i} onClick={this.onChangeHandler}>
                    <input type="checkbox" key={i} checked={!_has} data-id={i} onChange={this.onCheckChange} />{i+1}
                </div>
            )
        }
        return items;
    };

    const trComponent = getTrs();

    const getResulr = () => {
        if(!isShow) return;
        return (
          <div className="settingBox">
            <div className="settingBoxChk">
              {trComponent}
            </div>
            <button className="settingBoxBtn" onClick={this.onClickHandler}>确定</button>
          </div>
        );
    }

    const tabComponent = getResulr();
    return (
      <div>
        {toolbarComponent}
        {tabComponent}
      </div>
    );
  }
}

export default SettingBox;
