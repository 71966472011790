import Fetch from '../fetch.js';
import {KjApi} from "../api";

export default function (param) {

    let api =KjApi.getStudyDetail;
    let paramData = param;

    const promise = new Promise(function (resolve, reject) {

        Fetch(api, {
            data: paramData,
            customError: true
        }).then(
            (data) =>{

                resolve(data);
            },
            (error)=>{
                console.log(error);
            }
        );
    });

    return promise;
}
