import { connect } from 'react-redux';
import findDisplaySetByUID from './findDisplaySetByUID';
import { StudyBrowser } from './components/studyBrowser/StudyBrowser';
import actions from '../core/redux/actions';
import setCornerstoneLayout from "../viewport/utils/setCornerstoneLayout";

import * as cornerstoneTools from '@cornerstonejs/tools';


const {
  ToolGroupManager,CrosshairsTool
} = cornerstoneTools;
const { setViewportActive, setViewportSpecificData } = actions;

const mapStateToProps = (state) => {
  const { viewportSpecificData,activeViewportIndex } = state.viewports;
  return {
    viewportSpecificData,
    activeViewportIndex
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const {isCompare,noShow,studies} = ownProps;

  return {
    onTabClick: (_idx) => {
      dispatch(setViewportActive(_idx));
    },

    onThumbnailClick: (_idx,displaySetInstanceUID,viewportSpecificData) => {
      let oldUID = viewportSpecificData[_idx].displaySetInstanceUID
      if(displaySetInstanceUID!==oldUID){
        ToolGroupManager.getAllToolGroups().forEach(toolGroup=>{
          const activeTool=toolGroup.getActivePrimaryMouseButtonTool()
          if(activeTool==CrosshairsTool.toolName){
            toolGroup.setToolDisabled(activeTool);
          }
        });
         if(!isCompare) {
          setCornerstoneLayout();
         }
      }
      let displaySet = findDisplaySetByUID(
        ownProps.studyMetadata,
        displaySetInstanceUID
      );
      if(ownProps.onDrawerClose){
        ownProps.onDrawerClose();
      }
      dispatch(setViewportSpecificData(_idx, displaySet));
    },
  };
};

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => {
  const onThumbnailClickDispatch = propsFromDispatch.onThumbnailClick;
  const onTabClickDispatch = propsFromDispatch.onTabClick;
  const { viewportSpecificData } = propsFromState;
  const props = {
    ...propsFromState,
    ...propsFromDispatch,
    ...ownProps,
    onThumbnailClick: (thumbIndex,displaySetInstanceUID) =>
      onThumbnailClickDispatch(thumbIndex,displaySetInstanceUID, viewportSpecificData),
    onTabClick: (thumbIndex) =>
      onTabClickDispatch(thumbIndex),
  }

  return props;
};

const ConnectedStudyBrowser = connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(StudyBrowser);

export default ConnectedStudyBrowser;
