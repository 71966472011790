import './login.css';
import React, {Component} from "react";
import {withRouter } from "react-router";
import {Input, Button, message} from "antd";
import getSharingCodeToken from "../core/http/getSharingCodeToken";
import PropTypes from "prop-types";



class ShareCode extends Component {

    constructor (props) {
        super(props);
        this.doLogin = this.doLogin.bind(this);

        this.state={
            loading: false,
            kjStudyId:props.kjStudyId,
            hospitalId:props.hospitalId,
            authCode:props.authCode ? props.authCode : '',
        }
    }

    doLogin(){
        let {kjStudyId,hospitalId,authCode}=this.state;
        let history=this.props.history;

        if(!authCode){
            message.error("请输入分享码");
            return;
        }

        getSharingCodeToken({
             hospitalId: hospitalId,
             kjStudyId: kjStudyId,
             sharingAuthCode: authCode,
             verifierIp: ""
        }).then(data=>{
            if (!!data && !data.error) {
                history.push({pathname:'/',state:{kjStudyId: kjStudyId,hospitalId: data.hospitalId,noShare: true}});
            }
            else{
                console.log(data);
                message.error("验证码超时，请重新分享！");
            }
        })
    }

    setCode=(e)=>{
        let v=e.target.value;
        this.setState({authCode:v});
    }

    render() {
        const {authCode,loading}=this.state;
        return (
            <div className="login">
                <div className="model">
                    <div className="bg center">
                        <div className="prompt">为保护个人隐私与信息安全，需要验证分享码</div>
                    </div>
                    <div className="box">
                        <div className="field"><span className="caption">请输入分享码</span></div>
                          <div className="field"><span className="icov"></span>
                            <Input placeholder="请输入分享码" className="txt" value={authCode} onChange={(e)=>{this.setCode(e)}} style={{width: 'calc(100% - 100px)',}}>
                        </Input></div>
                        <div><button className="share-btn" onClick={this.doLogin} disabled={loading}>确  定</button> </div>
                        <div className="field" style={{textAlign:"center"}}>
                            <div className="anquan">
                                <i className={"iconfont icon-anquanbaozhang"} /><span className="lab" style={{fontSize:"14px"}}>个人信息加密保护中</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

ShareCode.propTypes={
    kjStudyId: PropTypes.string.isRequired,
}

export default withRouter(ShareCode);
