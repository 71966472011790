import './GridBox.styl';
import PropTypes from 'prop-types';
import React from 'react';
import ToolbarButton from '../ToolbarButton.js';
import Icon from '../components/Icon';
import classnames from 'classnames';
import {message} from "antd";

class GridBox extends React.Component {
  static propTypes = {
    /** Button label */
    label: PropTypes.string.isRequired,
    icon: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        name: PropTypes.string.isRequired,
      }),
    ]),
    activeCommand: PropTypes.string,
    studieLen: PropTypes.number,
    onGridClick: PropTypes.func,
    onBarClick: PropTypes.func,
  };

  static defaultProps = {
    buttons: [],
    icon: 'grid',
    label: '布局',
    studieLen: '2',
  };

  constructor(props) {
    super(props);
    this.state = {
      isShow: false,
      row: -1,
      col: -1,
      trNum: 2,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.active !== prevProps.active) {
      if(!this.props.active){
        this.setState({
            isShow: false,
        });
      }
    }
    if (this.props.studieLen !== prevProps.studieLen) {
        this.setState({
            trNum: (this.props.studieLen>2)?3:2,
        });

    }
  }

  activeIcon = () => {
    return this.props.icon;
  };

  onExpandableToolClick = () => {
    const _r = !this.state.isShow
    this.setState({
      isShow: _r,
    });
    this.props.onBarClick(this.props.id);
  };

  onClickHandler = event =>{
    let _r = parseInt(event.target.dataset.row);
    let _c = parseInt(event.target.dataset.col);

    this.setState({
      row: _r,
      col: _c,
    });
    if(_r*_c < this.props.studieLen ){
      message.warn('选择的布局格子不能少于' + this.props.studieLen + '个！')
      return;
    }

    if(this.props.onGridClick){
      this.props.onGridClick({rowNum:_r,colNum:_c})
    }
    this.setState({
      isShow: false
    });
  };
  onMouseEnter = event =>{
    this.setState({
      row: parseInt(event.target.dataset.row),
      col: parseInt(event.target.dataset.col),
    });
  };
  onMouseLeave = event =>{
    this.setState({
      row: -1,
      col: -1,
    });
  };

  render() {
    const {isShow,row,col}=this.state;
    const getToolBarButtonComponent = () => {
      return (
        <ToolbarButton
          key="menu-button"
          type="tool"
          label={this.props.label}
          active={this.props.active}
          icon={this.activeIcon()}
          className={'toolbar-button expandableToolMenu'}
          onClick={this.onExpandableToolClick}
        />
      );
    };

    const toolbarComponent = getToolBarButtonComponent();

    const getTds = (_r) => {
        const items=[];
        let inRow = this.state.row>=_r;
        for(let i=1;i<=this.state.trNum;i++){
            let inCol = this.state.col>=i;
            items[i]=(
                <td key={i}
                    className={classnames("",{"tdbg":inCol&&inRow})}
                    onMouseEnter={this.onMouseEnter}
                    onMouseLeave={this.onMouseLeave}
                    onClick={this.onClickHandler}
                    data-row={_r}
                    data-col={i}></td>
            )
        }
        return items;
    };

    const getTrs = () => {
        const items=[];
        for(let i=1;i<=this.state.trNum;i++){
            items[i]=(
                <tr key={i}>
                  {getTds(i)}
                </tr>
            )
        }
        return items;
    };

    const trComponent = getTrs();
    const getResulr = () => {
        if(!isShow) return;
        return (
         <div className="GridBox">
            <table border="1"><tbody>{trComponent}</tbody></table>
          </div>
        );
    }

    const tableComponent = getResulr();
    return (
      <div>
        {toolbarComponent}
        {tableComponent}
      </div>
    );
  }
}

export default GridBox;
