import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {Enums,eventTarget} from '@cornerstonejs/core';
import classes from '../core/classes/';
import utils from '../core/utils';

import './StudyPrefetcher.css';

const StudyPrefetcher = ({ studies, options }) => {
  useEffect(() => {
    const studyPrefetcher = classes.StudyPrefetcher.getInstance(
      studies,
      options
    );

    const studiesMetadata = studies.map(s =>
      utils.studyMetadataManager.get(s.StudyInstanceUID)
    );
    studyPrefetcher.setStudies(studiesMetadata);

    const onNewImage = ({ detail,target }) => {
      /**
       * When images are cached the viewport will load instantly and
       * the display sets will not be available at this point in time.
       *
       * This code add display sets and updates the study prefetcher metadata.
       */
      const studiesMetadata = studies.map(s =>
        utils.studyMetadataManager.get(s.StudyInstanceUID)
      );
      studyPrefetcher.setStudies(studiesMetadata);
      /*
      const studiesMetadata = studies.map(s => {
        const studyMetadata = utils.studyMetadataManager.get(
          s.StudyInstanceUID
        );

      const displaySets = studyMetadata.getDisplaySets();
      if (!displaySets || displaySets.length < 1) {
        s.displaySets.forEach(ds => studyMetadata.addDisplaySet(ds));
      }
        return studyMetadata;
      });
      studyPrefetcher.setStudies(studiesMetadata);

      const study = studyPrefetcher.getStudy(detail.image);
      const series = studyPrefetcher.getSeries(study, detail.image);
      const instance = studyPrefetcher.getInstance(series, detail.image);

      if (study.displaySets && study.displaySets.length > 0) {
        const {
          displaySetInstanceUID,
        } = studyPrefetcher.getDisplaySetBySOPInstanceUID(
          study.displaySets,
          instance
        );
        studyPrefetcher.prefetch(detail.element, displaySetInstanceUID);
      }
*/
      const {displaySetInstanceUID}=classes.MetadataProvider._getUIDsFromImageID(detail.image.imageId);
      studyPrefetcher.prefetch(target, displaySetInstanceUID);
    };


    const onElementEnabled = ({ detail }) => {
      detail.element.addEventListener(Enums.Events.STACK_NEW_IMAGE, onNewImage);
    };

    //eventTarget.addEventListener(Enums.Events.ELEMENT_ENABLED, onElementEnabled);

    return () => {
      // eventTarget.removeEventListener(
      //     Enums.Events.ELEMENT_ENABLED,
      //   onElementEnabled
      // );
      studyPrefetcher.destroy();
    };
  }, [options, studies]);

  return null;
};

StudyPrefetcher.propTypes = {
  studies: PropTypes.array.isRequired,
  options: PropTypes.shape({
    enabled: PropTypes.bool,
    order: PropTypes.string,
    displaySetCount: PropTypes.number,
    preventCache: PropTypes.bool,
    prefetchDisplaySetsTimeout: PropTypes.number,
    includeActiveDisplaySet: PropTypes.bool,
  }),
};

StudyPrefetcher.defaultProps = {
  options: {
    order: 'closest',
    displaySetCount: 1,
    preventCache: false,
    prefetchDisplaySetsTimeout: 300,
    includeActiveDisplaySet: false,
  },
};

export default StudyPrefetcher;
