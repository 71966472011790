import dicomParser from 'dicom-parser';
import * as cornerstone from '@cornerstonejs/core';
import cornerstoneWADOImageLoader from 'cornerstone-wado-image-loader';

export default function initCornerstoneWADOImageLoader() {
  cornerstoneWADOImageLoader.external.cornerstone = cornerstone;
  cornerstoneWADOImageLoader.external.dicomParser = dicomParser;
  cornerstoneWADOImageLoader.configure({
    // useWebWorkers: true,
    decodeConfig: {
      convertFloatPixelDataToInt: false,
    },
      beforeSend: function(xhr) {
          // const headers = UserAuthenticationService.getAuthorizationHeader();

          // Request:
          // JPEG-LS Lossless (1.2.840.10008.1.2.4.80) if available, otherwise accept
          // whatever transfer-syntax the origin server provides.
          // For now we use image/jls and image/x-jls because some servers still use the old type
          // http://dicom.nema.org/medical/dicom/current/output/html/part18.html
          const xhrRequestHeaders = {
              Accept: 'multipart/related; type=application/octet-stream',
              // 'multipart/related; type="image/x-jls", multipart/related; type="image/jls"; transfer-syntax="1.2.840.10008.1.2.4.80", multipart/related; type="image/x-jls", multipart/related; type="application/octet-stream"; transfer-syntax=*',
          };

          // if (headers && headers.Authorization) {
          //     xhrRequestHeaders.Authorization = headers.Authorization;
          // }

          return xhrRequestHeaders;
      },
      errorInterceptor: error => {
          // errorHandler.getHTTPErrorHandler(error);
          console.log(error)
      },
  });

  let maxWebWorkers = 1;

  if (navigator.hardwareConcurrency) {
    maxWebWorkers = Math.min(navigator.hardwareConcurrency, 3);
  }

  var config = {
    maxWebWorkers,
    startWebWorkersOnDemand: true,
    taskConfiguration: {
      decodeTask: {
        initializeCodecsOnStartup: false,
        strict: false,
      },
    },
  };

  cornerstoneWADOImageLoader.webWorkerManager.initialize(config);
}
