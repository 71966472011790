import * as cornerstoneTools from '@cornerstonejs/tools';

const {
    PanTool,
    WindowLevelTool,
    StackScrollMouseWheelTool,
    ZoomTool,
    LengthTool,
    AngleTool,
    CrosshairsTool,
    ToolGroupManager,
    Enums: csToolsEnums,
} = cornerstoneTools;
const { MouseBindings } = csToolsEnums;


const viewportId1 = 'CT_AXIAL';
const viewportId2 = 'CT_SAGITTAL';
const viewportId3 = 'CT_CORONAL';

const viewportColors = {
    [viewportId1]: 'rgb(200, 0, 0)',
    [viewportId2]: 'rgb(200, 200, 0)',
    [viewportId3]: 'rgb(0, 200, 0)',
};

const viewportReferenceLineControllable = [
    viewportId1,
    viewportId2,
    viewportId3,
];

const viewportReferenceLineDraggableRotatable = [
    viewportId1,
    viewportId2,
    viewportId3,
];

const viewportReferenceLineSlabThicknessControlsOn = [
    viewportId1,
    viewportId2,
    viewportId3,
];

function getReferenceLineColor(viewportId) {
    return viewportColors[viewportId];
}

function getReferenceLineControllable(viewportId) {
    const index = viewportReferenceLineControllable.indexOf(viewportId);
    return index !== -1;
}

function getReferenceLineDraggableRotatable(viewportId) {
    const index = viewportReferenceLineDraggableRotatable.indexOf(viewportId);
    return index !== -1;
}

function getReferenceLineSlabThicknessControlsOn(viewportId) {
    const index =
        viewportReferenceLineSlabThicknessControlsOn.indexOf(viewportId);
    return index !== -1;
}


const VolumeRenderTools=()=>{
    const volumeToolGroupId = 'VOLUME_TOOLGROUP_ID';

    const toolGroup = ToolGroupManager.createToolGroup(volumeToolGroupId);

    // For the crosshairs to operate, the viewports must currently be
    // added ahead of setting the tool active. This will be improved in the future.

    // Manipulation Tools
    toolGroup.addTool(StackScrollMouseWheelTool.toolName);
    // Add Crosshairs tool and configure it to link the three viewports
    // These viewports could use different tool groups. See the PET-CT example
    // for a more complicated used case.
    toolGroup.addTool(CrosshairsTool.toolName, {
        getReferenceLineColor,
        getReferenceLineControllable,
        getReferenceLineDraggableRotatable,
        getReferenceLineSlabThicknessControlsOn,
    });
    toolGroup.addTool(WindowLevelTool.toolName);

    toolGroup.addTool(PanTool.toolName);
    toolGroup.addTool(ZoomTool.toolName);

    toolGroup.addTool(LengthTool.toolName);
    toolGroup.addTool(AngleTool.toolName);

    toolGroup.setToolActive(WindowLevelTool.toolName, {
        bindings: [
            {
                mouseButton: MouseBindings.Primary, // Left Click
            },
        ],
    });
    toolGroup.setToolActive(PanTool.toolName, {
        bindings: [
            {
                mouseButton: MouseBindings.Auxiliary, // Middle Click
            },
        ],
    });
    toolGroup.setToolActive(ZoomTool.toolName, {
        bindings: [
            {
                mouseButton: MouseBindings.Secondary, // Right Click
            },
        ],
    });
    // As the Stack Scroll mouse wheel is a tool using the `mouseWheelCallback`
    // hook instead of mouse buttons, it does not need to assign any mouse button.
    toolGroup.setToolActive(StackScrollMouseWheelTool.toolName);

    return toolGroup;
}


export default VolumeRenderTools;
export { VolumeRenderTools};
