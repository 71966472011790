import  actions  from '../../core/redux/actions';

const { setLayout } = actions;

/**
 * Update the current layout with a simple Cornerstone one
 *
 * @return void
 */
const setCornerstoneLayout = () => {
  const viewportOptions={
    viewportType: 'STACK',
    toolGroupId: 'STACK_TOOL_GROUP_ID',
    viewportId: 'CT_AXIAL_STACK',
  }
  const layout = {
    numRows: 1,
    numColumns: 1,
    viewports: [{ plugin: 'cornerstone',viewportOptions:viewportOptions,displaySetOptions: [{}]  }],
  };

  const action = setLayout(layout);

  window.store.dispatch(action);
}

export default setCornerstoneLayout;
