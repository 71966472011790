import extensions from './extensions';
import preferences from './preferences';
import studies from './studies';
import seriesicons from './seriesicons';
import viewports from './viewports';
import activetools from "./activetools";

const reducers = {
  extensions,
  preferences,
  studies,
  seriesicons,
  viewports,
  activetools
};

export default reducers;
