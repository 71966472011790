import React, { Component } from 'react';
import { connect } from 'react-redux';
import ConnectedStudyBrowser from './ui/ConnectedStudyBrowser';
import StudyLoadingMonitor from './components/StudyLoadingMonitor';
import StudyPrefetcher from './components/StudyPrefetcher';
import ToolbarRow from './ui/ToolbarRow';
import BottomToolbar from "./ui/BottomToolbar";
import ConnectedViewerMain from './viewport/ConnectedViewerMain';
import SidePanel from "./ui/components/SidePanel/SidePanel";
import MeasurementTracking from "./ui/MeasurementTracking";
import {Drawer} from 'antd';
import ToolbarButton from "./ui/ToolbarButton";
import {switchToolbar} from "./ui/ToolbarModule";
import actions from "./core/redux/actions";
import {servicesManager} from "./App";
import {message} from "antd";
import classnames from 'classnames';
const { setActiveTool,setViewportActive } = actions;

let AppContext = React.createContext({});

const mapStateToProps = state => {
  const { viewports } = state;
  const _viewports= viewports.viewportSpecificData;
  const activeViewport = _viewports[viewports.activeViewportIndex];
  const activeDisplaySetInstanceUID = activeViewport
    ? activeViewport.displaySetInstanceUID
    : undefined;
  return {
    studies: state.studies.studyData,
    thumbnails:_mapStudiesToThumbnails(state.studies.studyData,activeDisplaySetInstanceUID),
    showThumbnailProgressBar:true
  };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setActiveTool: (data) => {
            dispatch(setActiveTool(data));
        },
        setViewportActive: (idx) => {
            dispatch(setViewportActive(idx));
        },
    };
};

const rightPanelComponents=[{name:"trackedMeasurements"}];

class ConnectedViewer extends Component {

  constructor(props) {
    super(props);
    this.state={
      ismobile:false,
      open: props.canShare? true: false,
      displayAI:false,
      isAiShow: false,
      isMPR: false,
      isMPR: false,
      gridSet: null,
      noShow: [],
      opNum: 0,
    }
  }

  componentDidMount() {
    if(navigator.userAgent.match(/Android/i)||
        navigator.userAgent.match(/webOS/i)||
        navigator.userAgent.match(/iphone/i)){
      this.setState({ismobile:true});
    }
    const {MeasurementService}=servicesManager.services;
    MeasurementService.subscribe(MeasurementService.EVENTS.MEASUREMENTS_MOUNTED, (v) => {
        this.setState({displayAI:v})
      });
  }

  componentWillUnmount(){

  }

  onDrawerClose=()=>{
     if(this.state.open){
         this.setState({
             open: false
         });
     }
  }

  onDrawerClick = (props,event) => {
     this.onSwitch();
  }

  onGridClick = (props,event) => {
    this.setState({
      gridSet: props,
      opNum: this.state.opNum+1
    });
  }
  setNoShow = (noShow,event) => {
    this.setState({
      noShow: noShow,
      opNum: this.state.opNum+1
    });
    for(let i=0;i<4;i++){
      if(noShow.indexOf(i)<0){
        this.props.setViewportActive(i)
        break;
      }
    }
  }

  onAiClick = (props,event) => {
    if(!this.state.displayAI){
      message.warn('影像无对应AI标注信息！')
      return;
    }
    this.onAISwitch();
  }

  onMprClick = (flag) => {
    this.setState({
      isMPR: flag,
    });
  }

  onAISwitch = () => {
    this.setState({
      isAiShow: !this.state.isAiShow,
    });
  };

  siderAItitle=()=>{
     return (
        <div className="siderhead">
             <div className="caption">Ai 标注</div>
            <a className="closebtn" onClick={this.onAISwitch}><i className={"iconfont icon-arrow-right"} /></a>
        </div>
     );
  };

  onSwitch = () => {
    this.setState({
      open: !this.state.open,
    });
  };

  sidertitle=()=>{
     return (
        <div className="siderhead">
             <div className="caption">检查序列</div>
            <a className="closebtn" onClick={this.onSwitch}><i className={"iconfont icon-arrow-right"} /></a>
        </div>
     );
  };

  _getToolbarComponent=(isMobile,studieLen)=>{
      const { kjStudyId, hospitalId, canShare, isCompare } = this.props;
      const barSet = { studieLen: studieLen,noShowLen: this.state.noShow.length,
                       mobileView: isMobile,
                       kjStudyId: kjStudyId,
                       hospitalId: hospitalId,
                       canShare: canShare,
                       isCompare: isCompare,
                     };
      return (
          <div id="toolbar" className="toolbar">
              <div className={classnames("scrollable-barbtn-main",{"toolbar-mobile":isMobile})}>
                <div className="barbtn-group">
                  <ToolbarRow {...barSet}
                    aiClick={this.onAiClick}
                    mprClick={this.onMprClick}
                    onGridClick={this.onGridClick}
                    onSetNoShow={this.setNoShow} />
                </div>
              </div>
              <div className="barbtn-group assets">
              </div>
              {isMobile && <div className="barbtn-group assets">
                  <ToolbarButton {...switchToolbar} onClick={this.onDrawerClick} />
              </div>}
          </div>
      )
  }

  mobileViewer=()=>{
      const { kjStudyId, hospitalId,thumbnails, studies, canShare, isCompare } = this.props;
      const {displayAI, isAiShow, isMPR}=this.state;
      const ToolbarComponent=this._getToolbarComponent(true,studies.length);
      const studyBrowserOptions = {
        studies: thumbnails,
        studyMetadata: studies,
        showThumbnailProgressBar: true,
        isCompare: isCompare,
        noShow: this.state.noShow,
        opNum: this.state.opNum,
      }
      return(
          <div className="viewer">
              <AppContext.Consumer>
                  {appContext => <StudyLoadingMonitor studies={studies} />}
              </AppContext.Consumer>
              <div className="studyBrowser">
              </div>
              <div className="dicomViewport">
                  <AppContext.Consumer>
                      {appContext => {
                          return (
                              <StudyPrefetcher
                                  studies={studies}
                                  options={{displaySetCount: 3,maxNumPrefetchRequests:100}}
                              />
                          );
                      }}
                  </AppContext.Consumer>
                  {ToolbarComponent}
                  <ConnectedViewerMain
                      studies={studies}
                      isStudyLoaded={true}
                      gridSet={this.state.gridSet}
                      noShow={this.state.noShow}
                      opNum={this.state.opNum}
                  />
                  {!isMPR && <BottomToolbar canShare={canShare} kjStudyId={kjStudyId} hospitalId={hospitalId} />}
              </div>
              <Drawer open={this.state.open} onClose={this.onSwitch} placement="right" width={200} closable={false} title={this.sidertitle()}>
                  <ConnectedStudyBrowser {...studyBrowserOptions} onDrawerClose={this.onDrawerClose}/>
              </Drawer>
              <Drawer open={displayAI&&isAiShow} onClose={this.onAISwitch} placement="right" width={280} closable={false} title={this.siderAItitle()}>
                  <MeasurementTracking />
              </Drawer>
          </div>
      );
  }

  render() {
    if(this.state.ismobile)
        return this.mobileViewer();
    else
        return this.webViewer();
  }

  webViewer() {
    const {displayAI, isAiShow}=this.state;
    const { thumbnails, studies, isCompare } = this.props;
    const ToolbarComponent=this._getToolbarComponent(false,studies.length);
    const studyBrowserOptions = {
        studies: thumbnails,
        studyMetadata: studies,
        showThumbnailProgressBar: true,
        isCompare: isCompare,
        noShow: this.state.noShow,
        opNum: this.state.opNum,
    }

    return (
      <div className="viewer">
        <AppContext.Consumer>
          {appContext => <StudyLoadingMonitor studies={studies} />}
        </AppContext.Consumer>
        <div className="studyBrowser">
          <ConnectedStudyBrowser {...studyBrowserOptions} />
        </div>
        <div className="dicomViewport">
          <AppContext.Consumer>
            {appContext => {
              return (
                  <StudyPrefetcher
                    studies={studies}
                    options={{order:'custom',displaySetCount: 10,maxNumPrefetchRequests:1}}
                  />
              );
            }}
          </AppContext.Consumer>
          {ToolbarComponent}
          <div className="viewport">
            <ConnectedViewerMain
                studies={studies}
                isStudyLoaded={true}
                gridSet={this.state.gridSet}
                noShow={this.state.noShow}
                opNum={this.state.opNum}
            />
            {isAiShow && displayAI && <SidePanel
                side="right"
                defaultComponentOpen="trackedMeasurements">
              <MeasurementTracking />
            </SidePanel>}
          </div>
        </div>

      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedViewer);

const _checkForDerivedDisplaySets = async function(displaySet, study) {
  return false;
}

const _checkForSeriesInconsistencesWarnings = async function(displaySet){
  return [];
}

const _isDisplaySetActive = function(
  displaySet,
  studies,
  activeDisplaySetInstanceUID
) {
  let active = false;

  const { displaySetInstanceUID } = displaySet;

  // TO DO: in the future, we could possibly support new modalities
  // we should have a list of all modalities here, instead of having hard coded checks
  if (
    displaySet.Modality !== 'SEG' &&
    displaySet.Modality !== 'RTSTRUCT' &&
    displaySet.Modality !== 'RTDOSE'
  ) {
    active = activeDisplaySetInstanceUID === displaySetInstanceUID;
  } else if (displaySet.getSourceDisplaySet) {
    if (displaySet.Modality === 'SEG') {
      const { referencedDisplaySet } = displaySet.getSourceDisplaySet(
        studies,
        false
      );
      active = referencedDisplaySet
        ? activeDisplaySetInstanceUID ===
        referencedDisplaySet.displaySetInstanceUID
        : false;
    } else {
      const referencedDisplaySet = displaySet.getSourceDisplaySet(
        studies,
        false
      );
      active = referencedDisplaySet
        ? activeDisplaySetInstanceUID ===
        referencedDisplaySet.displaySetInstanceUID
        : false;
    }
  }

  return active;
};

const _mapStudiesToThumbnails = function(studies, activeDisplaySetInstanceUID) {
  if(!studies||!studies.length)
    return [];
  return studies.map(study => {
    const { StudyInstanceUID,StudyDate } = study;
    const thumbnails = study.displaySets.map(displaySet => {
      const {
        displaySetInstanceUID,
        SeriesDescription,
        numImageFrames,
        SeriesNumber,
      } = displaySet;

      let imageId;
      let altImageText;

      /*
      if (displaySet.Modality && displaySet.Modality === 'SEG') {
        // TODO: We want to replace this with a thumbnail showing
        // the segmentation map on the image, but this is easier
        // and better than what we have right now.
        altImageText = 'SEG';
      } else if (displaySet.images && displaySet.images.length) {
        const imageIndex = Math.floor(displaySet.images.length / 2);
        imageId = displaySet.images[imageIndex].getImageId();
      } else if (displaySet.isSOPClassUIDSupported === false) {
        altImageText = displaySet.SOPClassUIDNaturalized;
      } else {
        altImageText = displaySet.Modality ? displaySet.Modality : 'UN';
      }
*/
      if (displaySet.images && displaySet.images.length) {
        const imageIndex = Math.floor(displaySet.images.length / 2);
        imageId = displaySet.images[imageIndex].url;
      }

      const hasWarnings = _checkForSeriesInconsistencesWarnings(displaySet);

      const hasDerivedDisplaySets = _checkForDerivedDisplaySets(displaySet,
        study);

      return {
        active: _isDisplaySetActive(
          displaySet,
          studies,
          activeDisplaySetInstanceUID
        ),
        imageId,
        altImageText,
        displaySetInstanceUID,
        SeriesDescription,
        numImageFrames,
        SeriesNumber,
        hasWarnings,
        hasDerivedDisplaySets,
      };
    });

    return {
      StudyInstanceUID,
      StudyDate,
      thumbnails,
    };
  });
};
