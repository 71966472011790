import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ToolbarButton from './ToolbarButton';
import ExpandableToolMenu from './ExpandableToolMenu';
import ShareBox from './toolBarBox/ShareBox';
import GridBox from './toolBarBox/GridBox';
import SettingBox from './toolBarBox/SettingBox';
import getDefaultButtons, {switchToolbar} from './ToolbarModule';
import {getVoiFromVolumeMetadata} from "../core/utils/colormap/transferFunctionHelpers";
import { getEnabledElement as OHIFgetEnabledElement} from './../viewport/state';

import {
    getEnabledElement,
    StackViewport,
    VolumeViewport,
    utilities,
    setUseSharedArrayBuffer,
    Enums
} from '@cornerstonejs/core';
import listener from '../core/classes/StudyLoadingListener';
import {message} from "antd";
import {jump3DUrl} from "../dicom-sr/utils/addMeasurementToService.js";

import {commandsManager,servicesManager} from '../App';

import {connect} from "react-redux";

import actions from "../core/redux/actions";
import * as cornerstoneTools from "@cornerstonejs/tools";



const { setLayout,setActiveTool } = actions;
const { StudyLoadingListener } = listener;

const {
    ToolGroupManager,StackScrollTool,
    Enums: csToolsEnums,
} = cornerstoneTools;
const { MouseBindings } = csToolsEnums;

const mapStateToProps = state => {
    const { viewports,activetools,studies } = state;
    const _viewports= viewports.viewportSpecificData;
    const activeViewport = _viewports[viewports.activeViewportIndex];
    const activeDisplaySetInstanceUID = activeViewport
        ? activeViewport.displaySetInstanceUID
        : undefined;
    return {
        activeViewportIndex:viewports.activeViewportIndex,
        activeViewport:activeViewport,
        displaySetInstanceUID:activeDisplaySetInstanceUID,
        studyData:studies.studyData,
        isVtk:viewports.layout.viewports.some(vp => !!vp.vtk),
        activetools:activetools,
        viewports:viewports
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setActiveTool: (data) => {
            dispatch(setActiveTool(data));
        },
      setLayout:({ numRows, numColumns, viewports })=>{
        dispatch(setLayout({ numRows, numColumns, viewports }));
      },
    };
};

class ToolbarRow extends Component {
    static propTypes = {
        mobileView:PropTypes.bool.isRequired,
        hasAI:PropTypes.bool.isRequired,
        includeLayoutButton: PropTypes.bool.isRequired,
        includePlayClipButton: PropTypes.bool.isRequired,
        aiClick: PropTypes.func,
        mprClick: PropTypes.func,
        mprClick: PropTypes.func,
        onGridClick: PropTypes.func,
    };

    static defaultProps = {
        mobileView:false,
        isCompare:false,
        hasAI:false,
        includeLayoutButton: false,
        includePlayClipButton: false,
    };


    constructor(props) {
        super(props);
        const toolbarButtonDefinitions = _getVisibleToolbarButtons.call(this,this.props.isVtk,this.props.mobileView,this.props.isCompare,false);
        this.props.mprClick(this.props.isVtk)
        this.state = {
            toolbarButtons: toolbarButtonDefinitions,
            activeButtons: [],
            activeId: this.props.activetools,
            lastClick:'',
            displayCrosshairs:true,
            mprDisabled:true,
            batchOperation:false
        };
    }

    componentDidUpdate(prevProps) {
        if(this.props.activeViewport!=prevProps.activeViewport){
            let disabled= this.props.activeViewport&&this.props.activeViewport.images.length>10
            this.setState({mprDisabled:!disabled})
        }
        if(this.props.isVtk!=prevProps.isVtk||this.props.mobileView!=prevProps.mobileView){
            const toolbarButtonDefinitions = _getVisibleToolbarButtons.call(this,this.props.isVtk,this.props.mobileView,this.props.isCompare,this.state.batchOperation);
            this.props.mprClick(this.props.isVtk)
            let active='Wwwc'
            if(this.props.mobileView){
                const toolGroup = ToolGroupManager.getToolGroup("STACK_TOOL_GROUP_ID");
                const activeTool = toolGroup.getActivePrimaryMouseButtonTool();
                if (activeTool) {
                    toolGroup.setToolPassive(activeTool);
                    toolGroup.setToolDisabled(activeTool);
                }
                toolGroup.setToolActive(StackScrollTool.toolName,{
                    bindings: [
                        {
                            mouseButton: MouseBindings.Primary, // Left Click
                        },
                    ],
                })
                active='StackScroll'
            }
            this.setState({toolbarButtons: toolbarButtonDefinitions,activeId:active})
        }
        if(this.props.activetools!=prevProps.activetools){
            this.setState({activeId:this.props.activetools})
        }
    }

    onBarClick = (_bid) => {
      this.setState({lastClick:_bid})
    }

    render() {
        const {toolbarButtons,mprDisabled,activeId,lastClick}=this.state;
        return (
            toolbarButtons.map((button, i) => {
                if(button.id=='MPR'){
                    return <ToolbarButton {...button} key={i} active={button.id==activeId} disabled={mprDisabled} onClick={_mprButtonClick.bind(this,button)} />;
                }
                else if(button.type=='command'&&(button.commandName=='setWindowLevel' || button.commandName=='setWindowLevelAll')){
                    return <ExpandableToolMenu {...button} key={i} onGroupMenuClick={_presetsButtonClick.bind(this,button)} getVoi={_getVoi.bind(this)}></ExpandableToolMenu>;
                }
                else if(button.type=='command'&&(button.commandName=='shareCommand')){
                    const {kjStudyId, hospitalId, canShare}=this.props;
                    if(canShare) {
                        return <ShareBox {...button} active={button.id==lastClick} onBarClick={this.onBarClick} key={i} kjStudyId={kjStudyId} hospitalId={hospitalId} ></ShareBox>;
                    }
                }
                else if(button.id=='grid'){
                    const sln = this.props.studieLen - this.props.noShowLen
                    return <GridBox {...button} key={i} active={button.id==lastClick} onGridClick={this.props.onGridClick} onBarClick={this.onBarClick} studieLen={sln}></GridBox>;
                }
                else if(button.id=='setting'){
                    return <SettingBox {...button} key={i} active={button.id==lastClick} onSetNoShow={this.props.onSetNoShow} onBarClick={this.onBarClick} studieLen={this.props.studieLen}></SettingBox>;
                }
                else
                    return <ToolbarButton {...button} key={i} active={button.id==activeId} onClick={_handleToolbarButtonClick.bind(this,button)} />;
            })
        );
    }
}

function _getVisibleToolbarButtons(isMPR,isMobile,isCompare,batchOperation) {
    let barType = null;
    let opbatch = false;
    if(isMPR) {
       barType = 'MPR';
    } else if(isCompare){
      barType = 'compare' //compare 必须在mobile前面
      if(batchOperation) {
        opbatch = true
      }
    } else if(isMobile){
      barType = 'mobile'
    }
    return getDefaultButtons(barType,opbatch)
}

function _getVoi() {
    try{
        const element = OHIFgetEnabledElement(this.props.activeViewportIndex);
        if (element) {
            const enabledElement = getEnabledElement(element);
            if (!enabledElement) {
                return;
            }
            const { viewport } = enabledElement;

            if (viewport instanceof VolumeViewport){
                let volumeId= this.props.displaySetInstanceUID
                let voi=getVoiFromVolumeMetadata(volumeId)
                const { windowWidth:windowWidth_default, windowCenter:windowCenter_default }=voi
                return {windowWidth: 0, windowCenter: 0,windowWidth_default,windowCenter_default}
            }

            if(viewport){
                const { lower, upper }=viewport.voiRange;
                const { windowWidth, windowCenter } = utilities.windowLevel.toWindowLevel(
                    lower,
                    upper
                );
                const { lower:lower_default, upper:upper_default }=viewport.initialVOIRange;
                const { windowWidth:windowWidth_default, windowCenter:windowCenter_default } = utilities.windowLevel.toWindowLevel(
                    lower_default,
                    upper_default
                );
                return { windowWidth, windowCenter,windowWidth_default,windowCenter_default };
            }
            else{
                return {windowWidth: 0, windowCenter: 0,windowWidth_default:0,windowCenter_default:0}
            }
        }
        else{
            return {windowWidth: 0, windowCenter: 0,windowWidth_default:0,windowCenter_default:0}
        }
    }
    catch (e) {
        return {windowWidth: 0, windowCenter: 0,windowWidth_default:0,windowCenter_default:0}
    }
}

function _presetsButtonClick(button, evt, props){
    const options = Object.assign({ evt }, button.commandOptions);
    commandsManager.runCommand(button.commandName, {...options,window:evt.ww,level:evt.wl});
}

function _mprButtonClick(button, evt, props){
    setUseSharedArrayBuffer(Enums.SharedArrayBufferModes.AUTO)
    let listener=StudyLoadingListener.getInstance()
    let loaded=listener.loadComplete(this.props.displaySetInstanceUID)
    if(!loaded){
        message.warn('影像正在加载，请稍候...')
        return;
    }
    if (button.commandName) {
        const options = Object.assign({ evt }, button.commandOptions);
        commandsManager.runCommand(button.commandName, options);
    }
    this.setState({lastClick:button.id})
}

function _handleToolbarButtonClick(button, evt, props) {
    const { activeButtons } = this.state;
    const {studyData, aiClick, hasAI, kjStudyId}=this.props;
    const { ToolBarService } = servicesManager.services;

    this.setState({lastClick:button.id})

    if (button.commandName) {
        const options = Object.assign({ evt }, button.commandOptions);
        if(button.id=='enableLevelTool'){
            const shouldDisplayCrosshairs=false;
            commandsManager.runCommand(button.commandName, {...options,display:shouldDisplayCrosshairs});
            this.setState({displayCrosshairs:shouldDisplayCrosshairs});
        }
        else if(button.id=='ScreenShot'){
            let consultationOrderId;
            if(studyData&&studyData.length>0){
                consultationOrderId=studyData[0].consultationOrderId
            }
            commandsManager.runCommand(button.commandName, {...options,consultationOrderId:consultationOrderId});
        }
        else if(button.id=='Ai'){
            if(aiClick){
              aiClick();
            }
        }
        else if(button.id=='TD'){
            jump3DUrl(kjStudyId);
        }
        else if(button.id=='opBatch'){
            const _b = !this.state.batchOperation
            // let _t = (_b) ?'启用':'取消'
            // message.info('预设，反色' + _t + '批量操作')
            this.setState({batchOperation:_b});
            const toolbarButtonDefinitions = _getVisibleToolbarButtons.call(this,this.props.isVtk,this.props.mobileView,this.props.isCompare,_b);
            this.setState({toolbarButtons: toolbarButtonDefinitions,batchOperation:_b})
        }
        else{
            commandsManager.runCommand(button.commandName, options);
        }
    }

    // TODO: Use Types ENUM
    // TODO: We can update this to be a `getter` on the extension to query
    //       For the active tools after we apply our updates?
    if (button.type === 'setToolActive') {
        const toggables = activeButtons.filter(
            ({ options }) => options && !options.togglable
        );
        this.setState({ activeButtons: [...toggables, button] });

        const toolGroupId='STACK_TOOL_GROUP_ID';
        const options = Object.assign({ evt }, button.commandOptions,{toolName:button.id,toolGroupId:toolGroupId});
        ToolBarService.recordInteraction({
            groupId: toolGroupId,
            itemId: button.id,
            interactionType: 'tool',
            commands: [
                {
                    commandName: 'setToolActive',
                    commandOptions: options,
                    context: 'VIEWER',
                }
            ]
        });

        this.props.setActiveTool(button.id)
    } else if (button.type === 'builtIn') {
       // this._handleBuiltIn(button);
    }
    else{
        if(button.id=='Crosscurve'){

        }
        else{
            this.setState({activeId:button.id});
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ToolbarRow);
