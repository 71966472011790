import './ViewerMain.css';
import { Component } from 'react';
import ViewportComponent from './ViewportComponent';
import PropTypes from 'prop-types';
import React from 'react';
import memoize from 'lodash/memoize';
import _values from 'lodash/values';
import {servicesManager} from "../App";
import addMeasurementToService from "../dicom-sr/utils/addMeasurementToService";
import actions from "../core/redux/actions";
const { setViewportActive } = actions;
var values = memoize(_values);

class ViewerMain extends Component {
  static defaultProps = {
    activeViewportIndex: 0
  };

  static propTypes = {
    activeViewportIndex: PropTypes.number.isRequired,
    studies: PropTypes.array,
    viewportSpecificData: PropTypes.object.isRequired,
    layout: PropTypes.object.isRequired,
    setLayout:PropTypes.func.isRequired,
    setViewportSpecificData: PropTypes.func.isRequired,
    clearViewportSpecificData: PropTypes.func.isRequired,
    clearStudyData:PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      displaySets: [],
    };
  }

  getDisplaySets(studies) {
    const displaySets = [];
    studies.forEach((study, idx) => {
      study.displaySets.forEach(dSet => {
        if (!dSet.plugin) {
          dSet.plugin = 'cornerstone';
          dSet.idx = idx;
        }
        displaySets.push(dSet);
      });
    });

    return displaySets;
  }

  findDisplaySet(studies, StudyInstanceUID, displaySetInstanceUID) {
    const study = studies.find(study => {
      return study.StudyInstanceUID === StudyInstanceUID;
    });

    if (!study) {
      return;
    }

    return study.displaySets.find(displaySet => {
      return displaySet.displaySetInstanceUID === displaySetInstanceUID;
    });
  }

  componentDidMount() {
    // Add beforeUnload event handler to check for unsaved changes
    //window.addEventListener('beforeunload', unloadHandlers.beforeUnload);

    // Get all the display sets for the viewer studies
    if (this.props.studies) {


      let numViewports = 1
      let numRows = 1
      let numColumns = 1
      const slen = this.props.studies.length
      let pluginType = 'cornerstone'
      if(slen > 1) {
        numViewports = slen
        // pluginType = 'vtk'
        numColumns = 2
        if(slen > 2){
            numRows = 2
        }
      }
      const viewports = [];
      for (let i = 0; i < numViewports; i++) {
        const viewportOptions={
          viewportType: 'STACK',
          toolGroupId: 'STACK_TOOL_GROUP_ID',
          viewportId: 'CT_AXIAL_STACK_'+i,
        }
        viewports.push({ plugin: pluginType,viewportOptions:viewportOptions,sdIdx: i});
      }
      this.props.setLayout({numRows: numRows,numColumns: numColumns,viewports: viewports})
      const displaySets = this.getDisplaySets(this.props.studies);
      this.setState({ displaySets }, this.fillEmptyViewportPanes);
    }
  }

  componentDidUpdate(prevProps) {
    const prevViewportAmount = prevProps.layout.viewports.length;
    const viewportAmount = this.props.layout.viewports.length;
    const isVtk = this.props.layout.viewports.some(vp => !!vp.vtk);
    if (this.props.studies !== prevProps.studies || (viewportAmount !== prevViewportAmount && !isVtk)) {
      this.componentDidMount();
    }
    if(this.props.viewportSpecificData&&(this.props.viewportSpecificData!==prevProps.viewportSpecificData)){
      if(this.props.viewportSpecificData[0]){
        const { MeasurementService } = servicesManager.services;
        MeasurementService.clearMeasurements();
        addMeasurementToService(MeasurementService,this.props.viewportSpecificData[0]);
      }
    }
  }

  fillEmptyViewportPanes = () => {
    // TODO: Here is the entry point for filling viewports on load.
    const dirtyViewportPanes = [];
    const { layout, viewportSpecificData } = this.props;
    const { displaySets } = this.state;

    if (!displaySets || !displaySets.length) {
      return;
    }
    if(!this.props.studies||!this.props.studies.length) {
      return;
    }
    const sLen = this.props.studies.length;

    for (let i = 0; i < layout.viewports.length; i++) {
      const viewportPane = viewportSpecificData[i];
      const isNonEmptyViewport =
        viewportPane &&
        viewportPane.StudyInstanceUID &&
        viewportPane.displaySetInstanceUID;

      if (isNonEmptyViewport) {
        dirtyViewportPanes.push({
          StudyInstanceUID: viewportPane.StudyInstanceUID,
          displaySetInstanceUID: viewportPane.displaySetInstanceUID,
        });

        continue;
      }

      const _study = this.props.studies[i]
      const autoLoadKjSeriesIds = _study.autoLoadKjSeriesIds;

      let foundDisplaySet
      let _displaySetInstanceUID
      if(sLen>1) {
          _displaySetInstanceUID = _study.displaySets[0].displaySetInstanceUID
      } else if(navigator.userAgent.match(/Android/i)||
        navigator.userAgent.match(/webOS/i)||
        navigator.userAgent.match(/iphone/i)||
        sLen>1) {
        //手机端
        foundDisplaySet = displaySets[0]
      } else{
        _displaySetInstanceUID = autoLoadKjSeriesIds[0]
      }
      if(_displaySetInstanceUID) {
        foundDisplaySet = displaySets.find(ds=>ds.displaySetInstanceUID===_displaySetInstanceUID)|| displaySets[0]
      }
      dirtyViewportPanes.push(foundDisplaySet);
    }
    dirtyViewportPanes.forEach((vp, i) => {
      if (vp && vp.StudyInstanceUID) {
        this.setViewportData({
          viewportIndex: i,
          StudyInstanceUID: vp.StudyInstanceUID,
          displaySetInstanceUID: vp.displaySetInstanceUID,
          aaa: '--t',
        });
      }
    });
  };

  setViewportData = ({
    viewportIndex,
    StudyInstanceUID,
    displaySetInstanceUID,
  }) => {
    let displaySet = this.findDisplaySet(
      this.props.studies,
      StudyInstanceUID,
      displaySetInstanceUID
    );

    /*
    const { LoggerService, UINotificationService } = servicesManager.services;

    if (displaySet.isDerived) {
      const { Modality } = displaySet;
      if (Modality === 'SEG' && servicesManager) {
        const onDisplaySetLoadFailureHandler = error => {
          LoggerService.error({ error, message: error.message });
          UINotificationService.show({
            title: 'DICOM Segmentation Loader',
            message: error.message,
            type: 'error',
            autoClose: true,
          });
        };

        const {
          referencedDisplaySet,
          activatedLabelmapPromise,
        } = displaySet.getSourceDisplaySet(
          this.props.studies,
          true,
          onDisplaySetLoadFailureHandler
        );
        displaySet = referencedDisplaySet;

        activatedLabelmapPromise.then(activatedLabelmapIndex => {
          const selectionFired = new CustomEvent(
            'extensiondicomsegmentationsegselected',
            {
              detail: { activatedLabelmapIndex: activatedLabelmapIndex },
            }
          );
          document.dispatchEvent(selectionFired);
        });
      } else {
        displaySet = displaySet.getSourceDisplaySet(this.props.studies);
      }

      if (!displaySet) {
        const error = new Error('Source data not present');
        const message = 'Source data not present';
        LoggerService.error({ error, message });
        UINotificationService.show({
          autoClose: false,
          title: 'Fail to load series',
          message,
          type: 'error',
        });
      }
    }

    if (displaySet.isSOPClassUIDSupported === false) {
      const error = new Error('Modality not supported');
      const message = 'Modality not supported';
      LoggerService.error({ error, message });
      UINotificationService.show({
        autoClose: false,
        title: 'Fail to load series',
        message,
        type: 'error',
      });
    }
*/
    if(displaySet){
      this.props.setViewportSpecificData(viewportIndex, displaySet);
    }
  };

  render() {
    const { viewportSpecificData,noShow,opNum } = this.props;
    const viewportData = values(viewportSpecificData);
    const onNewImageHandler = jumpData => {
      /** Do not trigger all viewports to render unnecessarily */
     // jumpData.refreshViewports = false;
      //commandsManager.runCommand('jumpToImage', jumpData);
    };

    const showViewPortData = []
    for (let j = 0; j < viewportData.length; j++){
        let flag = noShow.indexOf(j) >= 0
        if(!flag){
          showViewPortData.push(viewportData[j])
        }
    }

    return (
      <div className="ViewerMain">
        {this.state.displaySets.length == 0&&(
            <div className="Loading">
              <span style={{fontSize:16}}>正在载入数据...</span>
            </div>
        )}
        {this.state.displaySets.length > 0 && (
          <ViewportComponent
            isStudyLoaded={this.props.isStudyLoaded}
            gridSet={this.props.gridSet}
            noShow={this.props.noShow}
            studies={this.props.studies}
            viewportData={showViewPortData}
            setViewportData={this.setViewportData}
            onNewImage={onNewImageHandler}
            viewportIndex={0}
          >
            {/* Children to add to each viewport that support children */}
          </ViewportComponent>
        )}
      </div>
    );
  }

  componentWillUnmount() {
    // Clear the entire viewport specific data
    const { viewportSpecificData } = this.props;
    Object.keys(viewportSpecificData).forEach(viewportIndex => {
      this.props.clearViewportSpecificData(viewportIndex);
    });
    this.props.clearStudyData();

    // TODO: These don't have to be viewer specific?
    // Could qualify for other routes?
    // hotkeys.destroy();

    // Remove beforeUnload event handler...
    //window.removeEventListener('beforeunload', unloadHandlers.beforeUnload);
    // Destroy the synchronizer used to update reference lines
    //OHIF.viewer.updateImageSynchronizer.destroy();
    // TODO: Instruct all plugins to clean up themselves
    //
    // Clear references to all stacks in the StackManager
    //StackManager.clearStacks();
    // @TypeSafeStudies
    // Clears OHIF.viewer.Studies collection
    //OHIF.viewer.Studies.removeAll();
    // @TypeSafeStudies
    // Clears OHIF.viewer.StudyMetadataList collection
    //OHIF.viewer.StudyMetadataList.removeAll();
  }
}

export default ViewerMain;
